import React, { Suspense, useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { Box, style } from '@mui/system'

import DomainNewIssues from './DomainNewIssues'
import DomainWidgetSummary from './DomainWidgetSummary'
import IpReputationTable from './IpReputation'

import { useGetDomainStatistics } from 'src/services/Query/domains-statistic/domains-statistic.query'
import { ColorSchema } from 'src/theme/palette'

type ConfigDetails = {
  title: string
  icon: string
  color?: ColorSchema
}

type K =
  | 'userReportedSpamRatio'
  | 'inboundEncryptionRatio'
  | 'domainReputation'
  | 'spfSuccessRatio'
  | 'dkimSuccessRatio'
  | 'dmarcSuccessRatio'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  domain: string
}

const DomainTabPanel: React.FC<TabPanelProps> = ({
  index,
  value,
  children,
  domain,
  ...other
}: TabPanelProps) => {
  const { data, isFetched } = useGetDomainStatistics({
    domain: domain,
    options: {
      refetchOnWindowFocus: false
    }
  })

  const isNotFound = isFetched && !data

  const date = useMemo(() => {
    if (!!data?.name) {
      const dataNameTag = data?.name.split('/')
      const dateTag = dataNameTag?.[dataNameTag.length - 1]

      const year = dateTag.slice(0, 4)
      const month = dateTag.slice(4, 6)
      const day = dateTag.slice(6)

      // Create a Date object using the extracted year, month, and day
      const date = new Date(`${year}-${month}-${day}`)

      return date.toLocaleDateString()
    }

    return ''
  }, [data?.name])

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ paddingTop: 4 }}
      {...other}
    >
      {isNotFound ? (
        <></>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            {!!date ? (
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                Last data for {date} by Domain {domain}
              </Typography>
            ) : (
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                No data found for {domain}
              </Typography>
            )}
          </Grid>

          {Object.keys(config).map((key) => {
            // if (!!data && data[key as K] !== undefined) {
            const { title, icon, color } = config[key as K]
            const total = data?.[key as K]
            return (
              <Grid item xs={12} md={4} key={key}>
                <DomainWidgetSummary
                  color={color}
                  title={title}
                  total={total || 'No Info'}
                  icon={icon}
                />
              </Grid>
            )
          })}

          {!!data && (
            <Grid item xs={12} lg={7}>
              <DomainNewIssues
                title="Domain Delivery Errors"
                tableData={data.deliveryErrors}
                tableLabels={[
                  { id: 'id', label: 'ID' },
                  { id: 'message', label: 'Message' },
                  { id: 'errorCode', label: 'Error Code' },
                  { id: 'timestamp', label: 'Timestamp' }
                ]}
              />
            </Grid>
          )}

          {!!data && (
            <Grid item xs={12} lg={5}>
              <IpReputationTable
                title="IP Reputation Status"
                tableData={data.ipReputations}
                tableLabels={[
                  { id: 'id', label: 'ID' },
                  { id: 'ip', label: 'IP' },
                  { id: 'reputation', label: 'Reputation' }
                ]}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

export const config: { [key in K]: ConfigDetails } = {
  userReportedSpamRatio: {
    title: 'User Reported Spam Ratio',
    icon: 'ph:users-fill',
    color: 'warning'
  },
  inboundEncryptionRatio: {
    title: 'Inbound Encryption Ratio',
    icon: 'ri:spam-2-line',
    color: 'info'
  },
  domainReputation: {
    title: 'Domain Reputation',
    icon: 'jam:messages-f',
    color: 'info'
  },
  spfSuccessRatio: {
    title: 'SPF',
    icon: 'codicon:pass',
    color: 'success'
  },
  dkimSuccessRatio: {
    title: 'DKIM',
    icon: 'codicon:pass',
    color: 'success'
  },
  dmarcSuccessRatio: {
    title: 'DMARC',
    icon: 'codicon:pass',
    color: 'success'
  }
}

export default DomainTabPanel
