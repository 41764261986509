import { useState } from 'react'
import { useForm } from 'react-hook-form'
// form

import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { LoadingButton } from '@mui/lab'
import { Stack, Alert } from '@mui/material'

// routes
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { FormProvider, MUITextField } from '../../../fields'

import { PasswordField } from 'src/components/fields/PasswordField'
import { LoginSchema } from 'src/validations/schemas/login.schema'

type FormValuesProps = {
  userName: string
  password: string
  remember: boolean
  afterSubmit?: string
}

export default function LoginForm() {
  const { login } = useAuth()

  const isMountedRef = useIsMountedRef()

  const [showPassword, setShowPassword] = useState(false)

  const defaultValues = {
    userName: '',
    password: ''
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.userName, data.password)
    } catch (error) {
      console.error(error)

      reset()

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message })
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} my={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">Username or password incorrect</Alert>
        )}

        <MUITextField name="userName" label="Username" />

        <PasswordField name="password" label="Password" />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  )
}
