import { object, string } from 'yup'

export const LoginSchema = object().shape({
  userName: string()
    .min(3, 'Too short: username must have at least 3 characters')
    .required('Username is required'),
  password: string()
    .min(8, 'Too short: password must have at least 8 characters')
    .max(50, 'Password is too long!')
})
