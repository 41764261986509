import { array, mixed, object, string } from 'yup'

export const PlanCreateSchema = object().shape({
  project: object().shape({
    value: string().required('Project Name is required')
  }),
  date: string().trim().required('Date is required').nullable(),
  usersFields: array().of(
    object().shape({
      user: object().shape({
        value: string().required('User Name is required')
      }),
      taskFields: array().of(
        object().shape({
          workType: object().shape({
            value: string().required('Work Type is required')
          }),
          description: mixed().test('description', '', (value, context) => {
            const isTicket = context.parent.workType.value === 'TICKET'
            const urlRegex = /(https?:\/\/[^\s]+)/g
            const haveUrl = urlRegex.test(value)

            if (isTicket && !haveUrl) {
              return context.createError({
                message: 'Url must be in description'
              })
            }

            if (!value && !isTicket) {
              return context.createError({ message: 'Description is required' })
            }

            return !!value
          })
        })
      )
    })
  )
})
