import startOfDay from 'date-fns/startOfDay'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
  StaticDatePickerProps
} from '@mui/lab'
import { InputAdornment, Popover, styled, TextField } from '@mui/material'

import { Iconify } from '../base'

import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { findDate, findIndexDate } from 'src/utils/functions'

interface MultipleDatePickerFiledProps {
  label?: string
  name: string
  width?: string
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
}))

const MultipleDatePickerField: React.FC<
  MultipleDatePickerFiledProps & Partial<StaticDatePickerProps<Date>>
> = ({ name, label }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const [values, setValues] = useState<Date[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const renderPickerDay = (
    date: Date,
    selectedDates: Date[],
    pickersDayProps: JSX.IntrinsicAttributes &
      PickersDayProps<unknown> &
      React.RefAttributes<HTMLButtonElement>
  ) => {
    if (!values) {
      return <PickersDay {...pickersDayProps} />
    }

    const selected = !!findDate(values, date)

    return <CustomPickersDay {...pickersDayProps} selected={selected} />
  }

  const currentValues = values
    .map((date: Date) => IntlService.formatDate(date.toString()))
    .join(', ')

  return (
    <>
      <TextField
        label={label}
        size="small"
        error={!!errors?.[name]}
        helperText={errors?.[name]?.message}
        inputProps={{ readOnly: true }}
        value={currentValues}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-input': {
            cursor: 'pointer',
            WebkitTextFillColor: '#212B36'
          }
        }}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Iconify
                icon={'mdi:calendar'}
                sx={{ cursor: 'pointer' }}
                width={25}
                height={30}
              />
            </InputAdornment>
          )
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              //   disabled weekends
              shouldDisableDate={DatePickerService.findWeekendDays}
              label="Week picker"
              value={field.value || startOfDay(new Date())}
              onChange={(newValue) => {
                //copying the values array
                const array = [...values]
                const date = startOfDay(newValue)
                const index = findIndexDate(array, date)
                if (index >= 0) {
                  array.splice(index, 1)
                } else {
                  array.push(date)
                }
                setValues(array)

                field.onChange(
                  array.map((date: Date) =>
                    IntlService.formatDate(date.toString())
                  )
                )
              }}
              renderDay={renderPickerDay}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="'Week of' MMM d"
            />
          )}
        />
      </Popover>
    </>
  )
}

export default MultipleDatePickerField
