/*eslint no-param-reassign: "off"*/
import { UserManager } from 'src/@types/user'
import { IntlService } from 'src/services/IntlService'

export function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole
}: {
  tableData: UserManager[]
  comparator: (a: UserManager, b: UserManager) => number
  filterName: string
  filterStatus: string
  filterRole: string
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const)

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  tableData = stabilizedThis.map((el) => el[0])

  if (filterName) {
    tableData = tableData.filter(
      (item: UserManager) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    )
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(
      (item: UserManager) => item.status === filterStatus
    )
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter(
      (item: UserManager) => item.role === filterRole
    )
  }

  return tableData
}

export const normalizesQueryParams = (
  params: { [s: string]: unknown } | QueryParams
) => {
  const queryParamsFiltered = Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value
      }
      return acc
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as any
  )

  return queryParamsFiltered
}

export const transformMultipleValue = (value?: Options[]): string[] => {
  if (!value) {
    return []
  }

  return value.map((item: Options) => item.value)
}

export const findDate = (dates: Date[], date: Date) => {
  const dateTime = date.getTime()
  return dates.find((item: Date) => item.getTime() === dateTime)
}

export const findIndexDate = (dates: Date[], date: Date) => {
  const dateTime = date.getTime()
  return dates.findIndex((item: Date) => item.getTime() === dateTime)
}
export const downloadFile = (
  data: string,
  { fileName, formatFile }: { fileName: string; formatFile: string }
) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${formatFile}`)
  document.body.appendChild(link)
  link.click()
}

export const formatHeaderLabel = (
  headCell: TableHeadOptions,
  additionalInfo?: TableHeaderAdditionalInfo[]
) => {
  if (additionalInfo) {
    const currentValue = additionalInfo.find((item) => headCell.id === item.id)
    return currentValue
      ? `${headCell.label} (${currentValue.data})`
      : headCell.label
  }
  return headCell.label
}

export const formatDataForBarChart = (
  projectTypes: string[],
  data?: PeriodStatistic
) => {
  const series = projectTypes.map((item) => ({
    name: item,
    data: [] as Array<number | null>
  }))

  data?.clients.map((item) => {
    series[0].data.push(item.value)
  })

  data?.internals.map((item) => {
    series[1].data.push(item.value)
  })

  return series
}

/**
 * Creates options for Autocomplete
 *
 * @param {array} data - data array
 * @param {string} key1 - object key1
 * @param {string} key2 - object key2
 */
export const createOptions = <Payload = Record<string, unknown>>(
  data: Array<Payload>,
  key1 = 'id',
  key2 = 'name',
  withoutData = false
): Array<Option & Payload> => {
  if (!data || !Array.isArray(data)) return []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (data as { [key: string]: any })
    .filter(Boolean)
    .map(({ [key1]: value, [key2]: label, ...rest }) => ({
      value,
      label: label || '',
      ...(!withoutData && rest)
    }))
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
