import React, { Suspense, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button } from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  SelectField,
  MUITextField,
  StaticDateRangePickerField
} from 'src/components/fields'
import useToggle from 'src/hooks/useToggle'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { useGetWorkTypes } from 'src/services/Query/time-entries/time-entries.query'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { transformMultipleValue } from 'src/utils/functions'

const TimeLogsDialog = React.lazy(() => import('./TimeLogsDialog'))

interface TimeLogsFilterProps {
  handleFilterValueChange: (value: TimeValuesNormalired) => void
}

const TimeLogsFilter: React.FC<TimeLogsFilterProps> = ({
  handleFilterValueChange
}) => {
  const { toggle, onClose } = useToggle(false)

  const projectsQueryName = useGetProjectsNames({ params: { active: true } })
  const usersQueryName = useGetUsersNames({ params: { active: true } })
  const workTypesQueryName = useGetWorkTypes()

  const { startCurrentWeek, endCurrentWeek } = DatePickerService

  const methods = useForm<TimeEntriesFV>({
    defaultValues: {
      userIds: [],
      projectIds: [],
      workType: '',
      details: '',
      dateRange: [
        IntlService.formatDate(startCurrentWeek.toString()),
        IntlService.formatDate(endCurrentWeek.toString())
      ]
    }
  })

  const { dateRange, ...restValues } = methods.watch()
  const cloneValues = JSON.stringify(restValues)

  useEffect(() => {
    const normalizeValues = {
      ...restValues,
      userIds: transformMultipleValue(restValues?.userIds),
      projectIds: transformMultipleValue(restValues?.projectIds),
      startDate: IntlService.formatDate(dateRange?.[0] || ''),
      endDate: IntlService.formatDate(dateRange?.[1] || '')
    }

    handleFilterValueChange(normalizeValues)
  }, [dateRange, cloneValues])

  const usersOptions = useMemo(
    () =>
      usersQueryName?.data?.users.map(({ name, id }) => ({
        value: id,
        label: name
      })),
    [usersQueryName?.data?.users]
  )

  const projectsOptions = useMemo(
    () =>
      projectsQueryName?.data?.projects.map(({ name, id }) => ({
        value: id,
        label: name
      })),
    [projectsQueryName?.data?.projects]
  )

  const workTypesOptions = useMemo(
    () =>
      workTypesQueryName?.data?.workTypes?.map((name) => ({
        label: name,
        value: name
      })),
    [workTypesQueryName]
  )

  return (
    <>
      <FormProvider methods={methods}>
        <Box
          display="flex"
          alignContent="center"
          flexWrap="wrap"
          mb={3}
          sx={{
            '> *': {
              mr: 2,
              mt: 1.5
            }
          }}
        >
          <AutocompleteField
            label="User name"
            name="userIds"
            options={usersOptions || []}
            multiple
          />

          <AutocompleteField
            label="Projects name"
            name="projectIds"
            width="220px"
            options={projectsOptions || []}
            multiple
          />

          <SelectField
            label="Work type"
            name="workType"
            width="220px"
            options={[{ label: 'All', value: '' }, ...(workTypesOptions || [])]}
          />

          <MUITextField
            name="details"
            label="Details"
            sx={{
              mr: 2,
              mt: 1.5,
              width: '180px'
            }}
          />

          <Box>
            <StaticDateRangePickerField name="dateRange" />
          </Box>

          <Box>
            <Button
              size="medium"
              variant="outlined"
              onClick={() => methods.reset()}
              sx={{ height: '40px' }}
            >
              Clear filter
            </Button>
          </Box>
        </Box>
      </FormProvider>

      {toggle && (
        <>
          <Suspense fallback={null}>
            <TimeLogsDialog open={toggle} variant="create" onClose={onClose} />
          </Suspense>
        </>
      )}
    </>
  )
}

export default TimeLogsFilter
