import React, { FC } from 'react'

import { TableCell, TableRow } from '@mui/material'

import ActionTableMenu from 'src/components/table/ActionTableMenu'

interface CredentialTableRowType {
  row: CredentialManagement
  onEditRow: (id: string) => void
  onDeleteRow: (id: string) => void
}

const CredentialTableRow: FC<CredentialTableRowType> = ({
  row,
  onEditRow,
  onDeleteRow
}) => {
  const { title, domain, serviceEmail, credentialType, projects, id } = row

  return (
    <TableRow hover>
      <TableCell align="left" width={400}>
        {title}
      </TableCell>

      <TableCell align="left" width={400}>
        {domain}
      </TableCell>

      <TableCell align="left" width={200}>
        {serviceEmail}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} width={150}>
        {credentialType}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} width={150}>
        {projects?.map((item) => item.name)?.join(', ')}
      </TableCell>

      <ActionTableMenu
        id={id}
        onEditRow={() => onEditRow(id)}
        onDeleteRow={() => onDeleteRow(id)}
      />
    </TableRow>
  )
}
export default CredentialTableRow
