import { merge } from 'lodash'
import { SyntheticEvent } from 'react'

import { useTheme } from '@mui/system'

import { BaseOptionChart } from 'src/components/chart'

export const getChartOptions = (
  labels: string[],
  onClick: (labelIndex: number, typeIndex: number) => void
) => {
  const theme = useTheme()

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.chart.green[0], theme.palette.chart.blue[0]],
    chart: {
      stacked: true,
      events: {
        dataPointSelection: (
          e: SyntheticEvent<EventTarget>,
          chartContext: unknown,
          config: DiagramConfig
        ) => {
          onClick && onClick(config.dataPointIndex, config.seriesIndex)
        }
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value} hours`
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  })

  return chartOptions
}
