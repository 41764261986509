import React from 'react'

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fade,
  IconButton
} from '@mui/material'

import PlanTasksTable from './PlanTasksTable'

import { PLANS_SUBTABLE_HEAD } from 'src/constants/table.constants'

interface WeeklyReportsSubTableProps {
  tasks?: Task[]
  open: boolean
}

const WeeklyReportsSubTable: React.FC<WeeklyReportsSubTableProps> = ({
  tasks,
  open
}) => (
  <>
    {open && (
      <Fade in={open}>
        <Box ml="49.5px">
          {!!tasks?.length && (
            <Table
              aria-label="purchases"
              sx={{
                borderLeft: '0.5px',
                ml: '0.5px'
              }}
            >
              <TableHead>
                <TableRow>
                  {PLANS_SUBTABLE_HEAD.map((head) => (
                    <TableCell
                      key={head.id}
                      align={head.align}
                      width={head.width}
                    >
                      {head.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tasks?.map((task, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{
                        '& > *': { borderBottom: 'unset' },
                        cursor: 'pointer'
                      }}
                    >
                      <TableCell>{task?.user.name}</TableCell>
                      <TableCell>{task?.workType}</TableCell>
                      <TableCell align="right">{task?.progress} % </TableCell>
                      <TableCell align="right">
                        {String(task?.description)}
                      </TableCell>
                    </TableRow>

                    {/* {openEntries[task.id] && !!task?.tasks.length && (
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={6}>
                            <PlanTasksTable
                              openEntries={openEntries[plan.id]}
                              entriesInfo={plan?.tasks}
                            />
                          </TableCell>
                        </TableRow>
                      )} */}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Fade>
    )}
  </>
)

export default WeeklyReportsSubTable
