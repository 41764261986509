import { merge } from 'lodash'
import { SyntheticEvent } from 'react'

import { useTheme } from '@mui/system'

import { BaseOptionChart } from 'src/components/chart'

export const getDiagramOptions = (
  labels: string[],
  onClick?: (index: number) => void
) => {
  const theme = useTheme()

  const chartOptions = merge(BaseOptionChart(), {
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },

    chart: {
      events: {
        dataPointSelection: (
          e: SyntheticEvent<EventTarget>,
          chartContext: unknown,
          config: DiagramConfig
        ) => {
          onClick && onClick(config.dataPointIndex)
        }
      }
    },
    colors: [theme.palette.chart.green[0], theme.palette.chart.blue[0]],
    labels: labels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: true,
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(0) + '%'
      }
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: '100%',
          labels: {
            show: false
          }
        }
      }
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value} hours`
      }
    }
  })
  return chartOptions
}
