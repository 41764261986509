import { UseFormReturn } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import {
  DialogContent,
  Box,
  Typography,
  Divider,
  DialogActions,
  Button,
  Stack
} from '@mui/material'

import {
  FormProvider,
  CheckboxField,
  SelectField,
  MUITextField,
  PasswordField
} from 'src/components/fields'

interface UsersFormDialogProps extends FormDialogProps {
  methods: UseFormReturn<UsersFV>
  positionOptions?: Options[]
  isCredForm?: boolean
}

const UsersFormDialog: React.FC<UsersFormDialogProps> = ({
  positionOptions,
  methods,
  isCredForm,
  onClose,
  onSubmit
}) => {
  const {
    formState: { isSubmitting }
  } = methods

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }
            }}
          >
            {isCredForm ? (
              <>
                <MUITextField name="userName" label="UserName" />

                <PasswordField name="password" label="Password" />

                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle1">Login:</Typography>

                  <CheckboxField
                    label="Login"
                    name="login"
                    size="medium"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '1rem'
                      }
                    }}
                  />
                </Stack>
              </>
            ) : (
              <>
                <MUITextField name="name" label="Name" />

                <SelectField
                  name="position"
                  label="Position"
                  options={positionOptions || []}
                />

                <Typography variant="subtitle1">Status:</Typography>

                <Stack spacing={2}>
                  <CheckboxField
                    label="Is Active"
                    name="active"
                    size="medium"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '1rem'
                      },
                      ml: 0
                    }}
                  />

                  <CheckboxField
                    label="Should Log"
                    name="shouldLog"
                    size="medium"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '1rem'
                      }
                    }}
                  />

                  <CheckboxField
                    label="Should Appear In Notice"
                    name="shouldAppearInNotice"
                    size="medium"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '1rem'
                      }
                    }}
                  />
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          size="medium"
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  )
}

export default UsersFormDialog
