import { entries, find, forIn, groupBy, sortBy } from 'lodash'

export const generateChannelPlanMessage = (plan: Plan) => {
  let message = `@here :spiral_calendar_pad: ${plan.date}\n\n`

  const t = groupBy(plan.tasks, 'user.id')
  const items = sortBy(entries(t), [(o) => o[0]])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forIn(items, (item: any) => {
    const { user } = find(item[1], (i) => item[0] === i.user.id)
    message += `${user.position}: :heavy_minus_sign: *${user.name}* :heavy_minus_sign:\n`

    const tasks = sortBy(item[1], [(t) => -Number(t.progress)])

    tasks.forEach((task) => {
      const inlineCode = '`' + task.progress + '%' + '`'
      message += `${inlineCode}: ${task.description}\n`
    })

    message += '\n'
  })

  message += 'Have a good day!'

  return message
}
