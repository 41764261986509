// @mui
import { useState } from 'react'

import { TableRow, TableCell, IconButton } from '@mui/material'

import TimeWorkSubTableRow from './TimeWorkSubTableRow'

import { Iconify } from 'src/components/base'

interface TimeWorkTableRowProps extends Partial<TableRowProps> {
  row: TimeToWork
  params: ObjectContain | TimeToWorkFiltersDto
}

const TimeWorkTableRow = ({ row, selected, params }: TimeWorkTableRowProps) => {
  const { name, difference, hoursWorked, estimatedHoursWorked, user_id } = row
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow
        selected={selected}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <TableCell
          align="center"
          sx={{
            p: 0,
            width: '50px',

            '&.MuiTableCell-root:first-of-type': {
              paddingLeft: 0
            }
          }}
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <Iconify icon="akar-icons:arrow-up" />
            ) : (
              <Iconify icon="akar-icons:arrow-down" />
            )}
          </IconButton>
        </TableCell>

        <TableCell align="left">{name}</TableCell>

        <TableCell align="left">{hoursWorked}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {estimatedHoursWorked}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {difference}
        </TableCell>
      </TableRow>

      {open && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <TimeWorkSubTableRow userId={user_id} open={open} params={params} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default TimeWorkTableRow
