import { FC, useCallback, useState } from 'react'
import { UseControllerProps } from 'react-hook-form'

import { InputAdornment, IconButton } from '@mui/material'

import { MUITextFieldProps, MUITextField } from '../MUITextField'

import { Iconify } from 'src/components/base'

export interface PasswordFieldCustomProps {
  hideHelper?: boolean
}

export type PasswordFieldProps = PasswordFieldCustomProps &
  MUITextFieldProps &
  UseControllerProps

const PasswordField: FC<PasswordFieldProps> = ({
  name,
  id,
  helperText,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const toggle = useCallback(() => setShowPassword((oldValue) => !oldValue), [])

  const showIcon = 'eva:eye-fill'
  const hideIcon = 'eva:eye-off-fill'

  const textType = 'text'
  const passwordType = 'password'

  return (
    <MUITextField
      name={name}
      id={id}
      placeholder="Password"
      {...rest}
      type={showPassword ? textType : passwordType}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggle}
              edge="end"
              data-testid="toogle-password-visibility"
            >
              <Iconify icon={showPassword ? showIcon : hideIcon} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordField
