import React from 'react'

import {
  Card,
  Container,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { TIME_WORK_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useDeleteHolidayMutation } from 'src/services/Query/holidays/holidays.mutation'
import { useGetTimeToWorks } from 'src/services/Query/time-to-work/time-to-work.query'
import { TimeWorkFilter, TimeWorkTableRow } from 'src/views/dashboard/TimeWork'

const TimeWork: React.FC = () => {
  const { themeStretch } = useSettings()
  const { dense, order, orderBy, selected, onChangePage } = useTable({
    defaultRowsPerPage: 25
  })

  const { mutateAsync } = useDeleteHolidayMutation()

  const { startCurrentWeek, date } = DatePickerService
  const startDate = IntlService.formatDate(startCurrentWeek.toString())
  const endDate = IntlService.formatDate(date.toString())

  const { params, handleFilterValueChange } = useFiltersTable<
    TimeToWorkQueryParams,
    TimeToWorkFiltersDto
  >({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      startDate,
      endDate,
      userIds: []
    }
  })

  const { data, isFetched, isFetching } = useGetTimeToWorks({
    params: { ...params },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const isNotFound = isFetched && !data?.length

  return (
    <Page title="Time to work">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Time to work
        </Typography>

        <TimeWorkFilter handleFilterValueChange={handleFilterValueChange} />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TIME_WORK_TABLE_HEAD}
                  rowCount={data?.length}
                  numSelected={selected.length}
                />

                <TableBody>
                  {data?.map((row) => (
                    <TimeWorkTableRow
                      key={row.user_id}
                      row={row}
                      params={params}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  )
}

export default TimeWork
