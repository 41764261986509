import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Container, Typography } from '@mui/material'

import { PATH_DASHBOARD } from '../../../routes/paths'

import { Page } from 'src/components/base'
import { FormProvider } from 'src/components/fields'
import useSettings from 'src/hooks/useSettings'
import Api from 'src/services/Api'
import { useGetLatestPlan } from 'src/services/Query/plans/plans.query'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { useGetWorkTypes } from 'src/services/Query/time-entries/time-entries.query'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { createOptions } from 'src/utils/functions'
import { PlanCreateSchema } from 'src/validations/schemas/plan.schema'
import PlanForm from 'src/views/dashboard/Plans/components/PlanForm/PlanForm'

const PROGRESS_TRACKER = {
  false: 'BOOLEAN',
  true: 'PERCENTS'
}

const fieldNames = {
  user: 'usersFields',
  task: 'taskFields'
}

const CreatePlan: React.FC = () => {
  const { themeStretch } = useSettings()
  const navigate = useNavigate()

  const { data } = useGetProjectsNames({ params: { active: true } })
  const { data: workTypesNames, isLoading } = useGetWorkTypes()
  const { data: usersName } = useGetUsersNames({
    params: { active: true }
  })

  const options = createOptions(data?.projects as Projects[])
  const optionsWorkType = workTypesNames?.workTypes.map((item) => ({
    value: item,
    label: item
  }))
  const optionsUsersName = createOptions(usersName?.users as Users[])

  const methods = useForm<CreatePlan>({
    resolver: yupResolver(PlanCreateSchema),
    defaultValues: {
      project: undefined,
      description: '',
      date: new Date(),
      progressTracker: false,
      active: false
    }
  })
  const haveProject = methods.watch().project
  const haveData = methods.watch().usersFields?.length

  const { data: planData, isLoading: isLoadingLatestPlan } = useGetLatestPlan(
    haveProject?.value || '',
    {
      // @ts-ignore
      enabled: !!haveProject?.value && !haveData
    }
  )

  const onSubmit = async ({ usersFields, project, ...plan }: CreatePlan) => {
    try {
      const tasks = usersFields?.map(({ user, taskFields }) => {
        const tasks = taskFields?.map((item) => ({
          ...item,
          userId: user.value,
          workType: item.workType.value
        }))

        return tasks
      })
      const tasksWithDtoFlat = tasks?.flat()

      await Api.createPlan({
        ...plan,
        projectId: project?.value,
        progressTracker: PROGRESS_TRACKER.true,
        tasks: tasksWithDtoFlat
      })

      navigate(PATH_DASHBOARD.general.plans.index)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    methods.reset()
  }, [methods.formState.isSubmitSuccessful])

  const handleLoadPlan = useCallback(() => {
    if (planData) {
      const { tasks, project, description } = planData
      const normalizeTasks = tasks.reduce((acc, { user, ...item }) => {
        //   @ts-ignore
        const a = acc[user.id] || []

        return {
          ...acc,
          [user.id]: [
            ...a,
            {
              ...item,
              workType: { value: item.workType, label: item.workType }
            }
          ]
        }
      }, {})

      const dto = Object.entries(normalizeTasks).map(([key]) => {
        const task = tasks.find(
          ({ user }: { user: PlanEditUser }) => user.id === key
        )

        return {
          user: { label: task?.user.name, value: task?.user.id },
          taskFields: []
        }
      })

      return methods.reset({
        project: { label: project.name, value: project.id },
        description,
        progressTracker: false,
        active: false,
        date: new Date(),
        usersFields: dto
      })
    }

    return methods.reset({})
  }, [isLoadingLatestPlan, planData])

  return (
    <Page title="Plan">
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Typography variant="h4" mb={1.5}>
            Create Project Plan
          </Typography>

          <PlanForm
            options={options}
            optionsUsersName={optionsUsersName}
            isLoading={isLoading}
            optionsWorkType={optionsWorkType}
            fieldNames={fieldNames}
          />

          {!!planData && !haveData && (
            <Box marginX="auto" width="240px">
              <Button variant="outlined" fullWidth onClick={handleLoadPlan}>
                Load previous plan
              </Button>
            </Box>
          )}
        </Container>
      </FormProvider>
    </Page>
  )
}

export default CreatePlan
