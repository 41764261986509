import React from 'react'

import { Container, Grid, Stack, Typography } from '@mui/material'

import { Page } from 'src/components/base'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { DashboardFilter, DashboardTable } from 'src/views/dashboard/Dashboard'
import HoursStatistic from 'src/views/dashboard/Dashboard/components/HoursStatistic'
import ProjectTypesDiagram from 'src/views/dashboard/Dashboard/components/ProjectTypesDiagram'

const Dashboard: React.FC = () => {
  const { themeStretch } = useSettings()
  const tableParams = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1
  })

  const { date, twoWeeksAgo } = DatePickerService
  const startDate = IntlService.formatDate(twoWeeksAgo.toString())
  const endDate = IntlService.formatDate(date.toString())

  const defaultValues = {
    page: 1,
    startDate,
    endDate
  }

  const { params, handleFilterValueChange } = useFiltersTable<
    DashboardQueryParams,
    DashboardFiltersDTO
  >({
    onChangePage: tableParams.onChangePage,
    defaultValueParams: defaultValues
  })

  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h4">Dashboard</Typography>

          <DashboardFilter
            handleFilterValueChange={handleFilterValueChange}
            defaultValues={defaultValues}
          />
        </Stack>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={7}>
            <HoursStatistic params={params} />
          </Grid>
          <Grid item xs={12} md={5}>
            <ProjectTypesDiagram params={params} />
          </Grid>
        </Grid>

        <DashboardTable tableParams={tableParams} />
      </Container>
    </Page>
  )
}

export default Dashboard
