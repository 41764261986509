import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import {
  DialogContent,
  Box,
  Divider,
  DialogActions,
  Button,
  Stack,
  FormControl,
  FormLabel
} from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  RadioGroupField,
  MUITextField,
  MultipleDatePickerField
} from 'src/components/fields'
interface AbsenceReportsFormDialogDialogProps extends FormDialogProps {
  methods: UseFormReturn<AbsenceReportsFV>
  nameOptions?: Options[]
}

const AbsenceReportsFormDialogDialog: React.FC<
  AbsenceReportsFormDialogDialogProps
> = ({ methods, onClose, onSubmit, nameOptions, isEdit }) => {
  const {
    formState: { isSubmitting }
  } = methods

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }
            }}
          >
            <AutocompleteField
              label="User name *"
              name="userInfo"
              width="100%"
              disabled={isEdit}
              defaultValueKey="all"
              options={nameOptions || []}
              ListboxProps={{ style: { maxHeight: 180 } }}
            />

            <MultipleDatePickerField name="date" label="Date" />

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                error={!!methods.formState.errors.reason}
              >
                Reason *
              </FormLabel>
              <RadioGroupField
                name="reason"
                options={['VACATION', 'ILLNESS', 'OTHER']}
              />
            </FormControl>

            <MUITextField sx={{ mt: 2.6 }} name="comment" label="Comment" />
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          size="medium"
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  )
}

export default AbsenceReportsFormDialogDialog
