import { useMutation, useQueryClient } from 'react-query'

import { usersConfig } from './users.query'

import Api from 'src/services/Api'

const usersMutationConfig = {
  deleteUsers: {
    key: 'users/delete',
    request: Api.deleteUsers
  },
  editUsers: {
    key: 'users/edit',
    request: Api.updateUsers
  }
}
export const useDeleteUsersMutation = () => {
  const queryClient = useQueryClient()

  const { deleteUsers: config } = usersMutationConfig

  const state = useMutation<{ id: string }, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersConfig.getUsers.key)
      }
    }
  )
  return state
}

export const useEditUsersMutation = () => {
  const queryClient = useQueryClient()

  const { editUsers: config } = usersMutationConfig

  const state = useMutation<UsersFV, string, { id: string; users: UsersFV }>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersConfig.getUsers.key)
      }
    }
  )
  return state
}
