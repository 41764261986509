import { useMutation, useQueryClient } from 'react-query'

import { planConfig } from './plans.query'

import Api from 'src/services/Api'

const plansMutationConfig = {
  deletePlan: {
    key: 'plan/delete',
    request: Api.deletePlan
  },
  editPlan: {
    key: 'plan/edit',
    request: Api.updatePlan
  }
}
export const useDeletePlanMutation = () => {
  const queryClient = useQueryClient()

  const { deletePlan: config } = plansMutationConfig

  const state = useMutation<UpdatePlanDto, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(planConfig.getPlans.key)
      }
    }
  )

  return state
}

export const useEditPlanMutation = () => {
  const queryClient = useQueryClient()

  const { editPlan: config } = plansMutationConfig

  const state = useMutation<UpdatePlanDto, string, UpdatePlanDto>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(planConfig.getPlans.key)
      }
    }
  )
  return state
}
