import { useState, ReactNode, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

// hooks

import useAuth from '../hooks/useAuth'
// pages
import Login from '../pages/auth/Login'

import { LoadingScreen } from 'src/components/base'
import LocalStorage from 'src/services/LocalStorage'
// components

type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  )

  const refreshToken = LocalStorage.getItem('refreshToken')

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated && !refreshToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}
