import React, { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Box, Button, Stack } from '@mui/material'

import { TasksArray } from '../TaskArray'

import { Iconify } from 'src/components/base'
import {
  AutocompleteField,
  DatePickerField,
  MUITextField
} from 'src/components/fields'

interface Props {
  options: Option[]
  isLoading: boolean
  fieldNames: {
    [key: string]: string
  }
  optionsWorkType:
    | {
        value: string
        label: string
      }[]
    | undefined
  optionsUsersName:
    | {
        value: string
        label: string
      }[]
    | undefined
  isEdit?: boolean
}

const PlanForm = ({
  options,
  isLoading,
  optionsUsersName,
  optionsWorkType,
  fieldNames,
  isEdit = false
}: Props) => {
  const methods = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    //@ts-ignore
    name: fieldNames.user
  })

  const handleAddUser = useCallback(() => {
    append({
      user: { label: '', value: '' },
      taskFields: []
    })
  }, [isLoading])

  const handleRemoveUser = useCallback(
    (id) => () => {
      remove(id)
    },
    [remove]
  )

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'grid',
          rowGap: 1,
          columnGap: 1,
          padding: 2,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr) auto'
          }
        }}
      >
        <AutocompleteField
          label="Project"
          name="project"
          width="100%"
          disabled={isEdit}
          defaultValueKey="all"
          options={options || []}
        />

        <DatePickerField
          name="date"
          label="Date"
          width="100%"
          disabled={isEdit}
        />

        <Button
          sx={{
            height: '40px',
            width: '100px'
          }}
          variant="outlined"
          onClick={handleAddUser}
          color="info"
          data-testid="add-user-btn"
        >
          <Iconify icon={'ic:baseline-person-add-alt'} width={1} height={1} />
        </Button>

        <Box
          sx={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 4,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              lg: 'repeat(1, 1fr)'
            },
            paddingTop: 2
          }}
        >
          <MUITextField
            name="description"
            sx={{
              width: '100%'
            }}
            disabled={isEdit}
            label="Plan description"
          />
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 4,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              lg: 'repeat(1, 1fr)'
            }
          }}
        >
          {fields.map((field, idx) => (
            <Stack key={field.id}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 2,
                  columnGap: 3,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: '1fr auto'
                  },
                  paddingY: 2
                }}
              >
                <AutocompleteField
                  label="User"
                  name={`${fieldNames.user}.${idx}.user`}
                  width="100%"
                  defaultValueKey="all"
                  options={optionsUsersName || []}
                />

                <Button
                  sx={{ height: '40px' }}
                  variant="outlined"
                  onClick={handleRemoveUser(idx)}
                  color="error"
                  data-testid="remove-user-btn"
                >
                  <Iconify icon={'mdi:account-remove'} width={1} height={1} />
                </Button>

                <TasksArray
                  index={idx}
                  fieldNames={fieldNames}
                  optionsWorkType={optionsWorkType}
                />
              </Box>
            </Stack>
          ))}
        </Box>

        {!!fields.length && (
          <Box
            sx={{
              gridColumnStart: 2,
              gridColumnEnd: 4,
              justifySelf: 'right'
            }}
          >
            <Button
              sx={{ height: '40px', width: '100px' }}
              variant="contained"
              type="submit"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default PlanForm
