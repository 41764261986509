import React, { useCallback } from 'react'

import { TableRow, TableCell, IconButton } from '@mui/material'

import PlansSubTable from './PlansSubTable'

import { Iconify } from 'src/components/base'
import ActionTableMenu from 'src/components/table/ActionTableMenu'
import { generateChannelPlanMessage } from 'src/utils/generateChannelPlanMessage'

interface PlansTableRowProps extends Partial<TableRowProps> {
  row: Plan
}

const PlansTableRow: React.FC<PlansTableRowProps> = ({
  row,
  onEditRow,
  onDeleteRow
}) => {
  const [open, setOpen] = React.useState(false)

  const onCoppy = useCallback(() => {
    const message = generateChannelPlanMessage(row)

    navigator.clipboard.writeText(message)
  }, [row])

  const completedTasks = row.tasks.filter(
    (item) => Number(item.progress) === 100
  )

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}>
        <TableCell
          align="center"
          sx={{
            p: 0,
            width: '50px',

            '&.MuiTableCell-root:first-of-type': {
              paddingLeft: 0
            }
          }}
          onClick={() => {
            if (row?.tasks.length) {
              setOpen(!open)
            }
          }}
        >
          <>
            <IconButton aria-label="expand row" size="small">
              {open ? (
                <Iconify icon="akar-icons:arrow-up" />
              ) : (
                <Iconify icon="akar-icons:arrow-down" />
              )}
            </IconButton>
          </>
        </TableCell>

        <TableCell align="left">{row.project.name}</TableCell>
        <TableCell align="left">{row.description}</TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">{row.progressTracker}</TableCell>
        <TableCell align="left">{String(row.active)}</TableCell>
        <TableCell align="right">
          {completedTasks.length} / {row.tasks.length}
        </TableCell>

        <ActionTableMenu
          id={row.id}
          onEditRow={onEditRow}
          onDeleteRow={onDeleteRow}
          onCoppy={onCoppy}
        />
      </TableRow>

      {open && !!row?.tasks.length && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <PlansSubTable open={open} tasks={row?.tasks} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default PlansTableRow
