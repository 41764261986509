import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'

import { Card } from '@mui/material'

import { getChartOptions } from './IntervalStatisticOptions'

import { EmptyContent } from 'src/components/base'
import { formatDataForBarChart } from 'src/utils/functions'

interface IntervalStatisticProps {
  data?: PeriodStatistic
  projectTypes: string[]
  dateRange: string[]
  onClick: (labelIndex: number, typeIndex: number) => void
}

const IntervalStatisticChart: FC<IntervalStatisticProps> = ({
  data,
  projectTypes,
  dateRange,
  onClick
}) => {
  const series = formatDataForBarChart(projectTypes, data)

  const options = getChartOptions(dateRange, onClick)

  return (
    <Card>
      {!data ? (
        <EmptyContent title="No Data" />
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={341}
        />
      )}
    </Card>
  )
}
export default IntervalStatisticChart
