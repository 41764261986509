import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import {
  DialogContent,
  Box,
  Divider,
  DialogActions,
  Button,
  Stack
} from '@mui/material'

import { FormProvider, MUITextField } from 'src/components/fields'

interface AdminsFormDialogProps extends FormDialogProps {
  methods: UseFormReturn
}

const AdminsFormDialog: React.FC<AdminsFormDialogProps> = ({
  methods,
  isEdit,
  onClose,
  onSubmit
}) => {
  const {
    formState: { isSubmitting }
  } = methods

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }
            }}
          >
            <MUITextField name="email" label="Email" required />

            {!isEdit && (
              <MUITextField name="password" label="Password" required />
            )}
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          size="medium"
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  )
}

export default AdminsFormDialog
