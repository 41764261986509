import React from 'react'

import { TableRow, TableCell, IconButton } from '@mui/material'

import WeeklyReportsSubTable from './WeeklyReportsSubTable'

import { Iconify } from 'src/components/base'

interface WeeklyReportsTableRowProps extends Partial<TableRowProps> {
  row: ProjectReports
}

const WeeklyReportsTableRow: React.FC<WeeklyReportsTableRowProps> = ({
  row
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        onClick={() => {
          if (row?.entries.length) {
            setOpen(!open)
          }
        }}
      >
        <TableCell
          align="center"
          sx={{
            p: 0,
            width: '50px',

            '&.MuiTableCell-root:first-of-type': {
              paddingLeft: 0
            }
          }}
        >
          {row.totalProjectTime !== '00:00' && (
            <>
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <Iconify icon="akar-icons:arrow-up" />
                ) : (
                  <Iconify icon="akar-icons:arrow-down" />
                )}
              </IconButton>
            </>
          )}
        </TableCell>

        <TableCell align="left">{row.projectName}</TableCell>
        <TableCell align="right">{row.totalProjectTime}</TableCell>
      </TableRow>

      {open && !!row?.entries.length && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <WeeklyReportsSubTable open={open} timeEntries={row?.entries} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default WeeklyReportsTableRow
