import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import { GooglePostmasterResponse } from 'src/@types/domain-statistics'
import Api from 'src/services/Api'

export const domainsConfig = {
  getDomainsList: {
    key: 'domain-statistics/list',
    request: async () => {
      const resp = await Api.getDomainsList()

      return resp
    }
  },
  getDomainStatistics: {
    key: 'domain-statistics/domain',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, id] = queryKey as [string, string]

      const resp = await Api.getDomainStatistics(id)

      return resp.data
    }
  }
}

interface UseGetDomainsList {
  options?: UseQueryOptions<GooglePostmasterResponse[]>
}

export const useGetDomainsList = (props?: UseGetDomainsList) => {
  const { options } = props || {}
  const { getDomainsList: config } = domainsConfig

  const state = useQuery<GooglePostmasterResponse[]>(
    [config.key],
    config.request,
    options
  )
  return state
}

interface UseGetDomainStatistics {
  domain: string
  options?: UseQueryOptions<GooglePostmasterResponse>
}

export const useGetDomainStatistics = (props?: UseGetDomainStatistics) => {
  const { domain, options } = props || {}
  const { getDomainStatistics: config } = domainsConfig

  const state = useQuery<GooglePostmasterResponse>(
    [config.key, domain],
    config.request,
    {
      ...options
    }
  )
  return state
}
