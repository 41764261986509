import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { USERS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { useDeleteUsersMutation } from 'src/services/Query/users/users.mutation'
import {
  useGetUsers,
  useGetUsersById
} from 'src/services/Query/users/users.query'
import { UsersFilter, UsersTableRow } from 'src/views/settings/Users'

const UsersDialog = React.lazy(
  () => import('src/views/settings/Users/components/UsersDialog')
)

const Users: React.FC = () => {
  const { themeStretch } = useSettings()
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSort,
    onChangePage
  } = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1,
    defaultOrderBy: 'createdAt'
  })

  const { mutateAsync } = useDeleteUsersMutation()

  const {
    activeId,
    params,
    toggleEdit,
    toggleCustomEdit,
    onCloseEdit,
    onCloseCustomEdit,
    handleEditRow,
    handleCustomEditRow,
    handleFilterValueChange
  } = useFiltersTable<UsersQueryParams, UsersFV>({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      page: 1,
      term: '',
      role: '',
      active: 'true'
    }
  })

  const { data, isFetched, isFetching } = useGetUsers({
    params: {
      ...params,
      page,
      limit: rowsPerPage,
      order,
      sort: orderBy
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: user, refetch } = useGetUsersById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const revalidateDatHandler = () => refetch()

  const isNotFound = isFetched && !data?.users?.length

  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Users">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Users
        </Typography>

        <UsersFilter handleFilterValueChange={handleFilterValueChange} />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.users?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={USERS_TABLE_HEAD}
                  rowCount={data?.users?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  ignoreHeadSort={[
                    'isActive',
                    'created',
                    'username',
                    'havePassword',
                    'login',
                    ''
                  ]}
                />

                <TableBody>
                  {data?.users?.map((row) => (
                    <UsersTableRow
                      key={row.id}
                      row={row}
                      onEditRow={handleEditRow}
                      onCustomEditRow={handleCustomEditRow}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>

      {toggleEdit && (
        <>
          <Suspense fallback={null}>
            <UsersDialog
              open={toggleEdit}
              values={user}
              variant="edit"
              onClose={onCloseEdit}
              revalidateData={revalidateDatHandler}
            />
          </Suspense>
        </>
      )}
      {toggleCustomEdit && (
        <>
          <Suspense fallback={null}>
            <UsersDialog
              open={toggleCustomEdit}
              values={user}
              variant="customEdit"
              onClose={onCloseCustomEdit}
              revalidateData={revalidateDatHandler}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default Users
