import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const timeEntriesConfig = {
  getTimeLogs: {
    key: 'time-entries/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, TimeEntriesQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getTimeLogs(normalizesParams)

      return resp.data
    }
  },
  getTimeLogsById: {
    key: 'time-entries/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, id] = queryKey as [string, string]

      const resp = await Api.getTimeLogsById(id)

      return resp.data
    }
  },
  getWorkTypes: {
    key: 'work-types',
    request: async () => {
      const resp = await Api.getWorkTypes()

      return resp.data
    }
  }
}

interface UseGetTimeEntries {
  params?: TimeEntriesQueryParams
  options?: UseQueryOptions<TimeEntriesResponse>
}

export const useGetTimeEntries = (props?: UseGetTimeEntries) => {
  const { options, params } = props || {}
  const { getTimeLogs: config } = timeEntriesConfig

  const state = useQuery<TimeEntriesResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetTimeEntriesById {
  id: string
  options?: UseQueryOptions<TimeEntriesResponse>
}

export const useGetTimeEntriesById = (props?: UseGetTimeEntriesById) => {
  const { id, options } = props || {}
  const { getTimeLogsById: config } = timeEntriesConfig

  const state = useQuery<TimeEntriesResponse>(
    [config.key, id],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetWorkTypes {
  options?: UseQueryOptions<WorkTypesResponse>
}

export const useGetWorkTypes = (props?: UseGetWorkTypes) => {
  const { options } = props || {}
  const { getWorkTypes: config } = timeEntriesConfig

  const state = useQuery<WorkTypesResponse>(config.key, config.request, {
    ...options
  })
  return state
}
