import { useSearchParams } from 'react-router-dom'

export const useQuerySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setParams = (params: { [key: string]: string }) => {
    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key]) && params[key].length === 0) {
        searchParams.delete(key)
        return
      }
      if (key && params[key]) {
        searchParams.set(key, params[key])
      } else {
        searchParams.delete(key)
      }
    })
    setSearchParams(searchParams)
  }

  const getParams = (params: string[]) => {
    const res = {} as { [key: string]: string | null }
    params.forEach((item) => {
      const key = item
      res[`${item}`] = searchParams.get(key)
    })
    return res
  }

  return { setParams, getParams }
}
