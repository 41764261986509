// @mui
import { Checkbox, TableRow, TableCell } from '@mui/material'

import { IntlService } from 'src/services/IntlService'

interface TimeLogsTableRowProps extends Partial<TableRowProps> {
  row: TimeEntries
}

const TimeLogsTableRow = ({
  row,
  selected,
  onSelectRow
}: TimeLogsTableRowProps) => {
  const {
    project: { name: projectName },
    user: { name: userName },
    date,
    time,
    workType,
    description,
    createdAt
  } = row

  return (
    <TableRow hover selected={selected}>
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}

      <TableCell align="left" width={200}>
        {userName}
      </TableCell>

      <TableCell align="left" width={200}>
        {projectName}
      </TableCell>

      <TableCell align="left" width={250}>
        {IntlService.formatDate(createdAt, {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }).replace(',', '')}
      </TableCell>

      <TableCell align="left" width={200}>
        {IntlService.formatDate(date)}
      </TableCell>

      <TableCell align="left" width={150}>
        {time}
      </TableCell>

      <TableCell align="left" width={150}>
        {workType}
      </TableCell>

      <TableCell align="left" width={400}>
        {description}
      </TableCell>

      {/* <ActionTableMenu
        id={id}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        onViewRow={onViewRow}
      /> */}
    </TableRow>
  )
}

export default TimeLogsTableRow
