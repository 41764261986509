import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const dashboardQueryConfig = {
  getAbsencesComing: {
    key: 'dashboard/absences-coming',
    request: Api.getAbsencesComing
  },
  getHoursStatistic: {
    key: 'dashboard/hours-statistic',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, DashboardQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getHoursStatistic(normalizesParams)

      return resp.data
    }
  },
  getPeriodHoursStatistic: {
    key: 'dashboard/period-hours-statistic',
    request: Api.getPeriodHoursStatistic
  },
  getProjectsStatistic: {
    key: 'dashboard/projects-statistic',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, DashboardQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getProjectsStatistic(normalizesParams)

      return resp.data
    }
  }
}

export const useGetAbsencesComing = (props?: {
  options?: UseQueryOptions<{ data: AbsencesComingResponse }>
}) => {
  const { options } = props || {}
  const { getAbsencesComing: config } = dashboardQueryConfig
  const state = useQuery<{ data: AbsencesComingResponse }>(
    [config.key],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetHoursStatistic {
  params?: DashboardQueryParams
  options?: UseQueryOptions<HoursStatistic>
}

export const useGetHoursStatistic = (props?: UseGetHoursStatistic) => {
  const { options, params } = props || {}
  const { getHoursStatistic: config } = dashboardQueryConfig

  const state = useQuery<HoursStatistic>([config.key, params], config.request, {
    ...options
  })
  return state
}
export const useGetPeriodHoursStatistic = (props?: {
  options?: UseQueryOptions<PeriodStatisticResponse>
}) => {
  const { options } = props || {}
  const { getPeriodHoursStatistic: config } = dashboardQueryConfig

  const state = useQuery<PeriodStatisticResponse>(
    [config.key],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetProjectsStatistic {
  params?: DashboardQueryParams
  options?: UseQueryOptions<ProjectsStatisticResponse>
}

export const useGetProjectsStatistic = (props?: UseGetProjectsStatistic) => {
  const { options, params } = props || {}
  const { getProjectsStatistic: config } = dashboardQueryConfig

  const state = useQuery<ProjectsStatisticResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}
