import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Stack } from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  StaticDateRangePickerField
} from 'src/components/fields'
import { useQuerySearchParams } from 'src/hooks/useQuerySearchParams'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { IntlService } from 'src/services/IntlService'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { createOptions, transformMultipleValue } from 'src/utils/functions'

interface PlansFilterProps {
  handleFilterValueChange: (value: WeeklyReportFilters) => void
  defaultValues: {
    projectIds: Option[]
    dateRange: string[]
  }
}

const Filter: React.FC<PlansFilterProps> = ({
  handleFilterValueChange,
  defaultValues
}) => {
  const methods = useForm({
    defaultValues: {
      projectIds: defaultValues.projectIds,
      dateRange: [defaultValues.dateRange[0], defaultValues.dateRange[1]]
    }
  })

  const { data } = useGetProjectsNames({ params: { active: true } })

  const options = createOptions(data?.projects || [])

  const { dateRange, ...restValues } = methods.watch()
  const cloneValues = JSON.stringify(restValues)

  useEffect(() => {
    const normalizeValues: WeeklyReportFilters = {
      projectIds: transformMultipleValue(restValues.projectIds),
      startDate: IntlService.formatDate(dateRange?.[0] || ''),
      endDate: IntlService.formatDate(dateRange?.[1] || '')
    }

    handleFilterValueChange(normalizeValues)
  }, [cloneValues, dateRange])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          mb={3}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={2}
            marginBottom={{ sm: '20px' }}
            width={{ xs: '100%', md: '700px' }}
          >
            <Box width={{ xs: '100%', md: 240 }}>
              <AutocompleteField
                width="100%"
                name="projectIds"
                label="Projects name"
                options={options || []}
                multiple
              />
            </Box>

            <Box width={{ xs: '100%', md: '240px' }}>
              <StaticDateRangePickerField name="dateRange" width="100%" />
            </Box>

            <Button
              variant="outlined"
              sx={{ height: '40px' }}
              onClick={() => methods.reset()}
            >
              Clear filter
            </Button>
          </Stack>

          <Box width={{ xs: '100%', md: '150px' }}>
            <Button
              fullWidth
              component={RouterLink}
              to={PATH_DASHBOARD.general.plans.create}
              sx={{ height: '40px' }}
              variant="contained"
            >
              Add new plan
            </Button>
          </Box>
        </Stack>
      </FormProvider>

      {/* {toggle && (
        <ProjectsDialog
          title="Create project"
          open={toggle}
          variant="create"
          onClose={onClose}
        />
      )} */}
    </>
  )
}

export default Filter
