import React, { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Box, Button, Stack } from '@mui/material'

import { Iconify } from 'src/components/base'
import { AutocompleteField, MUITextField } from 'src/components/fields'

type Props = {
  index: number
  fieldNames: {
    [key: string]: string
  }
  optionsWorkType:
    | {
        value: string
        label: string
      }[]
    | undefined
}

export const TasksArray = ({ index, fieldNames, optionsWorkType }: Props) => {
  const { control, watch } = useFormContext()
  const {
    fields: taskFields,
    append: taskAppend,
    remove: taskRemove
  } = useFieldArray({
    control: control,
    //@ts-ignore
    name: `${fieldNames.user}.${index}.${fieldNames.task}`,
    shouldUnregister: true
  })
  const user = watch(`${fieldNames.user}.${index}.user`)

  const handleAddTask = useCallback(() => {
    if (user.position === 'PM') {
      return taskAppend({
        workType: optionsWorkType?.[4],
        description: ''
      })
    }
    return taskAppend({
      workType: optionsWorkType?.[0],
      description: ''
    })
  }, [optionsWorkType, user])

  const handleRemoveTask = useCallback(
    (id) => () => {
      taskRemove(id)
    },
    [taskRemove]
  )

  return (
    <>
      <Button
        sx={{
          height: '40px'
        }}
        variant="outlined"
        onClick={handleAddTask}
        color="info"
        data-testid="add-task-btn"
      >
        <Iconify icon={'gg:file-add'} width={1} height={1} />
      </Button>
      <Box
        sx={{
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 4,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            lg: 'repeat(1, 1fr)'
          }
        }}
      >
        {taskFields.map((field, taskIndex) => (
          <Stack key={field.id}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 2,
                columnGap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: '200px 100px 2fr auto'
                },
                paddingY: 2
              }}
            >
              <AutocompleteField
                label="Work Type"
                name={`${fieldNames.user}.${index}.${fieldNames.task}.${taskIndex}.workType`}
                width="100%"
                defaultValueKey="all"
                options={optionsWorkType || []}
              />

              <MUITextField
                name={`${fieldNames.user}.${index}.${fieldNames.task}.${taskIndex}.progress`}
                sx={{
                  width: '100px'
                }}
                label="Progress"
                disabled={true}
              />

              <MUITextField
                name={`${fieldNames.user}.${index}.${fieldNames.task}.${taskIndex}.description`}
                sx={{
                  width: '100%'
                }}
                label="Description"
              />

              <Button
                sx={{ height: '40px' }}
                variant="outlined"
                onClick={handleRemoveTask(taskIndex)}
                color="error"
                data-testid="remove-task-btn"
              >
                <Iconify icon={'gg:file-remove'} width={1} height={1} />
              </Button>
            </Box>
          </Stack>
        ))}
      </Box>
    </>
  )
}
