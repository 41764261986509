import { PATH_DASHBOARD } from '../../../routes/paths'

import { Iconify } from 'src/components/base'

export const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  blog: getIcon('carbon:blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('bx:user'),
  admins: getIcon('foundation:crown'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ant-design:calendar-filled'),
  absence: getIcon('ant-design:calendar-twotone'),
  holidays: getIcon('emojione-monotone:palm-tree'),
  ecommerce: getIcon('ic_ecommerce'),
  time: getIcon('ant-design:field-time-outlined'),
  timeToWork: getIcon('healthicons:health-worker'),
  dashboard: getIcon('mdi:view-dashboard'),
  projects: getIcon('charm:rocket'),
  plan: getIcon('mdi:text-box'),
  credentials: getIcon('carbon:id-management'),
  domainStatistics: getIcon('akar-icons:statistic-up')
}

const navConfig = [
  {
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Time logs',
        path: PATH_DASHBOARD.general.timeLogs,
        icon: ICONS.time
      },
      {
        title: 'Weekly report',
        path: PATH_DASHBOARD.general.weeklyReport,
        icon: ICONS.calendar
      },
      {
        title: 'Time to work',
        path: PATH_DASHBOARD.general.timeToWork,
        icon: ICONS.timeToWork
      },
      {
        title: 'Absence report',
        path: PATH_DASHBOARD.general.absenceReport,
        icon: ICONS.absence
      },
      {
        title: 'holidays',
        path: PATH_DASHBOARD.general.holidays,
        icon: ICONS.holidays
      },
      {
        title: 'projects',
        path: PATH_DASHBOARD.general.projects,
        icon: ICONS.projects
      },
      {
        title: 'plans',
        path: PATH_DASHBOARD.general.plans.index,
        icon: ICONS.plan
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.general.users,
        icon: ICONS.user
      },
      {
        title: 'Management Credentials',
        path: PATH_DASHBOARD.general.credentials,
        icon: ICONS.credentials
      },
      {
        title: 'Domain Statistics',
        path: PATH_DASHBOARD.general.domainStatistics,
        icon: ICONS.domainStatistics
      }
    ]
  }
]

export default navConfig
