import { useMutation, useQueryClient } from 'react-query'

import { projectsConfig } from './projects.query'

import Api from 'src/services/Api'

const projectsMutationConfig = {
  createProjects: {
    key: 'projects/create',
    request: Api.createProjects
  },
  deleteProjects: {
    key: 'projects/delete',
    request: Api.deleteProjects
  },
  editProjects: {
    key: 'projects/edit',
    request: Api.updateProjects
  }
}

export const useCreateProjectsMutation = () => {
  const queryClient = useQueryClient()

  const { createProjects: config } = projectsMutationConfig

  const state = useMutation<ProjectsFV, string, ProjectsFV>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectsConfig.getProjects.key)
        queryClient.invalidateQueries(projectsConfig.getProjectsNames.key)
      }
    }
  )
  return state
}
export const useDeleteProjectsMutation = () => {
  const queryClient = useQueryClient()

  const { deleteProjects: config } = projectsMutationConfig

  const state = useMutation<Projects, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectsConfig.getProjects.key)
        queryClient.invalidateQueries(projectsConfig.getProjectsNames.key)
      }
    }
  )
  return state
}

export const useEditProjectsMutation = () => {
  const queryClient = useQueryClient()

  const { editProjects: config } = projectsMutationConfig

  const state = useMutation<
    Projects,
    string,
    { id: string; projects: ProjectsFV }
  >(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsConfig.getProjects.key)
      queryClient.invalidateQueries(projectsConfig.getProjectsNames.key)
      queryClient.invalidateQueries(projectsConfig.getProjectsById.key)
    }
  })
  return state
}
