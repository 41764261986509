import React from 'react'

import { Stack, Typography } from '@mui/material'

interface TimeLogsProjectDetailsProps {
  report?: TimeEntriesProjectReport[]
}

const TimeLogsProjectDetails: React.FC<TimeLogsProjectDetailsProps> = ({
  report
}) => (
  <Stack direction="column" flexWrap="wrap" mb={3}>
    {report?.map((el, index) => (
      <Stack direction="column" width={300} p={1} key={index}>
        {el?.projectName && (
          <Typography fontWeight={700}>{el?.projectName}</Typography>
        )}

        {el?.users &&
          el.users.map(
            ({ name, workedHours }: ProjectReportUser, index: number) => (
              <Typography key={index}>{`${name}: ${workedHours}`}</Typography>
            )
          )}
        <Typography fontWeight={700}>Total: {el?.totalTimeEntries}</Typography>
      </Stack>
    ))}
  </Stack>
)

export default TimeLogsProjectDetails
