import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import { Dialog } from '@mui/material'

import TimeLogsFormDialog from './TimeLogsFormDialog'
import TimeLogsViewDialog from './TimeLogsViewDialog'

import { VariantDialogTitle } from 'src/components/dialogs'
import DialogViewService from 'src/services/DialogViewService'

export default function TimeLogsDialog({
  open,
  onClose,
  variant = 'create',
  values
}: VarianDialogProps) {
  const { enqueueSnackbar } = useSnackbar()
  const isView = variant === 'view'
  const isEdit = variant === 'edit'
  const isCreate = variant === 'create'
  const isEditable = isCreate || isEdit

  const defaultValues = {
    userName: '',
    projects: '',
    details: '',
    date: '',
    time: '',
    ...values
  }

  const methods = useForm<TimeEntriesFV>({
    defaultValues
  })

  useEffect(() => {
    if (isEdit) {
      methods.reset(defaultValues)
    }
  }, [values?.name])

  const onSubmit = async () => {
    try {
      enqueueSnackbar('Create success!')
      onClose()
    } catch (error) {
      enqueueSnackbar(error?.data?.message, { variant: 'error' })
    }
  }

  const viewData = DialogViewService.timeLogsView(values)

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        createTitle="Create"
        editTitle="Edit"
        viewTitle="View"
        variant={variant}
        onClose={onClose}
      />

      {isView && <TimeLogsViewDialog viewData={viewData} />}

      {isEditable && (
        <TimeLogsFormDialog
          onClose={onClose}
          isView={isView}
          isEdit={isEdit}
          methods={methods}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}
