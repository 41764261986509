import { Box, Card, Typography, Stack, Grid, useTheme } from '@mui/material'

type Props = {
  type: 'CLIENT' | 'INTERNAL'
  markerColor?: string
  data?: ProjectStatistic[]
}

export default function StatisticInfoCard({ type, data, markerColor }: Props) {
  const theme = useTheme()

  const isClient = type === 'CLIENT'

  const markersColors = {
    client: theme.palette.chart.green[0],
    internal: theme.palette.chart.blue[0]
  }

  return (
    <Card
      sx={{
        p: 2,
        height: '100%'
      }}
    >
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">{`Top 3 ${type} projects`}</Typography>

          <Box
            component="span"
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              bgcolor: isClient ? markersColors.client : markersColors.internal
            }}
          />
        </Stack>
        {data?.map((proj) => (
          <Grid container spacing={2} pt={1} key={proj.id}>
            <Grid item xs={8}>
              <Typography variant="body2" noWrap>
                {proj.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">{`${proj.hoursToWork}`}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Card>
  )
}
