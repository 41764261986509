import React from 'react'

import { Stack, Typography } from '@mui/material'

interface TimeLogsUserDetailsProps {
  report?: TimeEntriesUserReport[]
}

const TimeLogsUserDetails: React.FC<TimeLogsUserDetailsProps> = ({
  report
}) => (
  <Stack direction="column" flexWrap="wrap" mb={3}>
    {report?.map((el, index) => (
      <Stack direction="column" width={300} p={1} key={index}>
        {el?.userName && (
          <Typography fontWeight={700}>{el?.userName}</Typography>
        )}

        {el?.projects &&
          Object.entries(el?.projects || {}).map(([key, value]) => (
            <Typography key={key}>{`${key}: ${value}`}</Typography>
          ))}

        <Typography fontWeight={700}>Total: {el?.totalTimeEntries}</Typography>
      </Stack>
    ))}
  </Stack>
)

export default TimeLogsUserDetails
