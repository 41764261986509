import { isEqual } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  DateRange,
  DateRangePickerProps,
  StaticDateRangePicker
} from '@mui/lab'
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput'
import {
  Box,
  Button,
  InputAdornment,
  Popover,
  Stack,
  TextField
} from '@mui/material'

import { Iconify } from '../base'

import { MUITextField } from '.'

import { ConfigProps, DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'

interface StaticDateRangePickerFieldProps
  extends Partial<DateRangePickerProps<Date>> {
  name: string
  label?: string
  width?: string
}

const StaticDateRangePickerField: React.FC<StaticDateRangePickerFieldProps> = ({
  name,
  width,
  ...other
}) => {
  const { control, setValue, watch } = useFormContext()

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const staticButtonConfig: ConfigProps[] =
    DatePickerService.generateConfigButtonConfig({
      name,
      onClose: handleClose,
      setValue
    })

  const open = Boolean(anchorEl)

  const startDate = IntlService.formatDate(watch(`${name}.0`) || '')
  const endDate = IntlService.formatDate(watch(`${name}.1`) || '')

  const handleChange = useCallback(
    (value: DateRange<Date[]>) => {
      !value[1]
        ? setValue('dateRange', [value[0], value[0]])
        : setValue('dateRange', value)
    },
    [setValue]
  )

  return (
    <>
      <TextField
        name="startDate"
        label="Date"
        size="small"
        inputProps={{ readOnly: true }}
        value={startDate && `${startDate} - ${endDate}`}
        sx={{
          width: width || '240px',

          '& .MuiOutlinedInput-input': {
            cursor: 'pointer',
            WebkitTextFillColor: '#212B36'
          }
        }}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Iconify
                icon={'mdi:calendar'}
                sx={{ cursor: 'pointer' }}
                width={25}
                height={30}
              />
            </InputAdornment>
          )
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack
          direction="row-reverse"
          sx={{
            '& .PrivatePickersSlideTransition-root': {
              minHeight: '265px'
            }
          }}
        >
          <Controller
            {...other}
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { value, ...rest } = field

              return (
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  startText="Start date"
                  showDaysOutsideCurrentMonth
                  allowSameDateSelection
                  inputFormat="yyyy-MM-dd"
                  endText="End date"
                  value={value || [null, null]}
                  renderInput={(
                    startProps: MuiTextFieldProps,
                    endProps: MuiTextFieldProps
                  ) => (
                    <>
                      <MUITextField
                        name=""
                        error={!!error}
                        {...startProps}
                        value={value?.[0]}
                      />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <MUITextField {...endProps} value={value?.[1]} name="" />
                    </>
                  )}
                  {...rest}
                  onChange={handleChange}
                />
              )
            }}
          />

          <Stack
            direction="column"
            spacing={0.5}
            p={1}
            borderRight="3px solid #eceff1"
          >
            {staticButtonConfig.map(({ title, onClick, itemValue }) => {
              const isActive = isEqual(
                [
                  IntlService.formatDate(watch(`${name}.0`) || ''),
                  IntlService.formatDate(watch(`${name}.1`) || '')
                ],
                [
                  IntlService.formatDate(itemValue[0].toString() || ''),
                  IntlService.formatDate(itemValue[1].toString() || '')
                ]
              )

              return (
                <Button
                  key={title}
                  variant={isActive ? 'contained' : 'text'}
                  onClick={onClick}
                >
                  {title}
                </Button>
              )
            })}
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

export default StaticDateRangePickerField
