import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Stack,
  Button
} from '@mui/material'

const AdminsDialog = React.lazy(
  () => import('src/views/settings/Admins/components/AdminsDialog')
)

import { HeaderBreadcrumbs, Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { ADMINS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import useToggle from 'src/hooks/useToggle'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useDeleteHolidayMutation } from 'src/services/Query/holidays/holidays.mutation'
import {
  useGetHolidayById,
  useGetHolidays
} from 'src/services/Query/holidays/holidays.query'
import { AdminsTableRow } from 'src/views/settings/Admins'

const Admins: React.FC = () => {
  const { themeStretch } = useSettings()
  const {
    toggle: toggleCreate,
    onClose: onCloseCreate,
    onOpen: onOpenCreate
  } = useToggle(false)

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultRowsPerPage: 25
  })

  const { mutateAsync } = useDeleteHolidayMutation()

  const {
    activeId,
    params,
    toggleEdit,
    toggleView,
    onCloseEdit,
    onCloseView,
    handleDeleteRow,
    handleEditRow,
    handleViewRow
  } = useFiltersTable<HolidaysQueryParams>({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      page: 1,
      endDate: '',
      startDate: '',
      term: ''
    }
  })

  const { data, isFetched, isFetching } = useGetHolidays({
    params: { ...params, page: page + 1, limit: rowsPerPage },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: holiday } = useGetHolidayById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const isNotFound = isFetched && !data?.holidays?.length

  return (
    <Page title="Admins">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Admins"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.app },
            { name: 'Admins' }
          ]}
        />
        <Stack direction="row" width="100%" justifyContent="end" mb={3}>
          <Button size="large" variant="contained" onClick={onOpenCreate}>
            Add new admin
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.holidays?.length || 0}
                />
              )}
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={ADMINS_TABLE_HEAD}
                  rowCount={data?.holidays?.length}
                  numSelected={selected.length}
                />

                <TableBody>
                  {data?.holidays?.map((row) => (
                    <AdminsTableRow
                      key={row.id}
                      row={row}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={handleEditRow}
                      onViewRow={handleViewRow}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} of ${count}`
              }
              labelRowsPerPage="Rows per page:"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>

      {toggleCreate && (
        <>
          <Suspense fallback={null}>
            <AdminsDialog
              open={toggleCreate}
              variant="create"
              values={holiday}
              onClose={onCloseCreate}
            />
          </Suspense>
        </>
      )}

      {toggleView && (
        <>
          <Suspense fallback={null}>
            <AdminsDialog
              open={toggleView}
              variant="view"
              values={holiday}
              onClose={onCloseView}
            />
          </Suspense>
        </>
      )}

      {toggleEdit && (
        <>
          <Suspense fallback={null}>
            <AdminsDialog
              open={toggleEdit}
              values={holiday}
              variant="edit"
              onClose={onCloseEdit}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default Admins
