import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useCallback
} from 'react'

// utils
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth'
import { isValidToken } from '../utils/jwt'

import { HTTP_STATUS } from 'src/constants/http.constants'
import Api from 'src/services/Api'
import LocalStorage from 'src/services/LocalStorage'

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.Login]: {
    user: AuthUser
  }
  [Types.Logout]: undefined
}

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>]

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      }

    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: action.payload.user?.login,
        user: action.payload.user
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }

    default:
      return state
  }
}

const AuthContext = createContext<JWTContextType | null>(null)

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)

  const handleUnauthorizedResponse = useCallback(
    async (error: AxiosError): Promise<unknown> => {
      const status = error.response?.status
      const accessToken = LocalStorage.getItem('accessToken')
      const refreshToken = LocalStorage.getItem('refreshToken')

      const decodeUser = jwtDecode(accessToken || '') as AuthUser

      if (
        status === HTTP_STATUS.UNAUTHORIZED &&
        !!refreshToken &&
        !!accessToken &&
        isValidToken(refreshToken)
      ) {
        await refresh(refreshToken)
        return
      }

      if (status === HTTP_STATUS.UNAUTHORIZED) {
        await logout(decodeUser?.sub || decodeUser?.id)
        return
      }

      if (status === HTTP_STATUS.FORBIDDEN) {
        await logout(decodeUser?.sub || decodeUser?.id)
        return
      }

      return Promise.reject(error)
    },
    []
  )

  useEffect(() => {
    Api.appendResponseErrorInterceptor(handleUnauthorizedResponse)

    const initialize = async () => {
      try {
        const accessToken = LocalStorage.getItem('accessToken')
        const refreshToken = LocalStorage.getItem('refreshToken')

        if (accessToken && isValidToken(accessToken)) {
          Api.setAuthToken(accessToken)

          const decodeUser = jwtDecode(accessToken || '') as AuthUser

          const user = await Api.getUsersById(decodeUser?.sub || decodeUser?.id)

          return dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: user?.data?.login,
              user: user?.data
            }
          })
        }

        return dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      } catch (err) {
        console.error(err)
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (userName: string, password: string) => {
    const response = await Api.login(userName, password)

    const { access_token, refresh_token } = response.data

    LocalStorage.setItem('accessToken', access_token)
    LocalStorage.setItem('refreshToken', refresh_token)
    Api.setAuthToken(access_token)

    const decodeUser = jwtDecode(access_token || '') as AuthUser

    const user = await Api.getUsersById(decodeUser?.sub || decodeUser?.id)

    dispatch({
      type: Types.Login,
      payload: {
        user: user.data
      }
    })
  }

  const logout = async (userId: string) => {
    Api.setAuthToken(null)
    Api.logout(userId)
    LocalStorage.removeItem('accessToken')
    LocalStorage.removeItem('refreshToken')

    dispatch({ type: Types.Logout })
  }

  const refresh = async (refreshToken: string) => {
    try {
      const accessToken = LocalStorage.getItem('accessToken')
      Api.setAuthToken(accessToken)

      if (accessToken && refreshToken && isValidToken(refreshToken)) {
        const decodeUser = jwtDecode(refreshToken || '') as AuthUser
        const { data: tokens } = await Api.refreshToken({
          refreshToken
        })

        Api.setAuthToken(tokens.access_token)
        LocalStorage.setItem('accessToken', tokens.access_token)
        LocalStorage.setItem('refreshToken', tokens.refresh_token)

        const user = await Api.getUsersById(decodeUser?.sub || decodeUser?.id)

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: user?.data?.login,
            user: user?.data
          }
        })
      } else {
        LocalStorage.removeItem('accessToken')
        LocalStorage.removeItem('refreshToken')

        Api.setAuthToken(null)
        dispatch({ type: Types.Logout })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        refresh
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
