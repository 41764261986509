import { TableCell, Stack, IconButton } from '@mui/material'

import { Iconify } from '../base'
import TriggerDialog from '../dialogs/TriggerDialog'

import useToggle from 'src/hooks/useToggle'

interface ActionTableMenuProps {
  onEditRow?: (id: string) => void
  onDeleteRow?: (id: string) => void
  onViewRow?: (id: string) => void
  onCustomEditRow?: (id: string) => void
  onCoppy?: () => void
  id: string
}

const ActionTableMenu: React.FC<ActionTableMenuProps> = ({
  id,
  onDeleteRow,
  onEditRow,
  onCustomEditRow,
  onCoppy,
  onViewRow
}) => {
  const { toggle, onClose, onOpen } = useToggle(false)

  return (
    <TableCell align="right">
      <Stack direction="row" spacing={1} justifyContent="end">
        {onViewRow && (
          <IconButton
            onClick={() => {
              onViewRow(id)
            }}
          >
            <Iconify icon={'eva:eye-outline'} color="#54D62C" />
          </IconButton>
        )}

        {onEditRow && (
          <IconButton
            onClick={() => {
              onEditRow(id)
            }}
          >
            <Iconify icon={'eva:edit-outline'} color="#1890FF" />
          </IconButton>
        )}

        {onCustomEditRow && (
          <IconButton
            onClick={() => {
              onCustomEditRow(id)
            }}
          >
            <Iconify icon={'eva:lock-outline'} color="#1890FF" />
          </IconButton>
        )}

        {onCoppy && (
          <IconButton onClick={onCoppy}>
            <Iconify icon={'akar-icons:copy'} />
          </IconButton>
        )}

        {onDeleteRow && (
          <TriggerDialog
            title="Are you sure you want to delete?"
            onSubmit={() => {
              onDeleteRow(id)
              onClose()
            }}
            onClose={onClose}
            open={toggle}
          >
            <IconButton onClick={onOpen}>
              <Iconify icon={'eva:trash-2-outline'} color="#FF6C40" />
            </IconButton>
          </TriggerDialog>
        )}
      </Stack>
    </TableCell>
  )
}

export default ActionTableMenu
