import { isEmpty } from 'lodash'

// @mui
import {
  Card,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  CardProps,
  CardHeader,
  TableContainer
} from '@mui/material'

import { IpReputation, ReputationCategory } from 'src/@types/domain-statistics'
import { Label, Scrollbar } from 'src/components/base'
import { TableHeadCustom, TableNoData } from 'src/components/table'

interface Props extends CardProps {
  title?: string
  subheader?: string
  tableData: IpReputation[]
  tableLabels: TableHeadOptions[]
}

export default function IpReputationTable({
  title,
  subheader,
  tableData,
  tableLabels,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 420 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {isEmpty(tableData) ? (
                <TableNoData isNotFound={isEmpty(tableData)} />
              ) : (
                tableData?.map((row, index) => {
                  const id = index + 1
                  return (
                    <IpReputationRow key={index} row={{ ...row, id: id }} />
                  )
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />
    </Card>
  )
}

// ----------------------------------------------------------------------

type IpReputationRowProps = {
  row: IpReputation & { id: number }
}

function IpReputationRow({ row }: IpReputationRowProps) {
  return (
    <>
      <TableRow>
        <TableCell>{row.id}</TableCell>

        <TableCell>{row.ipAddress || 'Ip not found'}</TableCell>

        <TableCell>
          <Label
            variant="filled"
            color={
              (row.reputation === ReputationCategory.MEDIUM && 'warning') ||
              (row.reputation === ReputationCategory.BAD && 'error') ||
              (row.reputation === ReputationCategory.LOW && 'error') ||
              (row.reputation === ReputationCategory.UNKNOWN && 'error') ||
              'success'
            }
          >
            {row.reputation}
          </Label>
        </TableCell>
      </TableRow>
    </>
  )
}
