import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'

export const credentialConfig = {
  getCredential: {
    key: 'credential/all',
    request: async () => {
      const resp = await Api.getCredantialManagment()

      return resp.data
    }
  },
  getCredentialById: {
    key: 'credential/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, id] = queryKey as [string, string]
      const resp = await Api.getCredantialManagmentById(id)

      return resp.data
    }
  },
  getCredentialTypes: {
    key: 'credentia;/types',
    request: async () => {
      const resp = await Api.getCredantialManagmentTypes()

      return resp.data
    }
  }
}

export const useGetCredentialQuery = () => {
  const { getCredential: config } = credentialConfig

  const state = useQuery<CredentialManagement[]>([config.key], config.request)

  return state
}

export const useGetCredentialTypesQuery = () => {
  const { getCredentialTypes: config } = credentialConfig

  const state = useQuery<{ credentialTypes: string[] }>(
    [config.key],
    config.request
  )

  return state
}

interface UseGetCredentialByIdProps {
  id: string
  options?: UseQueryOptions<CredentialManagement>
}

export const useGetCredentialByIdQuery = (
  props?: UseGetCredentialByIdProps
) => {
  const { options, id } = props || {}
  const { getCredentialById: config } = credentialConfig

  const state = useQuery<CredentialManagement>(
    [config.key, id],
    config.request,
    {
      ...options
    }
  )

  return state
}
