import { IntlService } from 'src/services/IntlService'

export default class DialogViewService {
  static projectsView(values?: Projects) {
    return [
      {
        title: 'Id',
        value: values?.id
      },
      {
        title: 'Name',
        value: values?.name
      },
      {
        title: 'Is Active',
        value: values?.active ? 'Yes' : 'No'
      },
      {
        title: 'Type',
        value: values?.type
      }
    ]
  }

  static holidaysView(values?: Holidays) {
    return [
      {
        title: 'Name',
        value: values?.name
      },
      {
        title: 'Date',
        value: IntlService.formatDate(values?.date || '')
      }
    ]
  }

  static timeLogsView(values?: TimeEntries) {
    return [
      {
        title: 'Id',
        value: values?.id
      },
      {
        title: 'User',
        value: values?.user?.name
      },
      {
        title: 'Project',
        value: values?.project?.name
      },
      {
        title: 'Date',
        value: IntlService.formatDate(values?.date || '')
      },
      {
        title: 'Time',
        value: values?.time
      },
      {
        title: 'Details',
        value: values?.description
      }
    ]
  }

  static absencesView(values?: AbsenceReports) {
    return [
      {
        title: 'Id',
        value: values?.id
      },
      {
        title: 'Name',
        value: values?.user?.name
      },
      {
        title: 'Date',
        value: IntlService.formatDate(String(values?.date) || '')
      },
      {
        title: 'Reason',
        value: values?.reason
      },
      {
        title: 'Comment',
        value: values?.comment
      }
    ]
  }
}
