import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

const TimeLogsDialog = React.lazy(
  () => import('src/views/dashboard/TimeLogs/components/TimeLogsDialog')
)

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { TIME_LOGS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import {
  useGetTimeEntries,
  useGetTimeEntriesById
} from 'src/services/Query/time-entries/time-entries.query'
import { TimeLogsFilter, TimeLogsTableRow } from 'src/views/dashboard/TimeLogs'
import TimeLogsProjectDetails from 'src/views/dashboard/TimeLogs/components/TimeLogsProjectDetails'
import TimeLogsUserDetails from 'src/views/dashboard/TimeLogs/components/TimeLogsUserDetails'

const TimeLogs: React.FC = () => {
  const { themeStretch } = useSettings()
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onSort,
    selected,
    onChangePage
  } = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1
  })

  const { startCurrentWeek, endCurrentWeek } = DatePickerService
  const startDate = IntlService.formatDate(startCurrentWeek.toString())
  const endDate = IntlService.formatDate(endCurrentWeek.toString())

  const {
    activeId,
    params,
    toggleEdit,
    toggleView,
    onCloseEdit,
    onCloseView,
    handleViewRow,
    handleFilterValueChange
  } = useFiltersTable<TimeEntriesQueryParams, TimeValuesNormalired>({
    onChangePage,
    defaultValueParams: {
      page: 1,
      userIds: [],
      projectIds: [],
      workType: '',
      startDate,
      details: '',
      endDate
    }
  })

  const { data, isFetched, isFetching } = useGetTimeEntries({
    params: {
      ...params,
      page,
      limit: rowsPerPage,
      order,
      sort: orderBy
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: holiday } = useGetTimeEntriesById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const isNotFound = isFetched && !data?.timeEntries?.length

  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Time Logs">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4">Time Logs</Typography>

        <TimeLogsFilter handleFilterValueChange={handleFilterValueChange} />

        {!!data?.meta?.report?.length && !!data?.meta?.report?.[0].userName && (
          <TimeLogsUserDetails report={data?.meta?.report} />
        )}

        {!!data?.meta?.report?.length &&
          !!data?.meta?.report?.[0].projectName && (
            <TimeLogsProjectDetails report={data?.meta?.report} />
          )}

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.timeEntries?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TIME_LOGS_TABLE_HEAD}
                  rowCount={data?.timeEntries?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  ignoreHeadSort={['details']}
                />

                <TableBody>
                  {data?.timeEntries?.map((row) => (
                    <TimeLogsTableRow
                      key={row.id}
                      row={row}
                      onViewRow={handleViewRow}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>

      {toggleView && (
        <>
          <Suspense fallback={null}>
            <TimeLogsDialog
              open={toggleView}
              variant="view"
              values={holiday}
              onClose={onCloseView}
            />
          </Suspense>
        </>
      )}

      {toggleEdit && (
        <>
          <Suspense fallback={null}>
            <TimeLogsDialog
              open={toggleEdit}
              values={holiday}
              variant="edit"
              onClose={onCloseEdit}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default TimeLogs
