import { useMutation, useQueryClient } from 'react-query'

import { absenceReportsQueryConfig } from './absence-reports.query'

import Api from 'src/services/Api'

export const absenceReportsMutationConfig = {
  createAbsenceReports: {
    key: 'absence-reports/create',
    request: Api.createAbsenceReports
  },
  updateAbsenceReports: {
    key: 'absence-reports/update',
    request: Api.updateAbsenceReports
  },
  deleteAbsenceReports: {
    key: 'absence-reports/delete',
    request: Api.deleteAbsenceReports
  }
}
export const useCreateAbsenceReportsMutation = () => {
  const queryClient = useQueryClient()
  const { createAbsenceReports: config } = absenceReportsMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        absenceReportsQueryConfig.getAbsenceReports.key
      )
    }
  })
  return state
}

export const useEditAbsenceReportsMutation = () => {
  const queryClient = useQueryClient()
  const { updateAbsenceReports: config } = absenceReportsMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        absenceReportsQueryConfig.getAbsenceReports.key
      )
      queryClient.invalidateQueries(
        absenceReportsQueryConfig.getAbsenceReportsById.key
      )
    }
  })

  return state
}

export const useDeleteAbsenceReportsMutation = () => {
  const queryClient = useQueryClient()

  const { deleteAbsenceReports: config } = absenceReportsMutationConfig

  const state = useMutation<AbsenceReports, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          absenceReportsQueryConfig.getAbsenceReports.key
        )
      }
    }
  )
  return state
}
