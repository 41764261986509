import React, { Suspense, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button, InputAdornment, Stack } from '@mui/material'

import { Iconify } from 'src/components/base'
import {
  FormProvider,
  MUITextField,
  DatePickerField
} from 'src/components/fields'
import useToggle from 'src/hooks/useToggle'
import { DatePickerService } from 'src/services/DatePickerService'

const HolidayDialog = React.lazy(
  () => import('src/views/settings/Holidays/components/HolidayDialog')
)

interface HolidaysFilterProps {
  handleFilterValueChange: (value: HolidaysFilterFV) => void
}

const HolidaysFilter: React.FC<HolidaysFilterProps> = ({
  handleFilterValueChange
}) => {
  const { toggle, onClose, onOpen } = useToggle(false)

  const { startCurrentYear } = DatePickerService

  const methods = useForm<HolidaysFilterFV>({
    defaultValues: {
      term: '',
      date: startCurrentYear
    }
  })

  const values = methods.watch()

  useEffect(() => {
    handleFilterValueChange(values)
  }, [values.term, values.date])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <Box width={240}>
              <MUITextField
                size="small"
                label="Please enter holiday"
                name="term"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="fa-search" />
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <DatePickerField
              name="date"
              views={['year']}
              mask="____"
              inputFormat="yyyy"
            />

            <Button variant="outlined" onClick={() => methods.reset()}>
              Clear filter
            </Button>
          </Stack>

          <Button variant="contained" onClick={onOpen}>
            Add new holiday
          </Button>
        </Stack>
      </FormProvider>

      {toggle && (
        <>
          <Suspense fallback={null}>
            <HolidayDialog open={toggle} variant="create" onClose={onClose} />
          </Suspense>
        </>
      )}
    </>
  )
}

export default HolidaysFilter
