import './utils/highlight'

import 'simplebar/src/simplebar.css'

import 'react-image-lightbox/style.css'

import 'mapbox-gl/dist/mapbox-gl.css'

import 'react-quill/dist/quill.snow.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import locale from 'date-fns/locale/en-US'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import App from './App'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'
import { AuthProvider } from './contexts/JWTContext'
import { SettingsProvider } from './contexts/SettingsContext'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
const queryClient = new QueryClient()

if (locale && locale.options) {
  locale.options.weekStartsOn = 1
}

ReactDOM.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </AuthProvider>,
  document.getElementById('root')
)

serviceWorkerRegistration.unregister()

reportWebVitals()
