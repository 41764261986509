import * as React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface TriggerDialogProps {
  open: boolean
  title: string
  description?: string
  onClose: () => void
  onSubmit: () => void
}

const TriggerDialog: React.FC<TriggerDialogProps> = ({
  open,
  title,
  description,
  onClose,
  onSubmit,
  children
}) => (
  <>
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>

    {children}
  </>
)

export default TriggerDialog
