import { UseFormReturn } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import {
  DialogContent,
  Box,
  Divider,
  DialogActions,
  Button,
  Stack,
  FormControlLabel
} from '@mui/material'

import {
  FormProvider,
  CheckboxField,
  SelectField,
  MUITextField
} from 'src/components/fields'

interface ProjectsFormDialogProps extends FormDialogProps {
  methods: UseFormReturn<ProjectsFV>
}

const ProjectsFormDialog: React.FC<ProjectsFormDialogProps> = ({
  methods,
  isView,
  onClose,
  onSubmit
}) => {
  const {
    formState: { isSubmitting }
  } = methods

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }
            }}
          >
            <MUITextField name="name" label="Name *" disabled={isView} />
            <MUITextField
              name="channelName"
              label="Chanel name *"
              disabled={isView}
            />

            <SelectField
              label="Type *"
              name="type"
              width="100%"
              options={[
                { label: 'Client', value: 'CLIENT' },
                { label: 'Internal', value: 'INTERNAL' }
              ]}
            />

            <FormControlLabel
              sx={{
                alignItems: 'start',
                marginLeft: '5px'
              }}
              value="Status"
              label="Status"
              labelPlacement="top"
              control={
                <CheckboxField
                  label="Is Active"
                  name="active"
                  size="medium"
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '1rem'
                    }
                  }}
                />
              }
            />
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          size="medium"
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  )
}

export default ProjectsFormDialog
