import { Suspense, lazy, LazyExoticComponent } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'

// guards

import { PATH_AFTER_LOGIN } from '../config'
import GuestGuard from '../guards/GuestGuard'
import useAuth from '../hooks/useAuth'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
import Maintenance from '../pages/Maintenance'
import NotFound from '../pages/Page404'
import Page500 from '../pages/Page500'

import { LoadingScreen } from 'src/components/base'
import AuthGuard from 'src/guards/AuthGuard'
import AbsenceReports from 'src/pages/dashboard/AbsenceReports'
import Dashboard from 'src/pages/dashboard/Dashboard'
import DomainStatistics from 'src/pages/dashboard/DomainStatistics'
import CreatePlan from 'src/pages/dashboard/plan/CreatePlan'
import EditPlan from 'src/pages/dashboard/plan/EditPlan'
import Plans from 'src/pages/dashboard/plan/Plans'
import TimeLogs from 'src/pages/dashboard/TimeLogs'
import TimeWork from 'src/pages/dashboard/TimeWork'
import WeeklyReports from 'src/pages/dashboard/WeeklyReports'
import Admins from 'src/pages/settings/Admins'
import Credentials from 'src/pages/settings/Credentials'
import Holidays from 'src/pages/settings/Holidays'
import Projects from 'src/pages/settings/Projects'
import Users from 'src/pages/settings/Users'
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) =>
  (props: ObjectContain) => {
    const { pathname } = useLocation()

    const { isAuthenticated } = useAuth()

    const isDashboard = pathname.includes('/dashboard') && isAuthenticated

    return (
      <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
        <Component {...props} />
      </Suspense>
    )
  }

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: 'time-logs', element: <TimeLogs /> },
        { path: 'weekly-report', element: <WeeklyReports /> },
        { path: 'absence-reports', element: <AbsenceReports /> },
        { path: 'time-to-work', element: <TimeWork /> },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'holidays', element: <Holidays /> },
        { path: 'credentials', element: <Credentials /> },
        { path: 'users', element: <Users /> },
        { path: 'projects', element: <Projects /> },
        { path: 'admins', element: <Admins /> },
        {
          path: 'domain-statistics',
          element: <DomainStatistics />
        },

        {
          path: 'plans',
          children: [
            { element: <Plans />, index: true },
            { path: 'create', element: <CreatePlan /> },
            { path: ':id/edit', element: <EditPlan /> }
          ]
        }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
)
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')))
