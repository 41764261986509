import { useMutation, useQueryClient } from 'react-query'

import { holidaysConfig } from './holidays.query'

import Api from 'src/services/Api'

const holidaysMutationConfig = {
  createHoliday: {
    key: 'holiday/create',
    request: Api.createHoliday
  },
  deleteHoliday: {
    key: 'holiday/delete',
    request: Api.deleteHoliday
  },
  editHoliday: {
    key: 'holiday/edit',
    request: Api.updateHolidays
  }
}

export const useCreateHolidayMutation = () => {
  const queryClient = useQueryClient()

  const { createHoliday: config } = holidaysMutationConfig

  const state = useMutation<HolidaysFV, string, HolidaysFV>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(holidaysConfig.getHolidays.key)
      }
    }
  )
  return state
}
export const useDeleteHolidayMutation = () => {
  const queryClient = useQueryClient()

  const { deleteHoliday: config } = holidaysMutationConfig

  const state = useMutation<Holidays, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(holidaysConfig.getHolidays.key)
      }
    }
  )
  return state
}

export const useEditHolidayMutation = () => {
  const queryClient = useQueryClient()

  const { editHoliday: config } = holidaysMutationConfig

  const state = useMutation<
    HolidaysFV,
    string,
    { id: string; holidays: HolidaysFV }
  >(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(holidaysConfig.getHolidays.key)
      queryClient.invalidateQueries(holidaysConfig.getHolidayById.key)
    }
  })
  return state
}
