import React from 'react'

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fade,
  IconButton
} from '@mui/material'

import WeeklyReportUserTable from './WeeklyReportUserTable'

import { Iconify } from 'src/components/base'
import { WEEKLY_REPORTS_SUB_TABLE_HEAD } from 'src/constants/table.constants'

interface WeeklyReportsSubTableProps {
  timeEntries?: EntriesInfo[]
  open: boolean
}

const WeeklyReportsSubTable: React.FC<WeeklyReportsSubTableProps> = ({
  timeEntries,
  open
}) => {
  const [openEntries, setOpenEntries] = React.useState<{
    [key: string]: boolean
  }>({})

  return (
    <>
      {open && (
        <Fade in={open}>
          <Box ml="49.5px">
            {!!timeEntries?.length && (
              <Table
                aria-label="purchases"
                sx={{
                  borderLeft: '0.5px',
                  ml: '0.5px'
                }}
              >
                <TableHead>
                  <TableRow>
                    {WEEKLY_REPORTS_SUB_TABLE_HEAD.map((head) => (
                      <TableCell
                        key={head.id}
                        align={head.align}
                        width={head.width}
                      >
                        {head.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {timeEntries?.map((entry, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          '& > *': { borderBottom: 'unset' },
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpenEntries(
                            (prev: { [key: string]: boolean }) => ({
                              ...prev,
                              [entry?.name]: !prev[entry.name]
                            })
                          )
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            p: 0,

                            '&.MuiTableCell-root:first-of-type': {
                              paddingLeft: 0
                            }
                          }}
                        >
                          <IconButton aria-label="expand row" size="small">
                            {openEntries[entry.name] ? (
                              <Iconify icon="akar-icons:arrow-up" />
                            ) : (
                              <Iconify icon="akar-icons:arrow-down" />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell>{entry?.name}</TableCell>
                        <TableCell align="right">{entry?.total}</TableCell>
                      </TableRow>

                      {openEntries[entry.name] && !!entry?.entries.length && (
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={6}>
                            <WeeklyReportUserTable
                              openEntries={openEntries[entry.name]}
                              entriesInfo={entry?.entries}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Fade>
      )}
    </>
  )
}

export default WeeklyReportsSubTable
