import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// @mui
import { Box, Stack, Drawer } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

// hooks

import { NavSectionVertical } from '../../../components/nav-section'
import { NAVBAR } from '../../../config'
import useCollapseDrawer from '../../../hooks/useCollapseDrawer'
import useResponsive from '../../../hooks/useResponsive'
// utils
import cssStyles from '../../../utils/cssStyles'
// config
// components

//
import CollapseButton from './CollapseButton'
import navConfig from './NavConfig'

import { Logo, Scrollbar } from 'src/components/base'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter
    })
  }
}))

type Props = {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
}

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar
}: Props) {
  const theme = useTheme()
  const [haveMouseEnter, setHaveMouseEnter] = useState(false)

  const { pathname } = useLocation()

  const isDesktop = useResponsive('up', 'lg')

  const { isCollapse, collapseClick, onToggleCollapse } = useCollapseDrawer()

  const handleMouseEnter = () => {
    setHaveMouseEnter(true)
  }
  const handleMouseLeave = () => {
    setHaveMouseEnter(false)
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 1,
          pb: 0,
          pr: 2,
          pl: 2,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' })
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          height="45px"
        >
          {isCollapse ? (
            <>
              {!haveMouseEnter && <Logo />}

              {isDesktop && haveMouseEnter && (
                <CollapseButton
                  onToggleCollapse={onToggleCollapse}
                  collapseClick={collapseClick}
                />
              )}
            </>
          ) : (
            <>
              <Logo />

              {isDesktop && (
                <CollapseButton
                  onToggleCollapse={onToggleCollapse}
                  collapseClick={collapseClick}
                />
              )}
            </>
          )}
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
