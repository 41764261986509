import { Stack, DialogTitle, Typography, IconButton } from '@mui/material'

import { Iconify } from '../base'

interface VariantDialogTitleProps {
  variant: 'edit' | 'create' | 'view' | 'customEdit'
  createTitle?: string
  editTitle?: string
  viewTitle?: string
  customEditTitle?: string
  onClose: () => void
}

const VariantDialogTitle: React.FC<VariantDialogTitleProps> = ({
  variant,
  createTitle = 'Create',
  editTitle = 'Edit',
  customEditTitle = 'Custom edit',
  viewTitle = 'View',
  onClose
}) => (
  <DialogTitle>
    <Stack direction="row" spacing={2} alignItems="center">
      {
        {
          create: (
            <>
              <Iconify
                color="#2065D1"
                width={30}
                height={30}
                icon="carbon:add-filled"
              />

              <Typography fontSize={20} fontWeight={700}>
                {createTitle}
              </Typography>
            </>
          ),

          edit: (
            <>
              <Iconify
                color="#2065D1"
                width={30}
                height={30}
                icon="clarity:note-edit-solid"
              />

              <Typography fontSize={20} fontWeight={700}>
                {editTitle}
              </Typography>
            </>
          ),

          customEdit: (
            <>
              <Iconify
                color="#2065D1"
                width={30}
                height={30}
                icon="clarity:note-edit-solid"
              />

              <Typography fontSize={20} fontWeight={700}>
                {customEditTitle}
              </Typography>
            </>
          ),

          view: (
            <>
              <Iconify
                color="#2065D1"
                width={30}
                height={30}
                icon="carbon:view-filled"
              />

              <Typography fontSize={20} fontWeight={700}>
                {viewTitle}
              </Typography>
            </>
          )
        }[variant]
      }
    </Stack>

    <IconButton
      sx={{
        position: 'absolute',
        zIndex: 101,
        padding: 0,
        top: 25,
        right: 15,
        width: 25,
        height: 25,
        color: 'text.headline'
      }}
      onClick={onClose}
    >
      <Iconify color="#2065D1" width={30} height={30} icon="ep:close-bold" />
    </IconButton>
  </DialogTitle>
)

export default VariantDialogTitle
