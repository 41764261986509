import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'

import { Card } from '@mui/material'

import { getDiagramOptions } from './ProjectsDiagramOptions'
import { ChartWrapperStyle } from './ProjectsDiagramStyles'

import { EmptyContent } from 'src/components/base'

interface ProjectsDiagramProps {
  data: number[]
  labels: string[]
  onClick?: (index: number) => void
}
const ProjectsDiagram: FC<ProjectsDiagramProps> = ({
  data,
  onClick,
  labels
}) => {
  const options = getDiagramOptions(labels, onClick)

  return (
    <Card sx={{ height: '100%' }}>
      <ChartWrapperStyle dir="ltr">
        {!data?.length ? (
          <EmptyContent title="No Data" />
        ) : (
          <ReactApexChart
            type="pie"
            series={data}
            options={options}
            height={320}
          />
        )}
      </ChartWrapperStyle>
    </Card>
  )
}

export default ProjectsDiagram
