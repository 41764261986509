import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Stack } from '@mui/material'

import { FormProvider, StaticDateRangePickerField } from 'src/components/fields'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'

interface DashboardFilterProps {
  handleFilterValueChange: (value: DashboardFiltersDTO) => void
  defaultValues: DashboardFiltersDTO
}

const DashboardFilter: React.FC<DashboardFilterProps> = ({
  handleFilterValueChange,
  defaultValues
}) => {
  const { startPreviousMonth, date } = DatePickerService

  const methods = useForm<DashboardFilters>({
    defaultValues: {
      dateRange: [defaultValues.startDate, defaultValues.endDate]
    }
  })

  const { dateRange } = methods.watch()

  useEffect(() => {
    if (dateRange?.[0] && dateRange?.[1]) {
      const normalizeValues = {
        startDate: IntlService.formatDate(dateRange?.[0] || ''),
        endDate: IntlService.formatDate(dateRange?.[1] || '')
      }

      handleFilterValueChange(normalizeValues)
    }
  }, [dateRange])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          spacing={2}
          my={1}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <StaticDateRangePickerField name="dateRange" />

            <Button
              sx={{ height: '40px' }}
              variant="contained"
              color="primary"
              onClick={() => methods.reset()}
            >
              Clear filter
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  )
}

export default DashboardFilter
