import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const timeToWorkConfig = {
  getTimesToWork: {
    key: 'time-to-work/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, TimeEntriesQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getTimesToWork(normalizesParams)

      return resp.data
    }
  },
  getTimesToWorkEachdayLogs: {
    key: 'time-to-work/each-days',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, TimeEntriesQueryParams]
      const { id, ...rest } = params
      const normalizesParams = normalizesQueryParams(rest)
      const resp = await Api.getTimesToWorkEachDay(normalizesParams, id)

      return resp.data
    }
  }
}

interface UseGetTimeToWorks {
  params?: TimeToWorkQueryParams
  options?: UseQueryOptions<TimeToWork[]>
}

export const useGetTimeToWorks = (props?: UseGetTimeToWorks) => {
  const { options, params } = props || {}
  const { getTimesToWork: config } = timeToWorkConfig

  const state = useQuery<TimeToWork[]>([config.key, params], config.request, {
    ...options
  })
  return state
}

interface UseGetTimeToWorksEachDay {
  params?: TimeToWorkFiltersDto
  options?: UseQueryOptions<TimeToWorkEachDayLogsResponse[]>
}

export const useGetTimeToWorksEachDayLogs = (
  props?: UseGetTimeToWorksEachDay
) => {
  const { options, params } = props || {}
  const { getTimesToWorkEachdayLogs: config } = timeToWorkConfig

  const state = useQuery<TimeToWorkEachDayLogsResponse[]>(
    [config.key, params],
    config.request,
    options
  )
  return state
}
