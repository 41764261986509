import Axios from './Axios'

import {
  DomainResponse,
  GooglePostmasterResponse
} from 'src/@types/domain-statistics'

class ApiGroupClass extends Axios {
  // Get user info
  getUser = () => this.get(`/account/user`)

  // Login user
  login = (userName: string, password: string) =>
    this.post('/api/v1/auth/login', { data: { userName, password } })

  // logout user
  logout = (userId: string) =>
    this.post('/api/v1/auth/logout', { data: { userId } })

  // refresh user
  refreshToken = (payload: { refreshToken: string }) =>
    this.post('/api/v1/auth/refresh', { data: payload })

  // Get holidays
  getHolidays = (
    params: HolidaysQueryParams
  ): Promise<{ data: HolidaysResponse }> =>
    this.get('/api/v1/holidays', { params })

  // Create holiday
  createHoliday = (holidays: HolidaysFV) =>
    this.post('/api/v1/holidays', { data: holidays })

  // Update holidays
  updateHolidays = ({ id, holidays }: { id: string; holidays: HolidaysFV }) =>
    this.patch(`/api/v1/holidays/${id}`, { data: holidays })

  // Delete holiday
  deleteHoliday = (id: string): Promise<Holidays> =>
    this.del(`/api/v1/holidays/${id}`)

  // Get by id holidays
  getHolidaysById = (id: string): Promise<{ data: Holidays }> =>
    this.get(`/api/v1/holidays/${id}`)

  // Update project
  updateProjects = ({
    id,
    projects
  }: {
    id: string
    projects: ProjectsFV
  }): Promise<Projects> =>
    this.patch(`/api/v1/projects/${id}`, { data: projects })

  // Create project
  createProjects = (projects: ProjectsFV): Promise<Projects> =>
    this.post('/api/v1/projects', { data: projects })

  // Delete project
  deleteProjects = (id: string): Promise<Projects> =>
    this.del(`/api/v1/projects/${id}`)

  // Get projects
  getProjects = (
    params: ProjectsQueryParams
  ): Promise<{ data: ProjectsResponse }> =>
    this.get('/api/v1/projects', { params })

  // Get all projects names
  getProjectsNames = (
    params: ProjectsNamesQueryParams
  ): Promise<{ data: FilterNameProjects }> =>
    this.get('/api/v1/projects/names', { params })

  // Get by id project
  getProjectsById = (id: string): Promise<{ data: ProjectsResponse }> =>
    this.get(`/api/v1/projects/${id}`)

  // Get users
  getUsers = (params: UsersQueryParams): Promise<{ data: UsersResponse }> =>
    this.get('/api/v1/users', { params })
  // Update user
  updateUsers = ({
    id,
    users
  }: {
    id: string
    users: UsersFV
  }): Promise<Users> => this.patch(`/api/v1/users/${id}`, { data: users })

  // Delete user
  deleteUsers = (id: string): Promise<Users> => this.del(`/api/v1/users/${id}`)

  // Get users by id
  getUsersById = (id: string): Promise<{ data: Users }> =>
    this.get(`/api/v1/users/${id}`)

  // Get all users name
  getUsersNames = (
    params: UsersNamesQueryParams
  ): Promise<{ data: FilterNameUsers }> =>
    this.get(`/api/v1/users/names`, { params })

  // Get users positions
  getPositions = (): Promise<{ data: UserPositions }> =>
    this.get('/api/v1/users/positions')

  // create time logs
  createTimeLogs = (timeLogs: TimeEntriesFV): Promise<TimeEntries> =>
    this.post('/api/v1/time-entries', { data: timeLogs })

  // get time logs
  getTimeLogs = (
    params: TimeEntriesQueryParams
  ): Promise<{ data: TimeEntriesResponse }> =>
    this.get('/api/v1/time-entries', { params })

  // get time logs by id
  getTimeLogsById = (id: string): Promise<{ data: TimeEntriesResponse }> =>
    this.get(`/api/v1/time-entries/${id}`)

  // get absence reports
  getAbsenceReports = (
    params: AbsenceReportsQueryParams
  ): Promise<{ data: AbsenceReportsResponse }> =>
    this.get('/api/v1/absences', { params })

  // get absence reports by id
  getAbsenceReportsById = (id: string): Promise<{ data: AbsenceReports }> =>
    this.get(`/api/v1/absences/${id}`)

  // create absence reports
  createAbsenceReports = (
    absences: AbsenceReportsDto
  ): Promise<AbsenceReports> =>
    this.post('/api/v1/absences', { data: absences })

  // update absence reports
  updateAbsenceReports = ({
    id,
    absences
  }: {
    id: string
    absences: AbsenceReportsDto
  }): Promise<AbsenceReports> =>
    this.patch(`/api/v1/absences/${id}`, { data: absences })

  // delete absence reports
  deleteAbsenceReports = (id: string): Promise<AbsenceReports> =>
    this.del(`/api/v1/absences/${id}`)

  // Projects weekly report
  getWeeklyReport = (
    params: ProjectsWeeklyReportQueryParams
  ): Promise<{ data: WeeklyReportResponse }> =>
    this.get('/api/v1/time-reports', { params })

  // get time to works
  getTimesToWork = (
    params: TimeToWorkQueryParams
  ): Promise<TimeToWorkResponse> =>
    this.get('/api/v1/times-to-work', { params })

  getTimesToWorkEachDay = (
    params: TimeToWorkEachDayLogsResponse,
    id?: string
  ): Promise<{ data: TimeToWorkEachDayLogsResponse[] }> =>
    this.get(`/api/v1/times-to-work/each-day-user-logs/${id}`, { params })

  // get work types
  getWorkTypes = (): Promise<{ data: WorkTypesResponse }> =>
    this.get('/api/v1/time-entries/work-types')

  // get csv time entries
  getCsvTimeReports = (params: TimeEntriesQueryParams): Promise<string> =>
    this.get('/api/v1/time-reports/csv', { params })

  getCsvClientReports = (params: TimeEntriesQueryParams): Promise<string> =>
    this.get('/api/v1/time-reports/csv-client', { params })

  // get hours statistic for dashboard
  getHoursStatistic = (
    params: DashboardQueryParams
  ): Promise<{ data: HoursStatistic }> =>
    this.get('/api/v1/dashboard/hours-statistics', { params })

  // get period hours statistic for dashboard
  getPeriodHoursStatistic = (): Promise<PeriodStatisticResponse> =>
    this.get('/api/v1/dashboard/period-hours-statistic')

  // get projects statistic for dashboard
  getProjectsStatistic = (
    params: DashboardQueryParams
  ): Promise<{ data: ProjectsStatisticResponse }> =>
    this.get('/api/v1/dashboard/project-statistics', { params })

  getAbsencesComing = (): Promise<{ data: AbsencesComingResponse }> =>
    this.get('/api/v1/dashboard/absences-coming')

  createPlan = (plan: CreatePlanDto): Promise<void> =>
    this.post('/api/v1/plans', {
      data: plan
    })

  getPlans = (params: PlanQueryParams): Promise<{ data: PlanResponse }> =>
    this.get('/api/v1/plans', { params })

  getLatestPlan = (id: string): Promise<PlanEditResponse> =>
    this.get(`/api/v1/plans/latest-plan/${id}`)

  getPlanById = (id: string): Promise<PlanEditResponse> =>
    this.get(`/api/v1/plans/${id}`)

  deletePlan = (id: string): Promise<UpdatePlanDto> =>
    this.del(`/api/v1/plans/${id}`)

  updatePlan = ({ id, data }: UpdatePlanDto): Promise<UpdatePlanDto> =>
    this.put(`/api/v1/plans/${id}`, { data: data })

  createCredantialManagment = (
    data: CredentialManagementFV
  ): Promise<CredentialManagement> =>
    this.post('api/v1/credential-management', { data })

  getCredantialManagment = (): Promise<CredentialManagementsRespons> =>
    this.get('/api/v1/credential-management')

  getCredantialManagmentById = (
    id: string
  ): Promise<CredentialManagementRespons> =>
    this.get(`/api/v1/credential-management/${id}`)

  updateCredantialManagment = ({
    data,
    id
  }: {
    data: CredentialManagementFV
    id: string
  }): Promise<CredentialManagement> =>
    this.put(`/api/v1/credential-management/${id}`, { data: data })

  removeCredantialManagment = (
    id: string
  ): Promise<CredentialManagementRespons> =>
    this.del(`/api/v1/credential-management/${id}`)
  getCredantialManagmentTypes = (): Promise<{
    data: { credentialTypes: string[] }
  }> => this.get('/api/v1/credential-management/credential-types')

  getDomainStatistics = (domain: string): Promise<DomainResponse> =>
    this.get(`/api/v1/postmaster/domain/${domain}`)

  getDomainsList = (): Promise<GooglePostmasterResponse[]> =>
    this.get(`/api/v1/postmaster/domains-list`)
}

const Api = new ApiGroupClass({
  baseURL: process.env.REACT_APP_HOST_API_KEY,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export default Api
