// formate date intl service with options
export class IntlService {
  /**
   * Date formatter
   *
   * @param date Date value
   * @param options Intl.DateTimeFormatOptions
   */

  static formatDate(
    date: string,
    options?: Intl.DateTimeFormatOptions
  ): string {
    if (!date) return ''

    const formatter = new Date(String(date)).toLocaleString('en-CA', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      ...options
    })

    return formatter
  }
}
