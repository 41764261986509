import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import {
  DialogContent,
  Box,
  Divider,
  DialogActions,
  Button,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  MUITextField,
  DatePickerField
} from 'src/components/fields'
interface TimeLogsFormDialogDialogProps extends FormDialogProps {
  methods: UseFormReturn<TimeEntriesFV>
}

const TimeLogsFormDialogDialog: React.FC<TimeLogsFormDialogDialogProps> = ({
  methods,
  onClose,
  onSubmit
}) => {
  const {
    formState: { isSubmitting }
  } = methods

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }
            }}
          >
            <AutocompleteField
              label="User name"
              name="userName"
              required
              width="100%"
              options={[
                { label: 'John Doe', value: 'John Doe' },
                { label: 'Jane Doe 2', value: 'Jane Doe 2' }
              ]}
            />

            <AutocompleteField
              label="Projects name"
              name="projectsName"
              required
              width="100%"
              options={[
                { label: 'John Doe', value: 'John Doe' },
                { label: 'Jane Doe 2', value: 'Jane Doe 2' }
              ]}
            />

            <DatePickerField label="Date" name="date" width="100%" required />

            <Tooltip
              title={
                <Typography fontSize={14}>
                  If you want record 5 hours, you need write next: 05:00
                </Typography>
              }
            >
              <MUITextField required name="time" label="Time" />
            </Tooltip>

            <MUITextField required name="details" label="Details" />
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          size="medium"
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  )
}

export default TimeLogsFormDialogDialog
