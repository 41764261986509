// form
import { useFormContext, Controller } from 'react-hook-form'

// @mui
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControlLabelProps,
  CheckboxProps
} from '@mui/material'

interface CheckboxFieldProps extends Omit<FormControlLabelProps, 'control'> {
  name: string
}

export function CheckboxField({
  name,
  ...other
}: CheckboxFieldProps & CheckboxProps) {
  const { control } = useFormContext()

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox {...field} checked={field.value} {...other} />
          )}
        />
      }
      {...other}
    />
  )
}

interface MultiCheckboxFieldProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string
  options: string[]
}

export function MultiCheckboxField({
  name,
  options,
  ...rest
}: MultiCheckboxFieldProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value?.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option]

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value?.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...rest}
              />
            ))}
          </FormGroup>
        )
      }}
    />
  )
}
