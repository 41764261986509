// @mui
import { TableRow, TableCell } from '@mui/material'

import ActionTableMenu from 'src/components/table/ActionTableMenu'
import { IntlService } from 'src/services/IntlService'

interface HolidayTableRowProps extends TableRowProps {
  row: Holidays
}

const HolidayTableRow = ({
  row,
  selected,
  onEditRow,
  onDeleteRow
}: HolidayTableRowProps) => {
  const { name, date, id } = row

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left" width={800}>
        {name}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} width={600}>
        {IntlService.formatDate(date)}
      </TableCell>

      <ActionTableMenu
        id={id}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
      />
    </TableRow>
  )
}

export default HolidayTableRow
