// form
import { useFormContext, Controller } from 'react-hook-form'

// @mui
import { TextField, TextFieldProps } from '@mui/material'

interface IProps {
  name: string
}

export type MUITextFieldProps = IProps & TextFieldProps

export default function MUITextField({ name, ...other }: MUITextFieldProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  )
}
