import { FC } from 'react'

import { AutocompleteRenderGetTagProps, Chip, Typography } from '@mui/material'

interface AutocompleteTagsProps {
  value: Options[]
  getTagProps: AutocompleteRenderGetTagProps
}

const AutocompleteTags: FC<AutocompleteTagsProps> = ({
  value,
  getTagProps
}) => (
  <>
    <Chip {...getTagProps({ index: 0 })} size="small" label={value[0].label} />
    {value.length > 1 && <Typography>+{value.length - 1}</Typography>}
  </>
)

export default AutocompleteTags
