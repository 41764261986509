import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button, InputAdornment, Stack } from '@mui/material'

import ProjectsDialog from './ProjectsDialog'

import { Iconify } from 'src/components/base'
import { FormProvider, SelectField, MUITextField } from 'src/components/fields'
import { projectOptions, statusOptions } from 'src/constants/options.constants'
import useToggle from 'src/hooks/useToggle'

interface ProjectsFilterProps {
  handleFilterValueChange: (value: ProjectsFV) => void
}

const ProjectsFilter: React.FC<ProjectsFilterProps> = ({
  handleFilterValueChange
}) => {
  const { toggle, onOpen, onClose } = useToggle(false)

  const methods = useForm<ProjectsFV>({
    defaultValues: {
      term: '',
      active: '',
      type: ''
    }
  })

  const values = methods.watch()

  useEffect(() => {
    handleFilterValueChange(values)
  }, [values.term, values.active, values.type])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <Box width={240}>
              <MUITextField
                name="term"
                label="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="fa-search" />
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <SelectField name="active" label="Active" options={statusOptions} />

            <SelectField name="type" label="Type" options={projectOptions} />
            <Button
              variant="outlined"
              sx={{ height: '40px' }}
              onClick={() => methods.reset()}
            >
              Clear filter
            </Button>
          </Stack>

          <Button onClick={onOpen} sx={{ height: '40px' }} variant="contained">
            Add new project
          </Button>
        </Stack>
      </FormProvider>

      {toggle && (
        <ProjectsDialog
          title="Create project"
          open={toggle}
          variant="create"
          onClose={onClose}
        />
      )}
    </>
  )
}

export default ProjectsFilter
