import React from 'react'

import {
  Fade,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'

import { WEEKLY_REPORTS_USER_TABLE_HEAD } from 'src/constants/table.constants'

interface WeeklyReportsTableRowProps {
  entriesInfo: TimeEntries[]
  openEntries: boolean
}

const WeeklyReportUserTable: React.FC<WeeklyReportsTableRowProps> = ({
  entriesInfo,
  openEntries
}) => (
  <>
    {openEntries && !!entriesInfo?.length && (
      <Fade in={openEntries}>
        <Box ml="49.5px">
          <Table
            sx={{
              borderLeft: '0.5px'
            }}
          >
            <TableHead>
              <TableRow>
                {WEEKLY_REPORTS_USER_TABLE_HEAD.map((head) => (
                  <TableCell
                    key={head.id}
                    align={head.align}
                    width={head.width}
                  >
                    {head.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {entriesInfo?.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>{entry?.workType}</TableCell>
                  <TableCell>{entry?.description}</TableCell>
                  <TableCell align="right">{entry?.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Fade>
    )}
  </>
)

export default WeeklyReportUserTable
