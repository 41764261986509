export const statusOptions: Options[] = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
]

export const projectOptions: Options[] = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'INTERNAL',
    label: 'Internal'
  },
  {
    value: 'CLIENT',
    label: 'Client'
  }
]

export const userOptions: Options[] = [
  {
    value: 'ADMIN',
    label: 'ADMIN'
  },
  {
    value: 'USER',
    label: 'USER'
  }
]
