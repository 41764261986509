import { Card, Typography, CardProps } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'

import { ReputationCategory } from 'src/@types/domain-statistics'
import { Iconify } from 'src/components/base'
import { ColorSchema } from 'src/theme/palette'
import cssStyles from 'src/utils/cssStyles'

interface Props extends CardProps {
  title: string
  total: number | ReputationCategory | string
  icon: string
  color?: ColorSchema
}

export default function DomainWidgetSummary({
  title,
  total,
  icon,
  color = 'primary',
  sx,
  ...other
}: Props) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        ...sx
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        width={64}
        height={64}
        sx={{
          color: theme.palette[color].dark,
          ...cssStyles().bgGradient({
            direction: '135deg',
            startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
            endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`
          })
        }}
      />

      <Typography variant="h3">{total}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
        {title}
      </Typography>
    </Card>
  )
}
