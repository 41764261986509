import React from 'react'

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fade
} from '@mui/material'

import { TIME_WORK_SUBTABLE_HEAD } from 'src/constants/table.constants'
import { useGetTimeToWorksEachDayLogs } from 'src/services/Query/time-to-work/time-to-work.query'

type Props = {
  userId: string
  open: boolean
  params: TimeToWorkFiltersDto
}

const TimeWorkSubTableRow = ({ userId, open, params }: Props) => {
  const { data } = useGetTimeToWorksEachDayLogs({
    params: { ...params, id: userId },
    options: {
      enabled: open
    }
  })

  return (
    <>
      {open && (
        <Fade in={open}>
          <Box ml="49.5px">
            {!!data?.length && (
              <Table
                aria-label="purchases"
                sx={{
                  borderLeft: '0.5px',
                  ml: '0.5px'
                }}
              >
                <TableHead>
                  <TableRow>
                    {TIME_WORK_SUBTABLE_HEAD.map((head) => (
                      <TableCell
                        key={head.id}
                        align={head.align}
                        width={head.width}
                      >
                        {head.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map((item: TimeToWorkEachDayLogsResponse, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="left">
                        {item.day} {item.dayOff}
                      </TableCell>

                      <TableCell align="left">{item.hoursWorked}</TableCell>

                      <TableCell
                        align="left"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {item.estimatedHoursWorked}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {item.difference}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Fade>
      )}
    </>
  )
}

export default TimeWorkSubTableRow
