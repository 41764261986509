import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

const HolidayDialog = React.lazy(
  () => import('src/views/settings/Holidays/components/HolidayDialog')
)

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { HOLIDAY_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { useDeleteHolidayMutation } from 'src/services/Query/holidays/holidays.mutation'
import {
  useGetHolidayById,
  useGetHolidays
} from 'src/services/Query/holidays/holidays.query'
import { HolidaysFilter, HolidayTableRow } from 'src/views/settings/Holidays'

const Holidays: React.FC = () => {
  const { themeStretch } = useSettings()
  const { dense, page, order, orderBy, rowsPerPage, selected, onChangePage } =
    useTable({
      defaultRowsPerPage: 25,
      defaultCurrentPage: 1
    })

  const { mutateAsync } = useDeleteHolidayMutation()

  const { startCurrentYear } = DatePickerService

  const {
    activeId,
    params,
    toggleEdit,
    onCloseEdit,
    handleDeleteRow,
    handleEditRow,
    handleFilterValueChange
  } = useFiltersTable<HolidaysQueryParams, HolidaysFilterFV>({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      page: 1,
      endDate: '',
      startDate: '',
      term: '',
      date: startCurrentYear
    }
  })

  const { data, isFetched, isFetching } = useGetHolidays({
    params: { ...params, page, limit: rowsPerPage },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: holiday } = useGetHolidayById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const isNotFound = isFetched && !data?.holidays?.length

  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Holidays">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Holidays
        </Typography>

        <HolidaysFilter handleFilterValueChange={handleFilterValueChange} />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.holidays?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={HOLIDAY_TABLE_HEAD}
                  rowCount={data?.holidays?.length}
                  numSelected={selected.length}
                />

                <TableBody>
                  {data?.holidays?.map((row) => (
                    <HolidayTableRow
                      key={row.id}
                      row={row}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={handleEditRow}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>

      {toggleEdit && (
        <>
          <Suspense fallback={null}>
            <HolidayDialog
              open={toggleEdit}
              values={holiday}
              variant="edit"
              onClose={onCloseEdit}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default Holidays
