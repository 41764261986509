import { Card, Box, Skeleton } from '@mui/material'

export const HourlyCardSkeleton = () => (
  <Card sx={{ height: '100%' }}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="end"
      padding={3}
    >
      <Skeleton animation="wave" variant="text" width="100%" height="20px" />

      <Skeleton animation="wave" variant="text" width="100%" height="20px" />

      <Skeleton animation="wave" variant="text" width="100%" height="20px" />
    </Box>
  </Card>
)

export const HourlyDiagramSkeleton = () => (
  <Card sx={{ height: '100%' }}>
    <Box
      display="flex"
      justifyContent="space-around"
      padding={6}
      height="380px"
    >
      {new Array(6).fill({}).map((_item, idx) => (
        <Skeleton
          key={idx}
          animation="wave"
          variant="rectangular"
          width="30px"
          height="100%"
        />
      ))}
    </Box>
  </Card>
)

export const ProjectDiagramSkeleton = () => (
  <Card sx={{ height: '100%' }}>
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Skeleton
        animation="wave"
        variant="circular"
        width="310px"
        height="310px"
        sx={{
          marginTop: '20px'
        }}
      />
      <Box display="flex" justifyContent="center" alignItems="end" padding={2}>
        <Skeleton
          animation="wave"
          variant="text"
          width="60px"
          height="20px"
          sx={{
            marginTop: '65px',
            marginRight: '10px'
          }}
        />
        <Skeleton animation="wave" variant="text" width="60px" height="20px" />
      </Box>
    </Box>
  </Card>
)
