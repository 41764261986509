import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

const ProjectsDialog = React.lazy(
  () => import('src/views/settings/Projects/components/ProjectsDialog')
)

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { PROJECTS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { useDeleteProjectsMutation } from 'src/services/Query/projects/projects.mutation'
import {
  useGetProjects,
  useGetProjectsById
} from 'src/services/Query/projects/projects.query'
import { ProjectsFilter, ProjectsTableRow } from 'src/views/settings/Projects'

const Projects: React.FC = () => {
  const { themeStretch } = useSettings()
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSort,
    onChangePage
  } = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1
  })

  const { mutateAsync } = useDeleteProjectsMutation()

  const {
    activeId,
    params,
    toggleEdit,
    onCloseEdit,
    handleDeleteRow,
    handleEditRow,
    handleFilterValueChange
  } = useFiltersTable<ProjectsQueryParams, ProjectsFV>({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      page: 1,
      term: '',
      active: '',
      type: ''
    }
  })

  const { data, isFetched, isFetching } = useGetProjects({
    params: {
      ...params,
      page,
      limit: rowsPerPage,
      order,
      sort: orderBy || 'id'
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: project } = useGetProjectsById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const isNotFound = isFetched && !data?.projects?.length
  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Projects">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Projects
        </Typography>

        <ProjectsFilter handleFilterValueChange={handleFilterValueChange} />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.projects?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PROJECTS_TABLE_HEAD}
                  rowCount={data?.projects?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  ignoreHeadSort={['alias', 'isActive', 'type', '']}
                />

                <TableBody>
                  {data?.projects?.map((row) => (
                    <ProjectsTableRow
                      key={row.id}
                      row={row}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={handleEditRow}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>

      {toggleEdit && (
        <>
          <Suspense fallback={null}>
            <ProjectsDialog
              open={toggleEdit}
              values={project}
              variant="edit"
              onClose={onCloseEdit}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default Projects
