import React, { Suspense, useMemo } from 'react'
import { useNavigate } from 'react-router'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

import { Page, Scrollbar } from 'src/components/base'
import { TableHeadCustom, TableNoData } from 'src/components/table'
import { PLANS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import { useQuerySearchParams } from 'src/hooks/useQuerySearchParams'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { PATH_DASHBOARD } from 'src/routes/paths'
import Api from 'src/services/Api'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useDeletePlanMutation } from 'src/services/Query/plans/plan.mutation'
import { useGetPlans } from 'src/services/Query/plans/plans.query'
import { Filter } from 'src/views/dashboard/Plans'
import PlansTableRow from 'src/views/dashboard/Plans/components/PlansTableRow'

const Plans: React.FC = () => {
  const { themeStretch } = useSettings()
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSort,
    onChangePage
  } = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1
  })
  const navigate = useNavigate()

  const { date, endCurrentWeek } = DatePickerService
  const defaultValues = useMemo(
    () => ({
      projectIds: [],
      dateRange: [
        IntlService.formatDate(date.toString()),
        IntlService.formatDate(date.toString())
      ]
    }),
    []
  )

  const { mutateAsync } = useDeletePlanMutation()

  const { params, handleFilterValueChange, handleDeleteRow } = useFiltersTable({
    onChangePage,
    defaultValueParams: {
      projectIds: [],
      startDate: IntlService.formatDate(date.toString()),
      endDate: IntlService.formatDate(date.toString())
    },
    deleteFunction: mutateAsync
  })

  const { data, isFetched, isFetching } = useGetPlans({
    params: {
      ...params,
      page,
      limit: rowsPerPage
    },
    options: {
      refetchOnWindowFocus: true
    }
  })

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.general.plans.edit(id))
  }

  const isNotFound = isFetched && !data?.plans?.length
  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Projects">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Plans
        </Typography>

        <Filter
          handleFilterValueChange={handleFilterValueChange}
          defaultValues={defaultValues}
        />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PLANS_TABLE_HEAD}
                  rowCount={data?.plans?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  ignoreHeadSort={['alias', 'isActive', 'type', '']}
                />

                <TableBody>
                  {data?.plans?.map((row: Plan) => (
                    <PlansTableRow
                      key={row.id}
                      row={row}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                    />
                  ))}

                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>
    </Page>
  )
}

export default Plans
