import { string, object, array } from 'yup'

export const AbsenceSchema = object().shape({
  userInfo: object()
    .shape({
      value: string().required('User Name is required')
    })
    .nullable(),
  date: array()
    .min(1, 'Date is required')
    .required('Date is required')
    .nullable(),
  reason: string().trim().required('Reason is required').nullable()
})
