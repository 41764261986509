// form
import { useFormContext, Controller } from 'react-hook-form'

// @mui
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material'
import { Box } from '@mui/system'

interface IProps {
  name: string
  label?: string
  width?: string
  options: Options[]
}

export default function SelectField({
  name,
  label,
  options,
  width,
  ...rest
}: IProps & SelectProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box minWidth={180} width={width}>
          <FormControl fullWidth size="small">
            <InputLabel id={`id-${label || name}`} error={!!error?.message}>
              {label}
            </InputLabel>

            <Select
              labelId={`id-${label || name}`}
              error={!!error?.message}
              label={label}
              {...field}
              {...rest}
            >
              {options &&
                options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>

            {!!error && (
              <FormHelperText sx={{ color: 'error.main' }}>
                {error?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    />
  )
}
