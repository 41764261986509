import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid, Stack, Theme, useMediaQuery } from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  StaticDateRangePickerField
} from 'src/components/fields'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { transformMultipleValue } from 'src/utils/functions'

interface TimeWorkFilterProps {
  handleFilterValueChange: (value: TimeToWorkFiltersDto) => void
}

const TimeWorkFilter: React.FC<TimeWorkFilterProps> = ({
  handleFilterValueChange
}) => {
  const { data } = useGetUsersNames({ params: { active: true } })
  const { startCurrentWeek, date } = DatePickerService

  const methods = useForm<TimeToWorkFilters>({
    defaultValues: {
      userIds: [],
      dateRange: [startCurrentWeek.toString(), date.toString()]
    }
  })

  const { dateRange, userIds } = methods.watch()
  const cloneUserIds = JSON.stringify(userIds)

  useEffect(() => {
    if (dateRange?.[0] && dateRange?.[1]) {
      const normalizeValues = {
        userIds: transformMultipleValue(userIds),
        startDate: IntlService.formatDate(dateRange?.[0] || ''),
        endDate: IntlService.formatDate(dateRange?.[1] || '')
      }

      handleFilterValueChange(normalizeValues)
    }
  }, [dateRange, cloneUserIds])

  const usersOptions = useMemo(
    () =>
      data?.users?.map((user) => ({
        value: user.id,
        label: user.name
      })),
    [data]
  )
  return (
    <>
      <FormProvider methods={methods}>
        <Grid container spacing={2} mb={3}>
          <Grid item>
            <AutocompleteField
              name="userIds"
              label="User"
              options={usersOptions || []}
              multiple
            />
          </Grid>
          <Grid item>
            <StaticDateRangePickerField name="dateRange" />
          </Grid>
          <Grid item>
            <Button
              sx={{ height: '40px' }}
              variant="outlined"
              color="primary"
              onClick={() => methods.reset()}
            >
              Clear filter
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}

export default TimeWorkFilter
