// @mui
import { TableRow, TableCell, Box, CircularProgress } from '@mui/material'

import { EmptyContent } from '../base'

//

type Props = {
  isNotFound: boolean
  isFetching?: boolean
  isFetched?: boolean
  title?: string
  img?: string
}

export default function TableNoData({
  isNotFound,
  isFetched,
  isFetching,
  title = 'No Data',
  img
}: Props) {
  return (
    <>
      {isFetching && !isFetched && (
        <TableRow>
          <TableCell colSpan={9}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="165px"
            >
              <CircularProgress size={70} />
            </Box>
          </TableCell>
        </TableRow>
      )}

      {isNotFound && (
        <TableRow>
          <TableCell colSpan={9}>
            <EmptyContent
              showImg={!!img}
              img={img}
              title={title}
              sx={{
                '& span.MuiBox-root': { height: 160 }
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
