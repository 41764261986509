import React, { Suspense } from 'react'

import {
  Box,
  Card,
  Container,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

const AbsenceReportsDialog = React.lazy(
  () =>
    import('src/views/dashboard/AbsenceReports/components/AbsenceReportsDialog')
)

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { ABSENCE_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useDeleteAbsenceReportsMutation } from 'src/services/Query/absence-reports/absence-reports.mutation'
import {
  useGetAbsenceReports,
  useGetAbsenceReportsById
} from 'src/services/Query/absence-reports/absence-reports.query'
import {
  AbsenceReportsFilters,
  AbsenceReportsTableRow
} from 'src/views/dashboard/AbsenceReports'

const AbsenceReports: React.FC = () => {
  const { themeStretch } = useSettings()
  const { dense, page, order, orderBy, rowsPerPage, selected, onChangePage } =
    useTable({
      defaultRowsPerPage: 25,
      defaultCurrentPage: 1
    })

  const { mutateAsync } = useDeleteAbsenceReportsMutation()

  const { startCurrentYear, endCurrentYear } = DatePickerService
  const startDate = IntlService.formatDate(startCurrentYear.toString())
  const endDate = IntlService.formatDate(endCurrentYear.toString())

  const {
    activeId,
    params,
    toggleView,
    onCloseView,
    handleDeleteRow,
    handleFilterValueChange
  } = useFiltersTable<AbsenceReportsQueryParams, AbsenceFiltersDto>({
    deleteFunction: mutateAsync,
    onChangePage,
    defaultValueParams: {
      page: 1,
      startDate,
      endDate,
      userIds: []
    }
  })

  const { data, isFetched, isFetching } = useGetAbsenceReports({
    params: {
      ...params,
      page,
      limit: rowsPerPage
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: absence } = useGetAbsenceReportsById({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })

  const isNotFound = isFetched && !data?.absences?.length

  const totalPage = (data?.total && Math.ceil(data?.total / 25)) || 1

  return (
    <Page title="Absence Reports">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Absence Reports
        </Typography>

        <AbsenceReportsFilters
          handleFilterValueChange={handleFilterValueChange}
        />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={data?.absences?.length || 0}
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={ABSENCE_TABLE_HEAD}
                  rowCount={data?.absences?.length}
                  numSelected={selected.length}
                />

                <TableBody>
                  {data?.absences?.map((row) => (
                    <AbsenceReportsTableRow
                      key={row.id}
                      row={row}
                      onDeleteRow={handleDeleteRow}
                    />
                  ))}

                  {isNotFound && (
                    <TableNoData
                      isFetched={isFetched}
                      isFetching={isFetching}
                      isNotFound={isNotFound}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {totalPage > 1 && (
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ position: 'relative', borderTop: '1px solid #F4F6F8' }}
            >
              <Pagination
                count={totalPage || 1}
                page={page}
                onChange={onChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Box>
          )}
        </Card>
      </Container>

      {toggleView && (
        <>
          <Suspense fallback={null}>
            <AbsenceReportsDialog
              open={toggleView}
              variant="view"
              values={absence}
              onClose={onCloseView}
            />
          </Suspense>
        </>
      )}
    </Page>
  )
}

export default AbsenceReports
