import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const absenceReportsQueryConfig = {
  getAbsenceReports: {
    key: 'absence-reports/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, AbsenceReportsQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getAbsenceReports(normalizesParams)

      return resp.data
    }
  },
  getAbsenceReportsById: {
    key: 'absence-reports/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, id] = queryKey as [string, string]

      const resp = await Api.getAbsenceReportsById(id)

      return resp.data
    }
  }
}

interface UseGetAbsenceReports {
  params?: AbsenceReportsQueryParams
  options?: UseQueryOptions<AbsenceReportsResponse>
}

export const useGetAbsenceReports = (props?: UseGetAbsenceReports) => {
  const { options, params } = props || {}
  const { getAbsenceReports: config } = absenceReportsQueryConfig

  const state = useQuery<AbsenceReportsResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetAbsenceReportsById {
  id: string
  options?: UseQueryOptions<AbsenceReports>
}

export const useGetAbsenceReportsById = (props: UseGetAbsenceReportsById) => {
  const { id, options } = props
  const { getAbsenceReportsById: config } = absenceReportsQueryConfig

  const state = useQuery<AbsenceReports>([config.key, id], config.request, {
    ...options
  })
  return state
}
