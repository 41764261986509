import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// form
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Dialog } from '@mui/material'

import AbsenceReportsFormDialog from './AbsenceReportsFormDialog'
import AbsenceReportsViewDialog from './AbsenceReportsViewDialog'

import { VariantDialogTitle } from 'src/components/dialogs'
import DialogViewService from 'src/services/DialogViewService'
import {
  useCreateAbsenceReportsMutation,
  useEditAbsenceReportsMutation
} from 'src/services/Query/absence-reports/absence-reports.mutation'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { AbsenceSchema } from 'src/validations/schemas/absence.schema'

interface AbsenceReportsDialogProps extends VarianDialogProps {
  values?: AbsenceReports
}

export default function AbsenceReportsDialog({
  open,
  onClose,
  variant = 'create',
  values
}: AbsenceReportsDialogProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { data } = useGetUsersNames({ params: { active: true } })

  const nameOptions = data?.users.map((user) => ({
    label: user.name,
    value: user.id
  }))

  const createAbsenceReport = useCreateAbsenceReportsMutation()
  const editAbsenceReport = useEditAbsenceReportsMutation()

  const isView = variant === 'view'
  const isEdit = variant === 'edit'
  const isCreate = variant === 'create'
  const isEditable = isCreate || isEdit

  // find user options by id
  const editUser = nameOptions?.find((user) => user.value === values?.user?.id)

  const { id, createdAt, user, ...customValues } = values || {}

  const defaultValues: AbsenceReportsFV = {
    userInfo: { label: '', value: '' },
    comment: '',
    date: [],
    reason: '',
    ...customValues
  }

  const methods = useForm<AbsenceReportsFV>({
    resolver: yupResolver(AbsenceSchema),
    defaultValues,
    context: {
      isEdit
    }
  })

  useEffect(() => {
    if (isEdit && editUser?.value) {
      methods.reset({ ...defaultValues, userInfo: editUser })
    }
  }, [values?.id, editUser?.value])

  const onSubmit = async (formValues: AbsenceReportsFV) => {
    try {
      const { userInfo, ...otherValues } = formValues
      const userId = isEdit ? values?.user?.id : userInfo?.value

      const absences = {
        ...otherValues,
        userId: String(userId),
        date: isEdit ? [otherValues.date as string] : otherValues.date
      }

      if (isEdit && values?.id) {
        await editAbsenceReport.mutateAsync({
          id: values.id,
          absences
        })
      }

      if (isCreate) {
        await createAbsenceReport.mutateAsync(absences)
      }

      enqueueSnackbar('Create success!')
      onClose()
    } catch (error) {
      enqueueSnackbar(error?.data?.message, { variant: 'error' })
    }
  }

  const viewData = DialogViewService.absencesView(values)

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        createTitle="Create"
        editTitle="Edit"
        viewTitle="View"
        variant={variant}
        onClose={onClose}
      />

      {isView && <AbsenceReportsViewDialog viewData={viewData} />}

      {isEditable && (
        <AbsenceReportsFormDialog
          onClose={onClose}
          nameOptions={nameOptions}
          isView={isView}
          isEdit={isEdit}
          methods={methods}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}
