import { DialogContent, Typography, Divider, Stack } from '@mui/material'

import { IntlService } from 'src/services/IntlService'

const AdminsViewDialog: React.FC<AdminsProps> = ({ id, email }) => (
  <DialogContent>
    <Stack direction="row" justifyContent="space-between" spacing={2} my={2}>
      <Typography fontSize={18}>Id</Typography>
      <Typography fontSize={16}>{id || ''}</Typography>
    </Stack>

    <Divider />

    <Stack direction="row" justifyContent="space-between" spacing={2} my={2}>
      <Typography fontSize={18}>Email/Login</Typography>
      <Typography fontSize={16}>
        {IntlService.formatDate(email || '')}
      </Typography>
    </Stack>
  </DialogContent>
)

export default AdminsViewDialog
