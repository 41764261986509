import React, { Suspense, useCallback, useState } from 'react'

import { Container, Tab, Typography, Tabs } from '@mui/material'
import { Box } from '@mui/system'

import DomainTabPanel from './components/DomainTabPanel'

import { Page } from 'src/components/base'
import useSettings from 'src/hooks/useSettings'
import { a11yProps } from 'src/utils/functions'

const DOMAINS = [
  { key: 'codicaCom', name: 'codica.com' },
  { key: 'codicaBiz', name: 'codica.biz' },
  { key: 'codicaPro', name: 'codica.pro' }
]

const DomainStatistics: React.FC = () => {
  const { themeStretch } = useSettings()
  const [tabState, setTabState] = useState(0)

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabState(() => newValue)
    },
    []
  )

  return (
    <Page title="Domain Statistic">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4">Domains Statistics</Typography>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabState} onChange={handleChange} aria-label="tabs">
              {DOMAINS.map((domain, index) => (
                <Tab
                  key={domain.key}
                  label={domain.name}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>

          {DOMAINS.map((domain, index) => (
            <DomainTabPanel
              key={index}
              value={tabState}
              index={index}
              domain={domain.name}
            />
          ))}
        </Box>
      </Container>
    </Page>
  )
}

export default DomainStatistics
