import MotionLazyContainer from './components/animate/MotionLazyContainer'
import { ScrollToTop, ProgressBarStyle } from './components/base'
import { ChartStyle } from './components/chart'
import {
  NotistackProvider,
  RtlLayout,
  ThemeColorPresets,
  ThemeLocalization
} from './containers'
import Router from './routes'
import ThemeProvider from './theme'

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  )
}
