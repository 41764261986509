import { FC } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { ProjectDiagramSkeleton } from './Skeletons'

import { ProjectsDiagram } from 'src/components/diagrams'
import { useGetProjectsStatistic } from 'src/services/Query/dashboard/dashboard.query'

interface ProjectTypesDiagramProps {
  params: DashboardQueryParams
}

const ProjectTypesDiagram: FC<ProjectTypesDiagramProps> = ({ params }) => {
  const { data, isFetching } = useGetProjectsStatistic({
    params: {
      ...params
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const navigate = useNavigate()

  const chartData = data?.projectStatistics.map(
    (item: ProjectsStatistic) => item.hoursWorked
  )

  const typeLabels = data?.projectStatistics.map(
    (item: ProjectsStatistic) => item.type
  )

  const handleClick = (index: number) => {
    typeLabels &&
      navigate({
        pathname: '/weekly-report',
        search: createSearchParams({
          endDate: params.endDate || '',
          startDate: params.startDate || '',
          projectType: typeLabels[index]
        }).toString()
      })
  }

  if (isFetching) return <ProjectDiagramSkeleton />

  return (
    <ProjectsDiagram
      data={chartData || []}
      onClick={handleClick}
      labels={typeLabels || []}
    />
  )
}

export default ProjectTypesDiagram
