// Define enums and interfaces first
export enum ReputationCategory {
  UNKNOWN = 'UNKNOWN',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  BAD = 'BAD'
}

export interface IpReputation {
  ipAddress: string
  reputation: ReputationCategory
}

export interface FeedbackLoop {
  id: string
  description: string
  reportsCount: number
}

export interface DeliveryError {
  errorCode: string
  message: string
  timestamp: Date
}

// Main interface for the object
export interface GooglePostmasterResponse {
  name: string
  userReportedSpamRatio: number
  ipReputations: IpReputation[]
  domainReputation: ReputationCategory
  spammyFeedbackLoops: FeedbackLoop[]
  spfSuccessRatio: number
  dkimSuccessRatio: number
  dmarcSuccessRatio: number
  outboundEncryptionRatio: number
  inboundEncryptionRatio: number
  deliveryErrors: DeliveryError[]
}

export interface DomainResponse {
  data: GooglePostmasterResponse
}

export interface DomainQueryParams {
  name?: string
}
