import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

// form
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Dialog } from '@mui/material'

import AdminsFormDialog from './AdminsFormDialog'
import AdminsViewDialog from './AdminsViewDialog'

import { VariantDialogTitle } from 'src/components/dialogs'

export default function AdminsDialog({
  open,
  onClose,
  variant = 'create',
  values
}: VarianDialogProps) {
  const { enqueueSnackbar } = useSnackbar()

  const NewAddressSchema = Yup.object().shape({
    email: Yup.string().trim().required('Password is required'),
    password: Yup.string()
      .trim()
      .required('Password is required')
      .max(20, 'Password is too long')
  })

  const isView = variant === 'view'
  const isEdit = variant === 'edit'
  const isCreate = variant === 'create'
  const isEditable = isCreate || isEdit

  const defaultValues = {
    email: '',
    password: '',
    ...values
  }

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues
  })
  useEffect(() => {
    if (isEdit) {
      methods.reset(defaultValues)
    }
  }, [values?.name])

  const onSubmit = async () => {
    try {
      enqueueSnackbar('Create success!')
      onClose()
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        createTitle="Create"
        editTitle="Edit"
        viewTitle="View"
        variant={variant}
        onClose={onClose}
      />

      {isView && <AdminsViewDialog id={values?.id} email={values?.email} />}

      {isEditable && (
        <AdminsFormDialog
          onClose={onClose}
          isView={isView}
          isEdit={isEdit}
          methods={methods}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}
