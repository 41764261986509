import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import { Dialog } from '@mui/material'

import UsersFormDialog from './UsersFormDialog'

import { VariantDialogTitle } from 'src/components/dialogs'
import { useEditUsersMutation } from 'src/services/Query/users/users.mutation'
import { useGetPositions } from 'src/services/Query/users/users.query'

interface UsersDialogProps extends VarianDialogProps {
  values?: Users
  revalidateData?: () => void
}
const UsersDialog = ({
  open,
  onClose,
  revalidateData,
  variant = 'create',
  values
}: UsersDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const { data: positions } = useGetPositions()

  const editUsers = useEditUsersMutation()

  const isEdit = variant === 'edit'
  const isCustomEdit = variant === 'customEdit'

  const defaultValues = {
    name: values?.name || '',
    position: values?.position || '',
    active: values?.active || false,
    shouldLog: values?.shouldLog || false,
    shouldAppearInNotice: values?.shouldAppearInNotice || false,
    userName: values?.userName || '',
    login: values?.login || false
  }

  const positionOptions = positions?.userPositions?.map((position) => ({
    label: position,
    value: position
  }))

  const methods = useForm<UsersFV>({
    defaultValues
  })

  useEffect(() => {
    if (isEdit || isCustomEdit) {
      methods.reset(defaultValues)
    }
  }, [values?.id])

  const onSubmit = async (data: UsersFV) => {
    try {
      if ((isEdit || isCustomEdit) && values?.id) {
        const userData = {
          ...data,
          name: data.name ? data.name : undefined,
          position: data.position ? data.position : undefined,
          userName: data.userName ? data.userName : undefined
        }
        await editUsers.mutateAsync({ id: values.id, users: userData })
      }

      revalidateData && revalidateData()
      enqueueSnackbar('Edit success!')
      onClose()
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        editTitle="Edit"
        customEditTitle="Creds edit"
        variant={variant}
        onClose={onClose}
      />

      <UsersFormDialog
        positionOptions={positionOptions}
        methods={methods}
        onSubmit={onSubmit}
        onClose={onClose}
        isCredForm={isCustomEdit}
      />
    </Dialog>
  )
}

export default UsersDialog
