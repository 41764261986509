import { FC } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { HourlyCardSkeleton, HourlyDiagramSkeleton } from './Skeletons'

import { StatisticInfoCard } from 'src/components/base'
import { IntervalStatisticChart } from 'src/components/diagrams'
import { IntlService } from 'src/services/IntlService'
import {
  useGetHoursStatistic,
  useGetPeriodHoursStatistic
} from 'src/services/Query/dashboard/dashboard.query'

interface HoursStatisticProps {
  params: DashboardQueryParams
}

const HoursStatistic: FC<HoursStatisticProps> = ({ params }) => {
  const navigate = useNavigate()
  const { data: workStatistic, isFetching } = useGetHoursStatistic({
    params: {
      ...params
    },
    options: {
      refetchOnWindowFocus: false
    }
  })

  const { data: periodStatistic } = useGetPeriodHoursStatistic({
    options: {
      refetchOnWindowFocus: false
    }
  })

  const statistic = periodStatistic?.data
  const projectTypes = ['CLIENT', 'INTERNAL']

  const dateRange = statistic?.clients.map((item) => item.date) || []
  const formattedDateRange =
    dateRange.map((date) =>
      IntlService.formatDate(date, {
        month: 'long',
        year: undefined
      })
    ) || []

  const handleClick = (labelIndex: number, typeIndex: number) => {
    const currentDate = IntlService.formatDate(dateRange[labelIndex])

    projectTypes &&
      navigate({
        pathname: '/weekly-report',
        search: createSearchParams({
          endDate: currentDate,
          startDate: currentDate,
          projectType: projectTypes[typeIndex]
        }).toString()
      })
  }

  if (isFetching)
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <HourlyCardSkeleton />
        </Grid>
        <Grid item xs={6}>
          <HourlyCardSkeleton />
        </Grid>
        <Grid item xs={12}>
          <HourlyDiagramSkeleton />
        </Grid>
      </Grid>
    )

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <StatisticInfoCard type="CLIENT" data={workStatistic?.client} />
      </Grid>
      <Grid item xs={6}>
        <StatisticInfoCard type="INTERNAL" data={workStatistic?.internal} />
      </Grid>
      <Grid item xs={12}>
        <IntervalStatisticChart
          data={statistic}
          onClick={handleClick}
          projectTypes={projectTypes}
          dateRange={formattedDateRange}
        />
      </Grid>
    </Grid>
  )
}

export default HoursStatistic
