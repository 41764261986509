import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

import { Page, Scrollbar } from 'src/components/base'
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions
} from 'src/components/table'
import { WEEKLY_REPORTS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import { useQuerySearchParams } from 'src/hooks/useQuerySearchParams'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import {
  useGetClientCsvReport,
  useGetCsvReport,
  useGetWeeklyReport
} from 'src/services/Query/projects/projects.query'
import { downloadFile } from 'src/utils/functions'
import {
  WeeklyReportsFilter,
  WeeklyReportsTableRow
} from 'src/views/dashboard/WeeklyReports'

const WeeklyReports: React.FC = () => {
  const { getParams } = useQuerySearchParams()

  const searchParams: WeeklyReportFilters = getParams([
    'startDate',
    'endDate',
    'projectType'
  ])

  const { themeStretch } = useSettings()
  const { dense, order, orderBy, selected, onChangePage } = useTable({
    defaultRowsPerPage: 25,
    defaultCurrentPage: 1
  })

  const { startCurrentWeek, endCurrentWeek } = DatePickerService

  const defaultValues = useMemo(
    () => ({
      projectType: searchParams.projectType || '',
      projectIds: [],
      startDate:
        searchParams.startDate ||
        IntlService.formatDate(startCurrentWeek.toString()),
      endDate:
        searchParams.endDate ||
        IntlService.formatDate(endCurrentWeek.toString())
    }),
    [searchParams]
  )

  const { params, handleFilterValueChange } = useFiltersTable({
    onChangePage,
    defaultValueParams: defaultValues
  })

  const { data, isFetched, isFetching } = useGetWeeklyReport({
    params: { ...params },
    options: {
      refetchOnWindowFocus: false
    }
  })
  const reports = data?.timeReports
  const totalTime = data?.totalTime

  const additionalInfo = [{ id: 'totalTime', data: totalTime || '00:00' }]

  const { refetch } = useGetCsvReport({
    params: { ...params },
    options: {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (csvData) => {
        if (csvData)
          downloadFile(csvData, { fileName: 'WeeklyReport', formatFile: 'csv' })
      }
    }
  })

  const { refetch: getClientCsv } = useGetClientCsvReport({
    params: { ...params },
    options: {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (csvData) => {
        if (csvData)
          downloadFile(csvData, { fileName: 'WeeklyReport', formatFile: 'csv' })
      }
    }
  })

  const isNotFound = isFetched && !reports?.length
  return (
    <Page title="Weekly Reports">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Weekly Reports
        </Typography>

        <Stack direction="column" mb={3}>
          <WeeklyReportsFilter
            handleFilterValueChange={handleFilterValueChange}
            defaultValues={defaultValues}
          />
          <Box>
            <Button
              variant="contained"
              sx={{ minHeight: '40px', width: '200px', marginRight: 1 }}
              onClick={() => refetch()}
            >
              Download PM Report
            </Button>

            <Button
              variant="contained"
              sx={{ minHeight: '40px', width: '250px' }}
              onClick={() => getClientCsv()}
            >
              Download Accounting Report
            </Button>
          </Box>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={reports?.length || 0}
                />
              )}

              <Table size={'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={WEEKLY_REPORTS_TABLE_HEAD}
                  rowCount={reports?.length}
                  numSelected={selected.length}
                  additionalInfo={additionalInfo}
                />

                <TableBody>
                  {reports?.map((row, index) => (
                    <WeeklyReportsTableRow key={index} row={row} />
                  ))}

                  {isNotFound && (
                    <TableNoData
                      isFetched={isFetched}
                      isFetching={isFetching}
                      isNotFound={isNotFound}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  )
}

export default WeeklyReports
