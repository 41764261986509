import { ReactNode } from 'react'
// form
import {
  FieldValues,
  FormProvider as Form,
  UseFormReturn
} from 'react-hook-form'

type Props<T extends FieldValues> = {
  children: ReactNode
  methods: UseFormReturn<T>
  onSubmit?: VoidFunction
}

export default function FormProvider<T extends FieldValues>({
  children,
  onSubmit,
  methods
}: Props<T>) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  )
}
