// form
import { useFormContext, Controller } from 'react-hook-form'

// @mui
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps
} from '@mui/material'

import AutocompleteTags from '../../base/AutocompleteTags'

interface IProps {
  name: string
  label?: string
  width?: string
  defaultValueKey?: 'value' | 'label' | 'all'
  options: Options[]
  triggerFields?: string[]
}

const AutocompleteField = ({
  name,
  label,
  width,
  options,
  defaultValueKey = 'value',
  sx,
  triggerFields,
  ...rest
}: IProps &
  Partial<AutocompleteProps<Options, true | false, false, false>> &
  TextFieldProps) => {
  const { control, trigger } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          size="small"
          {...field}
          limitTags={1}
          disablePortal
          sx={{
            width: width || '240px',
            '& .MuiChip-label': {
              width: '80px'
            },
            ...sx
          }}
          handleHomeEndKeys
          noOptionsText="No options"
          options={options}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          isOptionEqualToValue={(option: Options): boolean => {
            if (Array.isArray(field.value)) {
              return field.value.find((f) => f.value === option.value)
            }

            return option.value === (field?.value?.value || field?.value)
          }}
          renderInput={(params) => {
            const errorMessage = error?.message
              ? error.message
              : (error as { value?: { message: string } })?.value?.message

            return (
              <TextField
                {...params}
                inputRef={field.ref}
                label={label}
                error={!!error}
                helperText={errorMessage}
              />
            )
          }}
          renderTags={(tagValue, getTagProps) => {
            if (tagValue.length > 0) {
              return (
                <AutocompleteTags value={tagValue} getTagProps={getTagProps} />
              )
            }
          }}
          onChange={(_e, value, reason) => {
            if (!!triggerFields?.length) {
              trigger(triggerFields)
            }

            if (reason === 'clear') {
              field.onChange('')
            }

            if (defaultValueKey === 'all') {
              return field.onChange(value)
            }

            if (rest?.multiple && Array.isArray(value)) {
              return field.onChange(value)
            }

            if (!rest?.multiple && !Array.isArray(value)) {
              return field.onChange(value?.[defaultValueKey])
            }
          }}
          {...rest}
        />
      )}
    />
  )
}

export default AutocompleteField
