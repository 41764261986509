import { ReactNode, createContext, useState, useEffect } from 'react'

// hooks
import useResponsive from '../hooks/useResponsive'

export type CollapseDrawerContextProps = {
  isCollapse?: boolean
  collapseClick: boolean
  onToggleCollapse: VoidFunction
}

const initialState: CollapseDrawerContextProps = {
  collapseClick: false,
  onToggleCollapse: () => {}
}

const CollapseDrawerContext = createContext(initialState)

type CollapseDrawerProviderProps = {
  children: ReactNode
}

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const isDesktop = useResponsive('up', 'lg')

  const [collapse, setCollapse] = useState({
    click: false
  })

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false
      })
    }
  }, [isDesktop])

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click })
  }

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click,
        collapseClick: collapse.click,
        onToggleCollapse: handleToggleCollapse
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  )
}

export { CollapseDrawerProvider, CollapseDrawerContext }
