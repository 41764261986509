// @mui
import { Checkbox, TableRow, TableCell } from '@mui/material'

import ActionTableMenu from 'src/components/table/ActionTableMenu'
import { IntlService } from 'src/services/IntlService'
interface UsersTableRowProps extends Partial<TableRowProps> {
  row: Users
}

const UsersTableRow = ({
  row,
  selected,
  onEditRow,
  onCustomEditRow,
  onSelectRow
}: UsersTableRowProps) => {
  const {
    name,
    id,
    active,
    shouldLog,
    shouldAppearInNotice,
    position,
    userName,
    havePassword,
    login,
    createdAt
  } = row

  return (
    <TableRow hover selected={selected}>
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}

      <TableCell align="left" width={400}>
        {name}
      </TableCell>

      <TableCell align="left" width={200}>
        {active ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" width={300}>
        {shouldLog ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" width={300}>
        {shouldAppearInNotice ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" width={400}>
        {position}
      </TableCell>

      <TableCell align="left" width={400}>
        {userName}
      </TableCell>

      <TableCell align="left" width={200}>
        {havePassword ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" width={200}>
        {login ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} width={200}>
        {IntlService.formatDate(createdAt)}
      </TableCell>

      <ActionTableMenu
        id={id}
        onEditRow={onEditRow}
        onCustomEditRow={onCustomEditRow}
      />
    </TableRow>
  )
}

export default UsersTableRow
