import React, { FC, Suspense, useMemo } from 'react'

import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableRow
} from '@mui/material'

const CredentialsDialog = React.lazy(
  () => import('src/components/dialogs/CredentialsDialog')
)

import { Page, Scrollbar } from 'src/components/base'
import { TableHeadCustom, TableNoData } from 'src/components/table'
import { CREDENTIALS_TABLE_HEAD } from 'src/constants/table.constants'
import { useFiltersTable } from 'src/hooks/useFiltersTable'
import useSettings from 'src/hooks/useSettings'
import useTable from 'src/hooks/useTable'
import useToggle from 'src/hooks/useToggle'
import { useDeleteCredentialMutation } from 'src/services/Query/credential-managment/credential-managment.mutation'
import {
  useGetCredentialByIdQuery,
  useGetCredentialQuery,
  useGetCredentialTypesQuery
} from 'src/services/Query/credential-managment/credential-managment.query'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { createOptions } from 'src/utils/functions'
import CredentialTableRow from 'src/views/settings/Credential/CredentialTableRow'

const Credentials: FC = () => {
  const { themeStretch } = useSettings()

  const { toggle, onOpen, onClose } = useToggle(false)

  const { onChangePage } = useTable({
    defaultRowsPerPage: 25
  })
  const { mutateAsync } = useDeleteCredentialMutation()

  const { toggleEdit, onCloseEdit, handleEditRow, activeId, handleDeleteRow } =
    useFiltersTable({
      defaultValueParams: {},
      onChangePage,
      deleteFunction: mutateAsync
    })

  const projectsQueryName = useGetProjectsNames({ params: { active: true } })

  const { data: dataById } = useGetCredentialByIdQuery({
    id: activeId,
    options: {
      enabled: !!activeId
    }
  })
  const projectsOptions = createOptions(projectsQueryName?.data?.projects || [])
  const { data: credentialTypes } = useGetCredentialTypesQuery()

  const boardType = useMemo(
    () =>
      credentialTypes?.credentialTypes?.map((name) => ({
        value: name,
        label: name
      })),
    [credentialTypes?.credentialTypes]
  )

  const { data, isFetched, isFetching } = useGetCredentialQuery()
  const isNotFound = isFetched && !data?.length

  return (
    <Page title="Projects">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" mb={1.5}>
          Management Credential
        </Typography>

        <Button
          variant="contained"
          sx={{ minHeight: '40px', width: '280px', mb: 2 }}
          onClick={() => onOpen()}
        >
          Create Management Credentials
        </Button>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size="medium">
              <TableHeadCustom headLabel={CREDENTIALS_TABLE_HEAD} />

              <TableBody>
                {data?.map((row) => (
                  <CredentialTableRow
                    row={row}
                    onEditRow={() => handleEditRow(row.id)}
                    onDeleteRow={() => handleDeleteRow(row.id)}
                  />
                ))}

                <TableNoData
                  isFetched={isFetched}
                  isFetching={isFetching}
                  isNotFound={isNotFound}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Container>

      {toggle && (
        <Suspense fallback={null}>
          <CredentialsDialog
            title="Management Credentials"
            open={toggle}
            variant="create"
            onClose={onClose}
            id={activeId}
            boardType={boardType}
            projectsOptions={projectsOptions}
          />
        </Suspense>
      )}

      {toggleEdit && (
        <Suspense fallback={null}>
          <CredentialsDialog
            title="Management Credentials"
            open={toggleEdit}
            onClose={onCloseEdit}
            variant="edit"
            boardType={boardType}
            data={dataById}
            id={activeId}
            projectsOptions={projectsOptions}
          />
        </Suspense>
      )}
    </Page>
  )
}
export default Credentials
