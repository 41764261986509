import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  startOfYesterday
} from 'date-fns'
import { FieldValues, UseFormSetValue } from 'react-hook-form'

import { ButtonProps } from '@mui/material'

import { IntlService } from './IntlService'

export type ConfigProps = ButtonProps & {
  itemValue: string[] | Date[] | string
}
export class DatePickerService {
  static date = new Date()

  static isFirstPartOfMonth = this.date.getDate() < 16

  static yesterday = startOfYesterday()
  static startCurrentWeek = startOfWeek(this.date, { weekStartsOn: 1 })
  static endCurrentWeek = endOfWeek(this.date, { weekStartsOn: 1 })

  // date 2 weeks ago
  static twoWeeksAgo = IntlService.formatDate(
    new Date(new Date(this.date).setDate(this.date.getDate() - 13)).toString()
  )

  // start of current month
  static startMonth = startOfMonth(this.date)

  static startCurrentYear = IntlService.formatDate(
    new Date(this.date.getFullYear(), 0, 1).toString()
  )

  static endCurrentYear = IntlService.formatDate(
    new Date(this.date.getFullYear(), 11, 31).toString()
  )

  // end of current month
  static endMonth = endOfMonth(this.date)

  //   start day of previous month
  static startPreviousMonth = startOfMonth(
    new Date(startOfMonth(this.date).setMonth(this.startMonth.getMonth() - 1))
  )

  //   end day of previous month
  static endPreviousMonth = endOfMonth(
    new Date(startOfMonth(this.date).setMonth(this.endMonth.getMonth() - 1))
  )

  //   start day of previous week
  static startPreviousWeek = startOfWeek(
    new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate() - 7
    ),
    { weekStartsOn: 1 }
  )

  //   end day of previous week
  static endPreviousWeek = endOfWeek(
    new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate() - 7
    ),
    { weekStartsOn: 1 }
  )

  //   start day of next week
  static startNextWeek = startOfWeek(
    new Date(
      startOfWeek(this.date).setDate(this.startCurrentWeek.getDate() + 7)
    ),
    { weekStartsOn: 1 }
  )

  //   end day of next week
  static endNextWeek = endOfWeek(
    new Date(endOfWeek(this.date).setDate(this.endCurrentWeek.getDate() + 7)),
    { weekStartsOn: 1 }
  )

  //   get sixteen day of previous month
  static getSixteenPreviousMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth() - 1)
    ).setDate(16)
  )

  //   get fifteenth day of current month
  static getFifteenthCurrentMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth())
    ).setDate(15)
  )
  //   get sixteen day of current month
  static getSixteenCurrentMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth())
    ).setDate(16)
  )
  //   get fifteenth day of next month
  static getFifteenthNextMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth() + 1)
    ).setDate(15)
  )
  //  sixteen before last day of prev month
  static getSixteenBeforeLastMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth() - 2)
    ).setDate(16)
  )

  //  fifteenth before last day of prev month
  static getFifteenthBeforeLastMonth = new Date(
    new Date(
      startOfMonth(this.date).setMonth(this.startMonth.getMonth() - 1)
    ).setDate(15)
  )
  //  start day of prev month
  static getStartDayLastMonth = this.isFirstPartOfMonth
    ? this.getSixteenBeforeLastMonth
    : this.getSixteenPreviousMonth

  //  end day of prev month
  static getEndDayLastMonth = this.isFirstPartOfMonth
    ? this.getFifteenthBeforeLastMonth
    : this.getFifteenthCurrentMonth

  //  start day of current month
  static getStartDayCurrentMonth = this.isFirstPartOfMonth
    ? this.getSixteenPreviousMonth
    : this.getSixteenCurrentMonth

  //  end day of current month
  static getEndDayCurrentMonth = this.isFirstPartOfMonth
    ? this.getFifteenthCurrentMonth
    : this.getFifteenthNextMonth

  // find weekend days
  static findWeekendDays = (day: Date) => {
    const date = day.getDay()
    return date === 0 || date === 6
  }

  // generate config button
  static generateConfigButtonConfig = ({
    name,
    onClose,
    setValue
  }: {
    name: string
    onClose?: () => void
    setValue: UseFormSetValue<FieldValues>
  }): ConfigProps[] => [
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.date, this.date])
        onClose && onClose()
      },
      itemValue: [this.date, this.date],
      title: 'Today'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.yesterday, this.yesterday])
        onClose && onClose()
      },
      itemValue: [this.yesterday, this.yesterday],
      title: 'Yesterday'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.startCurrentWeek, this.endCurrentWeek])
        onClose && onClose()
      },
      itemValue: [this.startCurrentWeek, this.endCurrentWeek],

      title: 'Current Week'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.startPreviousWeek, this.endPreviousWeek])
        onClose && onClose()
      },
      itemValue: [this.startPreviousWeek, this.endPreviousWeek],

      title: 'Last week'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [
          this.getStartDayCurrentMonth,
          this.getEndDayCurrentMonth
        ])
        onClose && onClose()
      },
      itemValue: [this.getStartDayCurrentMonth, this.getEndDayCurrentMonth],

      title: 'Current month'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.getStartDayLastMonth, this.getEndDayLastMonth])
        onClose && onClose()
      },
      itemValue: [this.getStartDayLastMonth, this.getEndDayLastMonth],

      title: 'Last month'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.startMonth, this.endMonth])
        onClose && onClose()
      },
      itemValue: [this.startMonth, this.endMonth],
      title: 'Current month (Calendar)'
    },
    {
      variant: 'outlined',
      onClick: () => {
        setValue(name, [this.startPreviousMonth, this.endPreviousMonth])
        onClose && onClose()
      },
      itemValue: [this.startPreviousMonth, this.endPreviousMonth],
      title: 'Last month (Calendar)'
    }
  ]
}
