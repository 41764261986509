import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Typography } from '@mui/material'

import { Page } from 'src/components/base'
import { FormProvider } from 'src/components/fields'
import useSettings from 'src/hooks/useSettings'
import { PATH_DASHBOARD } from 'src/routes/paths'
import Api from 'src/services/Api'
import { useGetPlanById } from 'src/services/Query/plans/plans.query'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { useGetWorkTypes } from 'src/services/Query/time-entries/time-entries.query'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { createOptions } from 'src/utils/functions'
import { PlanCreateSchema } from 'src/validations/schemas/plan.schema'
import PlanForm from 'src/views/dashboard/Plans/components/PlanForm/PlanForm'

const PROGRESS_TRACKER = {
  false: 'BOOLEAN',
  true: 'PERCENTS'
}

const fieldNames = {
  user: 'usersFields',
  task: 'taskFields'
}

const EditPlan = () => {
  const { themeStretch } = useSettings()
  const navigate = useNavigate()
  const { id: planId } = useParams()

  const { data: planData, isLoading: isLoadingData } = useGetPlanById(
    planId || ''
  )
  const { data } = useGetProjectsNames({ params: { active: true } })
  const { data: workTypesNames, isLoading } = useGetWorkTypes()
  const { data: usersName } = useGetUsersNames({
    params: { active: true }
  })

  const options = createOptions(data?.projects as Projects[])
  const optionsWorkType = workTypesNames?.workTypes.map((item) => ({
    value: item,
    label: item
  }))

  const defaultValues = useMemo(() => {
    if (!planData) {
      return {
        project: { label: '', value: '' },
        description: '',
        date: new Date(),
        progressTracker: false,
        active: false,
        usersFields: undefined
      }
    }

    const { tasks, project, ...plan } = planData
    const normalizeTasks = tasks.reduce((acc, { user, ...item }) => {
      const a = acc[user.id] || []

      return {
        ...acc,
        [user.id]: [
          ...a,
          {
            ...item,
            workType: { value: item.workType, label: item.workType }
          }
        ]
      }
    }, {} as { [key: string]: Omit<Task, 'user'>[] })

    const dto = Object.entries(normalizeTasks).map(([key, value]) => {
      const task = tasks.find(({ user }) => user.id === key)

      return {
        user: { label: task?.user.name, value: task?.user.id },
        taskFields: value
      }
    })

    return {
      project: { label: project.name, value: project.id },
      progressTracker: false,
      active: false,
      date: new Date(plan.date),
      description: plan?.description,
      usersFields: dto
    }
  }, [planData]) as PlanDefaultValues

  const optionsUsersName = createOptions(usersName?.users as Users[])
  const methods = useForm({
    resolver: yupResolver(PlanCreateSchema),
    defaultValues: defaultValues
  })

  const onSubmit = async ({ usersFields, project, ...plan }: CreatePlan) => {
    try {
      const tasks = usersFields?.map(({ user, taskFields }) => {
        const tasks = taskFields?.map((item) => ({
          ...item,
          userId: user.value,
          workType: item.workType.value
        }))

        return tasks
      })
      const tasksWithDtoFlat = tasks?.flat()

      await Api.updatePlan({
        id: planId,
        data: {
          ...plan,
          projectId: project?.value,
          progressTracker: PROGRESS_TRACKER.true,
          tasks: tasksWithDtoFlat
        }
      })

      navigate(PATH_DASHBOARD.general.plans.index)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (planData) {
      methods.reset(defaultValues)
    }
  }, [isLoadingData, planData])

  return (
    <Page title="Edit">
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Typography variant="h4" mb={1.5}>
            Edit Project Plan
          </Typography>

          <PlanForm
            options={options}
            optionsUsersName={optionsUsersName}
            isLoading={isLoading}
            optionsWorkType={optionsWorkType}
            fieldNames={fieldNames}
            isEdit
          />
        </Container>
      </FormProvider>
    </Page>
  )
}

export default EditPlan
