const keys = ['accessToken', 'confirmationEmail', 'refreshToken'] as const

type ItemName = typeof keys[number]

class LocalStorage {
  static getItem(itemName: ItemName) {
    const item = localStorage.getItem(itemName)
    const numPatt = new RegExp(/^\d+$/)
    const jsonPatt = new RegExp(/[[{].*[}\]]/)

    if (item) {
      if (jsonPatt.test(item)) {
        return JSON.parse(item)
      }
      if (numPatt.test(item)) {
        return parseFloat(item)
      }

      return item
    }

    return null
  }

  static setItem(itemName: ItemName, item: string) {
    if (typeof item === 'object') {
      localStorage.setItem(itemName, JSON.stringify(item))
    } else {
      localStorage.setItem(itemName, item)
    }
  }

  static removeItem(itemName: ItemName) {
    localStorage.removeItem(itemName)
  }

  static clear() {
    localStorage.clear()
  }
}

export default LocalStorage
