import { useMutation, useQueryClient } from 'react-query'

import { credentialConfig } from './credential-managment.query'

import Api from 'src/services/Api'

const credentialManagment = {
  createCredential: {
    key: 'credential/create',
    request: Api.createCredantialManagment
  },
  editCredantialManagment: {
    key: 'credential/edit',
    request: Api.updateCredantialManagment
  },
  deleteCredentialManagment: {
    key: 'credential/delete',
    request: Api.removeCredantialManagment
  }
}
export const useCreateCredentialMutation = () => {
  const { createCredential: config } = credentialManagment
  const queryClient = useQueryClient()

  const state = useMutation<
    CredentialManagement,
    string,
    CredentialManagementFV
  >(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(credentialConfig.getCredential.key)
    }
  })

  return state
}

export const useEditCredentialMutation = () => {
  const queryClient = useQueryClient()

  const { editCredantialManagment: config } = credentialManagment

  const state = useMutation<
    CredentialManagement,
    string,
    { id: string; data: CredentialManagementFV }
  >(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(credentialConfig.getCredential.key)
    }
  })
  return state
}
export const useDeleteCredentialMutation = () => {
  const queryClient = useQueryClient()

  const { deleteCredentialManagment: config } = credentialManagment

  const state = useMutation<CredentialManagementRespons, string, string>(
    config.key,
    config.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(credentialConfig.getCredential.key)
      }
    }
  )

  return state
}
