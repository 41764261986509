import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const projectsConfig = {
  getProjects: {
    key: 'projects/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, ProjectsQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getProjects(normalizesParams)

      return resp.data
    }
  },
  getProjectsById: {
    key: 'projects/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, id] = queryKey as [string, string]

      const resp = await Api.getProjectsById(id)

      return resp.data
    }
  },
  getProjectsNames: {
    key: 'projects/names',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, ProjectsNamesQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getProjectsNames(normalizesParams)

      return resp.data
    }
  },
  getWeeklyReport: {
    key: 'projects/weekly-report',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, ProjectsWeeklyReportQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getWeeklyReport(normalizesParams)

      return resp.data
    }
  },
  getCsvReport: {
    key: 'projects/csv-report',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, TimeEntriesQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getCsvTimeReports(normalizesParams)

      return resp
    }
  },
  getCsvClientReport: {
    key: 'projects/csv-client-report',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [, params] = queryKey as [string, TimeEntriesQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getCsvClientReports(normalizesParams)

      return resp
    }
  }
}

interface UseGetProjects {
  params: ProjectsQueryParams
  options?: UseQueryOptions<ProjectsResponse>
}

export const useGetProjects = (props?: UseGetProjects) => {
  const { options, params } = props || {}
  const { getProjects: config } = projectsConfig

  const state = useQuery<ProjectsResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetProjectsById {
  id: string
  options?: UseQueryOptions<ProjectsResponse, string, Projects>
}

export const useGetProjectsById = (props?: UseGetProjectsById) => {
  const { options, id } = props || {}
  const { getProjectsById: config } = projectsConfig

  const state = useQuery<ProjectsResponse, string, Projects>(
    [config.key, id],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetProjectsNames {
  params: ProjectsNamesQueryParams
  options?: UseQueryOptions<FilterNameProjects>
}

export const useGetProjectsNames = (props?: UseGetProjectsNames) => {
  const { options, params } = props || {}
  const { getProjectsNames: config } = projectsConfig

  const state = useQuery<FilterNameProjects>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetWeeklyReport {
  params: ProjectsWeeklyReportQueryParams
  options?: UseQueryOptions<WeeklyReportResponse>
}

export const useGetWeeklyReport = (props?: UseGetWeeklyReport) => {
  const { params, options } = props || {}
  const { getWeeklyReport: config } = projectsConfig

  const state = useQuery<WeeklyReportResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetCsvReport {
  params: TimeEntriesQueryParams
  options?: UseQueryOptions<string>
}

export const useGetCsvReport = (props?: UseGetCsvReport) => {
  const { params, options } = props || {}
  const { getCsvReport: config } = projectsConfig

  const state = useQuery<string>([config.key, params], config.request, {
    ...options
  })
  return state
}

export const useGetClientCsvReport = (props?: UseGetCsvReport) => {
  const { params, options } = props || {}
  const { getCsvClientReport: config } = projectsConfig

  const state = useQuery<string>([config.key, params], config.request, {
    ...options
  })
  return state
}
