import { Theme } from '@mui/material/styles'

export default function Backdrop(_theme: Theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&.MuiBackdrop-invisible': {
            background: 'transparent'
          }
        }
      }
    }
  }
}
