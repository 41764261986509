import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const planConfig = {
  getPlans: {
    key: 'plans/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, params] = queryKey as [string, HolidaysQueryParams]
      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getPlans(normalizesParams)

      //   @ts-ignore
      return resp.data
    }
  },
  getPlanById: {
    key: 'plan/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, id] = queryKey as [string, string]
      const resp = await Api.getPlanById(id)

      //   @ts-ignore
      return resp.data
    }
  },
  getLatestPlan: {
    key: 'plan/latest-plan',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, id] = queryKey as [string, string]
      const resp = await Api.getLatestPlan(id)

      //   @ts-ignore
      return resp.data
    }
  }
}

interface UseGetPlans {
  params?: PlanQueryParams
  options?: UseQueryOptions<PlanResponse>
}

export const useGetPlans = (props?: UseGetPlans) => {
  const { options, params } = props || {}
  const { getPlans: config } = planConfig

  const state = useQuery<PlanResponse>([config.key, params], config.request, {
    ...options
  })
  return state
}

export const useGetPlanById = (
  id: string,
  options?: UseQueryOptions<PlanEditResponse>
) => {
  const { getPlanById: config } = planConfig

  const state = useQuery<PlanEditResponse>([config.key, id], config.request, {
    ...options
  })
  return state
}

export const useGetLatestPlan = (
  id: string,
  options?: UseQueryOptions<PlanEditResponse>
) => {
  const { getLatestPlan: config } = planConfig

  const state = useQuery<PlanEditResponse>([config.key, id], config.request, {
    ...options
  })
  return state
}
