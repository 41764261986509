import { Link as RouterLink } from 'react-router-dom'

// @mui
import { BoxProps } from '@mui/material'

import { ReactComponent as IconLogo } from 'src/assets/logo.svg'

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false }: Props) {
  if (disabledLink) {
    return (
      <>
        <IconLogo />
      </>
    )
  }

  return (
    <RouterLink to="/">
      <IconLogo />
    </RouterLink>
  )
}
