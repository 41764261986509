import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const usersConfig = {
  getUsers: {
    key: 'users/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, params] = queryKey as [string, UsersQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getUsers(normalizesParams)

      return resp.data
    }
  },
  getUsersById: {
    key: 'users/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, id] = queryKey as [string, string]

      const resp = await Api.getUsersById(id)

      return resp.data
    }
  },
  getUsersNames: {
    key: 'users/names',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, params] = queryKey as [string, UsersQueryParams]

      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getUsersNames(normalizesParams)

      return resp.data
    }
  },
  getPositions: {
    key: 'users/positions',
    request: async () => {
      const resp = await Api.getPositions()

      return resp.data
    }
  }
}

interface UseGetUsers {
  params?: UsersQueryParams
  options?: UseQueryOptions<UsersResponse>
}

export const useGetUsers = (props?: UseGetUsers) => {
  const { options, params } = props || {}
  const { getUsers: config } = usersConfig

  const state = useQuery<UsersResponse>([config.key, params], config.request, {
    ...options
  })
  return state
}

interface UseGetUsersById {
  id: string
  options?: UseQueryOptions<Users>
}

export const useGetUsersById = (props?: UseGetUsersById) => {
  const { options, id } = props || {}
  const { getUsersById: config } = usersConfig

  const state = useQuery<Users>([config.key, id], config.request, {
    ...options
  })
  return state
}

interface UseGetUsersNames {
  params: UsersNamesQueryParams
  options?: UseQueryOptions<FilterNameUsers>
}

export const useGetUsersNames = (props?: UseGetUsersNames) => {
  const { options, params } = props || {}
  const { getUsersNames: config } = usersConfig

  const state = useQuery<FilterNameUsers>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetPositions {
  options?: UseQueryOptions<UserPositions>
}

export const useGetPositions = (props?: UseGetPositions) => {
  const { options } = props || {}
  const { getPositions: config } = usersConfig

  const state = useQuery<UserPositions>([config.key], config.request, {
    ...options
  })
  return state
}
