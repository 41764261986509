// @mui
import { Checkbox, TableRow, TableCell } from '@mui/material'

import ActionTableMenu from 'src/components/table/ActionTableMenu'

interface AdminsTableRowProps extends TableRowProps {
  row: AdminsProps
}

const AdminsTableRow = ({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onViewRow
}: AdminsTableRowProps) => {
  const { email, id } = row

  return (
    <TableRow hover selected={selected}>
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}

      <TableCell
        align="left"
        width={300}
        sx={{
          maxWidth: '300px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {id}
      </TableCell>

      <TableCell align="left" width={400}>
        {email}
      </TableCell>

      <ActionTableMenu
        id={id}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        onViewRow={onViewRow}
      />
    </TableRow>
  )
}

export default AdminsTableRow
