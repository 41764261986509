import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Stack, Typography } from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  DatePickerField
} from 'src/components/fields'
import useToggle from 'src/hooks/useToggle'
import { DatePickerService } from 'src/services/DatePickerService'
import { IntlService } from 'src/services/IntlService'
import { useGetUsersNames } from 'src/services/Query/users/users.query'
import { transformMultipleValue } from 'src/utils/functions'

const AbsenceReportsDialog = React.lazy(() => import('./AbsenceReportsDialog'))

interface AbsenceReportsFilterProps {
  handleFilterValueChange: (value: AbsenceFiltersDto) => void
}

const AbsenceReportsFilters: React.FC<AbsenceReportsFilterProps> = ({
  handleFilterValueChange
}) => {
  const { toggle, onClose, onOpen } = useToggle(false)

  const { startCurrentYear, endCurrentYear } = DatePickerService

  const { data } = useGetUsersNames({ params: { active: true } })

  const usersOptions = useMemo(
    () =>
      data?.users?.map((user) => ({
        value: user.id,
        label: user.name
      })),
    [data]
  )

  const methods = useForm<AbsenceFilters>({
    defaultValues: {
      userIds: [],
      startDate: startCurrentYear,
      endDate: endCurrentYear
    }
  })

  const { startDate, endDate, userIds } = methods.watch()
  const cloneUserIds = JSON.stringify(userIds)

  useEffect(() => {
    if (startDate && endDate) {
      const normalizeValues = {
        userIds: transformMultipleValue(userIds),
        startDate: IntlService.formatDate(startDate || ''),
        endDate: IntlService.formatDate(endDate || '')
      }

      handleFilterValueChange(normalizeValues)
    }
  }, [startDate, endDate, cloneUserIds])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          mb={3}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" spacing={2} alignItems="center" mr={2}>
            <AutocompleteField
              name="userIds"
              label="User"
              options={usersOptions || []}
              multiple
            />
            <Typography variant="body2" color="textSecondary">
              From
            </Typography>
            <DatePickerField
              name="startDate"
              views={['year']}
              mask="____"
              inputFormat="yyyy"
              width="150px"
            />

            <Typography variant="body1" color="textSecondary">
              to
            </Typography>

            <DatePickerField
              name="endDate"
              views={['year']}
              mask="____"
              inputFormat="yyyy"
              width="150px"
            />
          </Stack>

          <Button variant="contained" onClick={onOpen} sx={{ height: '40px' }}>
            Add new absence
          </Button>
        </Stack>
      </FormProvider>

      {toggle && (
        <>
          <Suspense fallback={null}>
            <AbsenceReportsDialog
              open={toggle}
              variant="create"
              onClose={onClose}
            />
          </Suspense>
        </>
      )}
    </>
  )
}

export default AbsenceReportsFilters
