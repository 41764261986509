import { DialogContent, Typography, Divider, Stack } from '@mui/material'

interface TileLogsViewDialogProps {
  viewData: ViewData[]
}

const TileLogsViewDialog: React.FC<TileLogsViewDialogProps> = ({
  viewData
}) => (
  <DialogContent>
    <Stack direction="column" divider={<Divider />}>
      {viewData.map((item) => (
        <Stack direction="row" my={2}>
          <Typography fontSize={18} width={250}>
            {item?.title || ''}
          </Typography>
          <Typography fontSize={16} fontWeight={700}>
            {item?.value || ''}
          </Typography>
        </Stack>
      ))}
    </Stack>
  </DialogContent>
)

export default TileLogsViewDialog
