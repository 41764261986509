import React from 'react'

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fade
} from '@mui/material'

import { TableNoData } from 'src/components/table'
import ActionTableMenu from 'src/components/table/ActionTableMenu'
import { ABSENCE_SUB_TABLE_HEAD } from 'src/constants/table.constants'
import { IntlService } from 'src/services/IntlService'

interface AbsenceReportsSubTableProps {
  row: AbsenceReports[]
  open: boolean
  onEditRow?: (id: string) => void
  onDeleteRow?: (id: string) => void
  onViewRow?: (id: string) => void
}

const AbsenceReportsSubTable: React.FC<AbsenceReportsSubTableProps> = ({
  onDeleteRow,
  onEditRow,
  onViewRow,
  row,
  open
}) => (
  <>
    {open && (
      <Fade in={open}>
        <Box
          sx={{
            pl: '49.5px'
          }}
        >
          <Table
            size="small"
            aria-label="purchases"
            sx={{
              borderLeft: '0.5px',
              ml: '0.5px'
            }}
          >
            <TableHead>
              <TableRow>
                {ABSENCE_SUB_TABLE_HEAD.map((head) => (
                  <TableCell
                    key={head.id}
                    align={head.align}
                    width={head.width}
                  >
                    {head.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {row?.map((absenceRow) => (
                <TableRow key={absenceRow.id}>
                  <TableCell>
                    {IntlService.formatDate(String(absenceRow.date))}
                  </TableCell>
                  <TableCell>{absenceRow.reason}</TableCell>
                  <TableCell width={300}>{absenceRow.comment}</TableCell>

                  <ActionTableMenu
                    id={absenceRow.id}
                    onEditRow={onEditRow}
                    onDeleteRow={onDeleteRow}
                    onViewRow={onViewRow}
                  />
                </TableRow>
              ))}

              {!row?.length && <TableNoData isNotFound={!row?.length} />}
            </TableBody>
          </Table>
        </Box>
      </Fade>
    )}
  </>
)

export default AbsenceReportsSubTable
