import { FC } from 'react'

import {
  Card,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'

import DashboardTableRow from './DashboardTableRow'

import { Scrollbar } from 'src/components/base'
import { TableHeadCustom, TableNoData } from 'src/components/table'
import { DASHBOARD_ABSENCE_TABLE_HEAD } from 'src/constants/table.constants'
import { UseTableProps } from 'src/hooks/useTable'
import { useGetAbsencesComing } from 'src/services/Query/dashboard/dashboard.query'

interface DashboardTableProps {
  tableParams: UseTableProps
}

const DashboardTable: FC<DashboardTableProps> = ({ tableParams }) => {
  const { dense, order } = tableParams

  const { data, isFetched, isFetching } = useGetAbsencesComing({
    options: {
      refetchOnWindowFocus: false
    }
  })

  const rows = data?.data.comingAbsences || []

  const isNotFound = isFetched && !rows.length
  const noDataImg =
    'https://theprintedcat.com/wp-content/uploads/2021/08/keyboard-cat-transp2.gif'
  const noDataTitle = 'Everyone is in place'

  return (
    <>
      <Typography variant="h5" my={1}>
        Absences for the coming 2 weeks
      </Typography>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                headLabel={DASHBOARD_ABSENCE_TABLE_HEAD}
                rowCount={rows.length}
              />

              <TableBody>
                {rows.map((row) => (
                  <DashboardTableRow key={row.date} row={row} />
                ))}

                {isNotFound && (
                  <TableNoData
                    isFetched={isFetched}
                    isFetching={isFetching}
                    isNotFound={isNotFound}
                    img={noDataImg}
                    title={noDataTitle}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  )
}

export default DashboardTable
