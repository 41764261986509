import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'

import useToggle from './useToggle'

interface UseFiltersTableProps {
  defaultValueParams: ObjectContain
  deleteFunction?: UseMutateAsyncFunction<unknown, string, string, unknown>
  onChangePage: (event: unknown, page: number) => void
}
export const useFiltersTable = <T = ObjectContain, V = ObjectContain>({
  defaultValueParams,
  deleteFunction,
  onChangePage
}: UseFiltersTableProps) => {
  const [activeId, setActiveId] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const {
    toggle: toggleView,
    onClose: onCloseView,
    onOpen: onOpenView
  } = useToggle(false)
  const {
    toggle: toggleEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit
  } = useToggle(false)
  const {
    toggle: toggleCustomEdit,
    onClose: onCloseCustomEdit,
    onOpen: onOpenCustomEdit
  } = useToggle(false)

  const [params, setParams] = useState<T | ObjectContain>(defaultValueParams)

  const handleDeleteRow = async (id: string) => {
    try {
      if (deleteFunction) {
        await deleteFunction(id)
      }

      enqueueSnackbar('Delete success!')
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditRow = (id: string) => {
    setActiveId(id)
    onOpenEdit()
  }

  const handleCustomEditRow = (id: string) => {
    setActiveId(id)
    onOpenCustomEdit()
  }

  const handleViewRow = (id: string) => {
    setActiveId(id)
    onOpenView()
  }

  const handleFilterValueChange = debounce((values: V) => {
    setParams((prev) => ({ ...prev, ...values }))
    onChangePage(null, 1)
  }, 500)

  return {
    activeId,
    toggleView,
    onCloseView,
    toggleEdit,
    onCloseEdit,
    toggleCustomEdit,
    onCloseCustomEdit,
    params,
    setParams,
    handleDeleteRow,
    handleEditRow,
    handleCustomEditRow,
    handleViewRow,
    handleFilterValueChange
  }
}
