import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// form
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Dialog } from '@mui/material'

import HolidayFormDialog from './HolidayFormDialog'

import { VariantDialogTitle } from 'src/components/dialogs'
import DialogViewService from 'src/services/DialogViewService'
import {
  useCreateHolidayMutation,
  useEditHolidayMutation
} from 'src/services/Query/holidays/holidays.mutation'
import { HolidaysSchema } from 'src/validations/schemas/holidays.schema'

interface HolidayDialogProps extends VarianDialogProps {
  values?: Holidays
}

export default function HolidayDialog({
  open,
  onClose,
  variant = 'create',
  values
}: HolidayDialogProps) {
  const { enqueueSnackbar } = useSnackbar()

  const createHoliday = useCreateHolidayMutation()
  const editHoliday = useEditHolidayMutation()

  const isView = variant === 'view'
  const isEdit = variant === 'edit'
  const isCreate = variant === 'create'
  const isEditable = isCreate || isEdit

  const defaultValues = {
    name: '',
    date: '',
    ...values
  }

  const methods = useForm<HolidaysFV>({
    resolver: yupResolver(HolidaysSchema),
    defaultValues
  })

  useEffect(() => {
    if (isEdit) {
      methods.reset(defaultValues)
    }
  }, [values?.name])

  const onSubmit = async (data: HolidaysFV) => {
    try {
      if (isEdit && values?.id) {
        await editHoliday.mutateAsync({ id: values.id, holidays: data })
        enqueueSnackbar('Edit success!')
      }

      if (isCreate) {
        await createHoliday.mutateAsync(data)
        enqueueSnackbar('Create success!')
      }

      onClose()
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }

  const viewData = DialogViewService.holidaysView(values)

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        createTitle="Create new holiday"
        editTitle="Edit holiday"
        viewTitle="View"
        variant={variant}
        onClose={onClose}
      />

      {isEditable && (
        <HolidayFormDialog
          onClose={onClose}
          isView={isView}
          isEdit={isEdit}
          methods={methods}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}
