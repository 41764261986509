import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import Api from 'src/services/Api'
import { normalizesQueryParams } from 'src/utils/functions'

export const holidaysConfig = {
  getHolidays: {
    key: 'holidays/all',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, params] = queryKey as [string, HolidaysQueryParams]
      const normalizesParams = normalizesQueryParams(params)
      const resp = await Api.getHolidays(normalizesParams)

      return resp.data
    }
  },
  getHolidayById: {
    key: 'holidays/id',
    request: async ({ queryKey }: QueryFunctionContext) => {
      const [_key, id] = queryKey as [string, string]

      const resp = await Api.getHolidaysById(id)

      return resp.data
    }
  }
}

interface UseGetHolidays {
  params?: HolidaysQueryParams
  options?: UseQueryOptions<HolidaysResponse>
}

export const useGetHolidays = (props?: UseGetHolidays) => {
  const { options, params } = props || {}
  const { getHolidays: config } = holidaysConfig

  const state = useQuery<HolidaysResponse>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetHolidayById {
  id: string
  options?: UseQueryOptions<Holidays>
}
export const useGetHolidayById = (props?: UseGetHolidayById) => {
  const { options, id } = props || {}
  const { getHolidayById: config } = holidaysConfig

  const state = useQuery<Holidays>([config.key, id], config.request, {
    ...options
  })
  return state
}
