export const HOLIDAY_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: '', label: 'Actions', align: 'right' }
]

export const USERS_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'isActive', label: 'Is Active', align: 'left' },
  { id: 'shouldLog', label: 'Should Log', align: 'left' },
  {
    id: 'shouldAppearInNotice',
    label: 'Should Appear In Notice',
    align: 'left'
  },
  { id: 'position', label: 'Position', align: 'left' },
  { id: 'username', label: 'UserName', align: 'left' },
  { id: 'havePassword', label: 'Have password', align: 'left' },
  { id: 'login', label: 'Login', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: '', label: 'Actions', align: 'right' }
]

export const PROJECTS_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'channelName', label: 'Channel name', align: 'left' },
  { id: 'isActive', label: 'Is Active', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: '', label: 'Actions', align: 'right' }
]

export const TIME_LOGS_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'name', label: 'Users', align: 'left' },
  { id: 'project', label: 'Projects', align: 'left' },
  { id: 'logDate', label: 'Log Date', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'time', label: 'Time', align: 'left' },
  { id: 'workType', label: 'Work type', align: 'left' },
  { id: 'details', label: 'Details', align: 'left' }
]

export const WEEKLY_REPORTS_TABLE_HEAD: TableHeadOptions[] = [
  { id: '', label: '', width: '50px' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'totalTime', label: 'Total Time', align: 'right', width: '200px' }
]

export const WEEKLY_REPORTS_SUB_TABLE_HEAD: TableHeadOptions[] = [
  { id: '', label: '', width: '50px' },
  { id: 'collaborator', label: 'Collaborators', align: 'left' },
  {
    id: 'timeSpent',
    label: 'Time Spent',
    align: 'right',
    width: '130px'
  }
]
export const WEEKLY_REPORTS_USER_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'workType', label: 'Work type', align: 'left', width: '130px' },
  {
    id: 'details',
    label: 'Details',
    align: 'left'
  },
  { id: 'time', label: 'Time', align: 'right', width: '130px' }
]

export const ABSENCE_TABLE_HEAD: TableHeadOptions[] = [
  { id: '', label: '', width: '50px' },
  { id: 'users', label: 'Users', align: 'left' },
  { id: 'vacation', label: 'Vacation', align: 'left' },
  { id: 'illness', label: 'Illness', align: 'left' },
  { id: 'other', label: 'Other', align: 'left' }
]

export const DASHBOARD_ABSENCE_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'user', label: 'Name', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'interval', label: 'Interval', align: 'left' }
]

export const ABSENCE_SUB_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'reason', label: 'Reason', align: 'left' },
  { id: 'comment', label: 'Comment', align: 'left', width: '200px' },
  { id: '', label: 'Actions', align: 'right' }
]

export const TIME_WORK_TABLE_HEAD: TableHeadOptions[] = [
  { id: '', label: '', width: '50px' },
  { id: 'user', label: 'User' },
  { id: 'hoursWorked', label: 'Hours Worked', align: 'left' },
  {
    id: 'estimatedHoursWorked',
    label: 'Estimated Hours Worked',
    align: 'left'
  },
  { id: 'difference', label: 'Difference', align: 'left', width: '200px' }
]

export const TIME_WORK_SUBTABLE_HEAD: TableHeadOptions[] = [
  { id: 'date', label: 'Date' },
  { id: 'hoursWorked', label: 'Hours Worked', align: 'left' },
  {
    id: 'estimatedHoursWorked',
    label: 'Estimated Hours Worked',
    align: 'left'
  },
  { id: 'difference', label: 'Difference', align: 'left', width: '200px' }
]

export const ADMINS_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'id', label: 'Id', align: 'left', width: '100px' },
  { id: 'email', label: 'Email/Login', align: 'left' },
  { id: '', label: 'Actions', align: 'right' }
]

export const PLANS_TABLE_HEAD: TableHeadOptions[] = [
  { id: '', label: '', width: '50px' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'progressTracker', label: 'Progress Type', align: 'left' },
  { id: 'active', label: 'Published', align: 'left' },
  { id: 'count', label: 'completed / All tasks', align: 'left' },
  { id: '', label: 'Actions', align: 'right' }
]

export const PLANS_SUBTABLE_HEAD: TableHeadOptions[] = [
  { id: 'user', label: 'User name', align: 'left', width: '350px' },
  { id: 'workType', label: 'Work type', align: 'left', width: '150px' },
  { id: 'finished', label: 'Finished task', align: 'left', width: '150px' },
  { id: 'description', label: 'Description', align: 'right' }
]
export const CREDENTIALS_TABLE_HEAD: TableHeadOptions[] = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'domain', label: 'Domain', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: '', label: 'Actions', align: 'left' }
]
