import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, InputAdornment, Stack } from '@mui/material'

import { Iconify } from 'src/components/base'
import { FormProvider, SelectField, MUITextField } from 'src/components/fields'
import { statusOptions } from 'src/constants/options.constants'

interface UsersFilterProps {
  handleFilterValueChange: (value: UsersFV) => void
}

const UsersFilter: React.FC<UsersFilterProps> = ({
  handleFilterValueChange
}) => {
  const methods = useForm<UsersFV>({
    defaultValues: {
      term: '',
      active: 'true'
    }
  })

  const values = methods.watch()

  useEffect(() => {
    handleFilterValueChange(values)
  }, [values.term, values.active])

  return (
    <>
      <FormProvider methods={methods}>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <MUITextField
              name="term"
              label="User name"
              sx={{ width: '240px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="fa-search" />
                  </InputAdornment>
                )
              }}
            />

            <SelectField name="active" label="Active" options={statusOptions} />

            <Button variant="outlined" onClick={() => methods.reset()}>
              Clear filter
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  )
}

export default UsersFilter
