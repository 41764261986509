import React from 'react'

import { TableRow, TableCell } from '@mui/material'

import { IntlService } from 'src/services/IntlService'

interface DashboardTableRowProps extends Partial<TableRowProps> {
  row: AbsencesComing
}

const DashboardTableRow: React.FC<DashboardTableRowProps> = ({ row }) => (
  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell align="left">{row.names.join(', ')}</TableCell>
    <TableCell align="left" width={200}>
      {IntlService.formatDate(row.date)}
    </TableCell>
    <TableCell align="left" width={200}>
      {row.startDate}
    </TableCell>
  </TableRow>
)

export default DashboardTableRow
