import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { DatePicker, DatePickerProps } from '@mui/lab'
import { Box, TextFieldProps } from '@mui/material'

import { MUITextField } from '../MUITextField'

import { IntlService } from 'src/services/IntlService'

interface DatePickerFieldProps extends Partial<DatePickerProps<Date>> {
  name: string
  label?: string
  width?: string
}

const DatePickerField: React.FC<DatePickerFieldProps & TextFieldProps> = ({
  name,
  label,
  width,
  ...rest
}) => {
  const { control } = useFormContext()

  const [open, setOpen] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box
          sx={{
            '& .MuiFormHelperText-root': {
              color: 'error.main'
            },

            '& .MuiInputLabel-formControl': {
              color: error ? 'error.main' : 'gray.500'
            }
          }}
        >
          <DatePicker
            {...field}
            value={IntlService.formatDate(field.value) || null}
            InputProps={{ error: !!error?.message }}
            label={label || null}
            inputFormat="yyyy-MM-dd"
            mask="____-__-__"
            open={open}
            onClose={() => setOpen(false)}
            inputProps={{ readOnly: true }}
            renderInput={(params) => (
              <MUITextField
                sx={{
                  width: width || '180px',
                  '& .MuiOutlinedInput-input': {
                    cursor: 'pointer',
                    WebkitTextFillColor: '#212B36'
                  }
                }}
                autoComplete="off"
                onClick={() => setOpen(true)}
                error={!!error}
                helperText={error?.message}
                name="date"
                {...params}
              />
            )}
            {...rest}
          />
        </Box>
      )}
    />
  )
}

export default DatePickerField
