// @mui
import { Checkbox, TableRow, TableCell } from '@mui/material'

import ActionTableMenu from 'src/components/table/ActionTableMenu'

interface ProjectsTableRowProps extends TableRowProps {
  row: Projects
}

const ProjectsTableRow = ({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow
}: ProjectsTableRowProps) => {
  const { name, id, active, type, channelName } = row

  return (
    <TableRow hover selected={selected}>
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}

      <TableCell align="left" width={400}>
        {name}
      </TableCell>

      <TableCell align="left" width={400}>
        {channelName}
      </TableCell>

      <TableCell align="left" width={200}>
        {active ? 'Yes' : 'No'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} width={150}>
        {type.toLowerCase()}
      </TableCell>

      <ActionTableMenu
        id={id}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
      />
    </TableRow>
  )
}

export default ProjectsTableRow
