import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
/**
 * Deep merges two objets.
 * @param  {Object} object destination object
 * @param  {Object} source source obejct
 *
 * @returns {Object} new object
 */
const merge = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source: Record<string, any>
): object => {
  if (object === source) return object

  const newValue = {
    ...object,
    ...source
  }

  Object.entries(source).forEach(([key, value]) => {
    if (object[key] && typeof object[key] === 'object') {
      newValue[key] = merge(object[key], value)
    } else {
      newValue[key] = value
    }
  })

  return newValue
}

export interface RequestOptions extends AxiosRequestConfig {
  deserialize?: boolean
  fullResponse?: boolean
  handleError?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (url: string, config?: RequestOptions) => Promise<any>
type Handlers = {
  get: Handler
  post: Handler
  put: Handler
  patch: Handler
  del: Handler
}

class Axios {
  private config: RequestOptions

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private instance: any

  constructor(config: AxiosRequestConfig) {
    this.config = config
    this.instance = axios.create(config)
  }

  update(config: AxiosRequestConfig) {
    this.instance = axios.create(merge(this.config, config))
  }

  appendResponseErrorInterceptor = (
    interceptor: (error: AxiosError) => void
  ) => {
    this.instance.interceptors.response.use(
      (r: AxiosRequestConfig) => r,
      interceptor
    )
  }

  appendRequestErrorInterceptor = (
    interceptor: (error: AxiosError) => void
  ) => {
    this.instance.interceptors.response.use(
      (r: AxiosRequestConfig) => r,
      interceptor
    )
  }

  makeRequest(method: string, url: string, options: RequestOptions = {}) {
    const headers = { ...this.instance.defaults.headers, ...options.headers }

    const { deserialize, fullResponse, ...axiosOptions } = options

    return this.instance({
      ...axiosOptions,
      method,
      url,
      headers
    })
      .then((resp: AxiosResponse) => {
        const data = resp?.data

        if (fullResponse) {
          return { ...resp, data }
        }

        return data
      })
      .catch(({ response }: AxiosError) => Promise.reject(response))
  }

  setAuthToken(auth_token?: string | null) {
    if (auth_token) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${auth_token}`
    } else delete this.instance.defaults.headers.common.Authorization
  }

  get: Handler = (url, config) => this.makeRequest('get', url, config)

  post: Handler = (url, config) => this.makeRequest('post', url, config)

  put: Handler = (url, config) => this.makeRequest('put', url, config)

  patch: Handler = (url, config) => this.makeRequest('patch', url, config)

  del: Handler = (url, config) => this.makeRequest('delete', url, config)

  requestHandlers: Handlers = {
    get: this.get,
    post: this.post,
    put: this.put,
    patch: this.patch,
    del: this.del
  }
}

export default Axios
