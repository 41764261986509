import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid } from '@mui/material'

import {
  FormProvider,
  AutocompleteField,
  SelectField,
  StaticDateRangePickerField
} from 'src/components/fields'
import { projectOptions } from 'src/constants/options.constants'
import { useQuerySearchParams } from 'src/hooks/useQuerySearchParams'
import { IntlService } from 'src/services/IntlService'
import { useGetProjectsNames } from 'src/services/Query/projects/projects.query'
import { transformMultipleValue } from 'src/utils/functions'

interface WeeklyReportsFilterProps {
  defaultValues: WeeklyReportFilters
  handleFilterValueChange: (value: WeeklyReportFilters) => void
}

const WeeklyReportsFilter: React.FC<WeeklyReportsFilterProps> = ({
  defaultValues,
  handleFilterValueChange
}) => {
  const { setParams } = useQuerySearchParams()

  const { data } = useGetProjectsNames({ params: { active: true } })

  const methods = useForm({
    defaultValues: {
      projectIds: defaultValues.projectIds,
      projectType: defaultValues.projectType,
      dateRange: [defaultValues.startDate, defaultValues.endDate]
    }
  })

  const { dateRange, ...restValues } = methods.watch()
  const cloneValues = JSON.stringify(restValues)

  useEffect(() => {
    const normalizeValues: WeeklyReportFilters = {
      ...restValues,
      projectIds: transformMultipleValue(restValues.projectIds),
      startDate: IntlService.formatDate(dateRange?.[0] || ''),
      endDate: IntlService.formatDate(dateRange?.[1] || '')
    }

    setParams(normalizeValues)

    handleFilterValueChange(normalizeValues)
  }, [cloneValues, dateRange])

  // FIXME: would be better use util `createOptions`
  const options = data?.projects?.map((project) => ({
    value: project.id,
    label: project.name
  }))

  return (
    <>
      <FormProvider methods={methods}>
        <Grid container spacing={2} mb={2} flexWrap="wrap">
          <Grid item>
            <AutocompleteField
              name="projectIds"
              width="240px"
              label="Projects name"
              options={options || []}
              multiple
            />
          </Grid>
          <Grid item>
            <SelectField
              name="projectType"
              label="Projects type"
              options={projectOptions || []}
              width="240px"
            />
          </Grid>
          <Grid item>
            <StaticDateRangePickerField name="dateRange" width="240px" />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={{ minHeight: '40px' }}
              onClick={() => methods.reset()}
            >
              Clear filter
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}

export default WeeklyReportsFilter
