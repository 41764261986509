import { sentenceCase } from 'change-case'
import { isEmpty } from 'lodash'
import { useState } from 'react'

// @mui
import {
  Box,
  Card,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  CardProps,
  CardHeader,
  TableContainer
} from '@mui/material'

import { DeliveryError } from 'src/@types/domain-statistics'
import { Label, Scrollbar } from 'src/components/base'
import { TableHeadCustom, TableNoData } from 'src/components/table'
import { fDateTime } from 'src/utils/formatTime'

interface Props extends CardProps {
  title?: string
  subheader?: string
  tableData: DeliveryError[]
  tableLabels: TableHeadOptions[]
}

export default function DomainNewIssues({
  title,
  subheader,
  tableData,
  tableLabels,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 520 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {isEmpty(tableData) ? (
                <TableNoData isNotFound={isEmpty(tableData)} />
              ) : (
                tableData?.map((row, index) => {
                  const id = index + 1
                  return (
                    <DomainNewIssuesRow key={index} row={{ ...row, id: id }} />
                  )
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />
    </Card>
  )
}

// ----------------------------------------------------------------------

type DomainNewIssuesRowProps = {
  row: DeliveryError & { id: number }
}

function DomainNewIssuesRow({ row }: DomainNewIssuesRowProps) {
  return (
    <>
      <TableRow>
        <TableCell>{row.id}</TableCell>

        <TableCell>{row.message}</TableCell>

        <TableCell>
          <Label variant="filled" color={'error'}>
            {row.errorCode}
          </Label>
        </TableCell>

        <TableCell>{fDateTime(row.timestamp)}</TableCell>
      </TableRow>
    </>
  )
}
