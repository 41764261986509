// @mui
import { useLocation } from 'react-router'

import { Box, Stack, AppBar, Toolbar, Button, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'

// hooks
import { IconButtonAnimate } from '../../../components/animate'
import { HEADER, NAVBAR } from '../../../config'
import useOffSetTop from '../../../hooks/useOffSetTop'
import useResponsive from '../../../hooks/useResponsive'
// utils
import cssStyles from '../../../utils/cssStyles'

// config
import { HeaderBreadcrumbs, Iconify, Logo } from 'src/components/base'
import { useSettingsContext } from 'src/contexts/SettingsContext'
import useAuth from 'src/hooks/useAuth'
import { PATH_DASHBOARD } from 'src/routes/paths'

type RootStyleProps = {
  isCollapse: boolean
  isOffset: boolean
  verticalLayout: boolean
}

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout'
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default
    })
  }
}))

const breadcrumbsConfig: { [key: string]: string } = {
  'weekly-report': 'Weekly Report',
  'time-logs': 'Time Logs',
  'time-to-work': 'Time To Work',
  'absence-reports': 'Absence Reports',
  holidays: 'Holidays',
  projects: 'Projects',
  users: 'Users',
  credentials: 'Credentials'
}

type Props = {
  onOpenSidebar: VoidFunction
  isCollapse?: boolean
  verticalLayout?: boolean
}

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false
}: Props) {
  const { logout, user } = useAuth()
  const isDesktop = useResponsive('up', 'lg')

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout

  const location = useLocation()
  const pathname = location.pathname.split('/')
  const lastPathname = pathname[pathname.length - 1]

  const isDashboard = lastPathname === 'app'
  const breadcrumbsName = breadcrumbsConfig[lastPathname]

  // const { onToggleMode, themeMode } = useSettingsContext()
  const handleLogout = () => {
    logout(user?.id)
  }

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 }
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Stack direction="row" alignItems="center" height="100%">
          <HeaderBreadcrumbs
            sx={{ mb: 0, display: 'flex' }}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.general.app },
              { name: breadcrumbsName }
            ].slice(0, isDashboard ? 1 : 3)}
          />
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        {/* <Box>
          <Switch
            color="primary"
            value={themeMode}
            checked={themeMode === 'dark'}
            onClick={onToggleMode}
          />
        </Box> */}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Button variant="text" size="small" onClick={handleLogout}>
            Log out
          </Button>
        </Stack>
      </Toolbar>
    </RootStyle>
  )
}
