import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// form
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Dialog } from '@mui/material'

import ProjectsFormDialog from './ProjectsFormDialog'

import { VariantDialogTitle } from 'src/components/dialogs'
import {
  useCreateProjectsMutation,
  useEditProjectsMutation
} from 'src/services/Query/projects/projects.mutation'
import { ProjectsSchema } from 'src/validations/schemas/projects.schema'

interface ProjectsDialogProps extends VarianDialogProps {
  values?: Projects
}

const ProjectsDialog = ({
  open,
  onClose,
  variant = 'create',
  values
}: ProjectsDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const createProjects = useCreateProjectsMutation()
  const editProjects = useEditProjectsMutation()

  const isView = variant === 'view'
  const isEdit = variant === 'edit'
  const isCreate = variant === 'create'
  const isEditable = isCreate || isEdit

  const defaultValues = {
    name: '',
    type: '',
    channelName: '',
    active: false,
    ...values
  }

  const methods = useForm<ProjectsFV>({
    resolver: yupResolver(ProjectsSchema),
    defaultValues
  })

  useEffect(() => {
    if (isEdit) {
      methods.reset(defaultValues)
    }
  }, [values?.name])

  const onSubmit = async (data: ProjectsFV) => {
    try {
      if (isEdit && values?.id) {
        await editProjects.mutateAsync({ id: values.id, projects: data })
        enqueueSnackbar('Edit success!')
      }

      if (isCreate) {
        await createProjects.mutateAsync(data)
        enqueueSnackbar('Create success!')
      }

      onClose()
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <VariantDialogTitle
        createTitle="Create new project"
        editTitle="Edit"
        viewTitle="View"
        variant={variant}
        onClose={onClose}
      />

      {isEditable && (
        <ProjectsFormDialog
          isEdit={isEdit}
          isView={isView}
          methods={methods}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  )
}

export default ProjectsDialog
