import React from 'react'

import { TableRow, TableCell, IconButton } from '@mui/material'

import AbsenceReportsSubTable from './AbsenceReportsSubTable'

import { Iconify } from 'src/components/base'

interface AbsenceReportsTableRowProps extends Partial<TableRowProps> {
  row: AbsenceReportsResponseData
}

const AbsenceReportsTableRow: React.FC<AbsenceReportsTableRowProps> = ({
  onDeleteRow,
  onEditRow,
  onViewRow,
  row
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      >
        <TableCell
          align="center"
          sx={{
            p: 0,

            '&.MuiTableCell-root:first-of-type': {
              paddingLeft: 0
            }
          }}
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <Iconify icon="akar-icons:arrow-up" />
            ) : (
              <Iconify icon="akar-icons:arrow-down" />
            )}
          </IconButton>
        </TableCell>

        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.meta.vacationTotal}</TableCell>
        <TableCell align="left">{row.meta.IllnesTotal}</TableCell>
        <TableCell align="left">{row.meta.otherTotal}</TableCell>
      </TableRow>

      {open && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <AbsenceReportsSubTable
              open={open}
              onDeleteRow={onDeleteRow}
              onEditRow={onEditRow}
              onViewRow={onViewRow}
              row={row.absences}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default AbsenceReportsTableRow
